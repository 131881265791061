<template>
  <footer
    data-w-id="5d3def44-2af0-a39e-d268-cb5e4a46cda3"
    class="py-12 border-t"
  >
    <div class="max-w-7xl mx-auto">
      <div
        data-w-id="5d3def44-2af0-a39e-d268-cb5e4a46cda6"
        class="links-block footer-links flex flex-col gap-4"
      >
        <a href="#" class="w-inline-block"
          ><img
            src="../assets/logo-gupshup.svg"
            width="180"
            alt="gups hup Logo"
        /></a>
        <div class="fine-print">
          Copyright @ {{ title }} | Powered by<a href="https://chadura.com/">
            Chadura</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      title: "",
    };
  },
  created() {
    this.title = process.env.VUE_APP_Title;
  },
};
</script>

<style scoped>
.footer-maggana {
  background-color: beige;
  position: relative;
  bottom: 0px;
}
</style>
