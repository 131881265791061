<template>
  <div class="w-full h-full">
    <div class="accordion-collapse border-0 text-sm md:text-base">
      <div
        class="accordion-body py-4 pr-10 flex flex-col gap-3 justify-evenly items-start p-4"
      >
      <!-- Key Object -->
        <div class="w-full flex flex-col gap-2 relative">
          <div
            class="flex justify-between items-center p-1 rounded-lg shadow-lg border-b border-gray-100 bg-primary px-2"
          >
            <h4 class="m-0 text-lg text-white">Key Objectives</h4>
            <button
              v-if="this.selected_popUp == ''"
              @click="this.selected_popUp = 'keyObject'"
              class="px-4 py-1 bg-white text-red-800 rounded-lg"
            >
              Add
            </button>
          </div>
          <ol v-if="key_object_list.length > 0">
            <li
              v-for="el in this.key_object_list"
              :key="el"
              class="list-disc p-2 border rounded-lg"
            >
              <div class="flex justify-between">
                {{ el.title }}
                <div>
                  <img src="../assets/edit.svg" alt="" @click="selected_popUp = 'keyObjectEdit',this.edit_file_id = el.id" class="h-6 cursor-pointer" />
                  <img
                    src="../assets/trash.svg"
                    @click="this.deleteKeyObject(el.id)"
                    alt=""
                    class="h-6 cursor-pointer"
                  />
                </div>
              </div>
            </li>
            <div
                v-if="selected_popUp == 'keyObjectEdit'"
                class="p-4 absolute top-10 right-0 bg-white border border-gray-300 shadow-lg rounded-md z-50"
                style="width: 450px"
              >
                <form
                  @submit.prevent="this.updateKeyObject($event,edit_file_id)"
                  class="flex flex-col gap-2 relative w-ful"
                >
                  <img
                    src="./../assets/closebutton.svg"
                    alt="close-btn"
                    @click="this.selected_popUp = ''"
                    class="h-6 absolute top-0 right-0 cursor-pointer z-50"
                  />
                  <div>
                    <label for="key_object_titleEdit">Updated Title</label>
                    <input
                      id="key_object_titleEdit"
                      type="text"
                      class="h-10 border border-gray-300 rounded-md w-11/12"
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      class="px-4 py-1 bg-primary rounded-lg"
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
          </ol>
          <ol v-else>
            <li>No data</li>
          </ol>
          <div
            v-if="selected_popUp == 'keyObject'"
            class="p-4 absolute top-10 right-0 bg-white border border-gray-300 shadow-lg rounded-md z-50"
            style="width: 450px"
          >
            <form
              @submit.prevent="this.addKeyObject"
              class="flex flex-col gap-2 relative w-full"
            >
              <img
                src="./../assets/closebutton.svg"
                alt="close-btn"
                @click="this.selected_popUp = ''"
                class="h-6 absolute top-0 right-0 cursor-pointer z-50"
              />
              <div>
                <label for="key_title">Key Objectives Title</label>
                <input
                  id="key_title"
                  type="text"
                  class="h-10 border border-gray-300 rounded-md w-11/12"
                />
              </div>
              <div>
                <button type="submit" class="px-4 py-1 bg-primary rounded-lg">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <!-- Touch Point -->
        <div class="w-full">
          <div
            class="flex justify-between items-center p-1 rounded-lg shadow-lg border-b border-gray-100 bg-primary px-2"
          >
            <h4 class="text-lg m-0 text-white">Touch-points</h4>
            <button
              v-if="this.selected_popUp == ''"
              @click="this.selected_popUp = 'touchPoint'"
              class="px-4 py-1 bg-white text-red-800 rounded-lg"
            >
              Add
            </button>
          </div>
          <ol v-if="touch_point_list.length > 0">
            <li v-for="el in this.touch_point_list" :key="el" class="list-disc">
              <div class="flex flex-col mt-3 border px-2 rounded-md">
                <div class="m-0 p-0">{{ el.title }}</div>
                <div class="flex gap-4 justify-between">
                  <div class="flex items-center gap-4 mt-1">
                    <label for="touch-point-initiated">Initiated</label>
                    <input
                      @click="updateTouchPointIni($event, 'initiated', el.id)"
                      type="checkbox"
                      :checked="el.initiated"
                      class="border border-gray-300 rounded-md"
                    />
                  </div>
                  <div class="flex items-center gap-4 mt-1">
                    <label for="touch-point-completed">Completed</label>
                    <input
                      @click="updateTouchPointIni($event, 'completed', el.id)"
                      type="checkbox"
                      :checked="el.completed"
                      class="border border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <img src="../assets/edit.svg" alt="" @click="this.selected_popUp='touchPointEdit',this.edit_file_id = el.id" class="h-6 cursor-pointer" />
                    <img
                      src="../assets/trash.svg"
                      @click="this.deleteTouchPoints(el.id)"
                      alt=""
                      class="h-6 cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            </li>
            <div
                v-if="selected_popUp == 'touchPointEdit'"
                class="p-4 absolute top-10 right-0 bg-white border border-gray-300 shadow-lg rounded-md z-50"
                style="width: 450px"
              >
                <form
                  @submit.prevent="this.updateTouchPointIni($event,'title',edit_file_id)"
                  class="flex flex-col gap-2 relative w-ful"
                >
                  <img
                    src="./../assets/closebutton.svg"
                    alt="close-btn"
                    @click="this.selected_popUp = ''"
                    class="h-6 absolute top-0 right-0 cursor-pointer z-50"
                  />
                  <div>
                    <label for="touch_point_titleEdit">Updated Title</label>
                    <input
                      id="touch_point_titleEdit"
                      type="text"
                      class="h-10 border border-gray-300 rounded-md w-11/12"
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      class="px-4 py-1 bg-primary rounded-lg"
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
          </ol>
          <ol v-else class="py-2">
            No data
          </ol>
          <div
            v-if="selected_popUp == 'touchPoint'"
            class="p-4 absolute top-10 right-0 bg-white border border-gray-300 shadow-lg rounded-md z-50"
            style="width: 450px"
          >
            <form
              @submit.prevent="this.addTouchPoints"
              class="flex flex-col gap-2 relative w-ful"
            >
              <img
                src="./../assets/closebutton.svg"
                alt="close-btn"
                @click="this.selected_popUp = ''"
                class="h-6 absolute top-0 right-0 cursor-pointer z-50"
              />
              <div>
                <label for="touch_point_title">Touch-points Title</label>
                <input
                  id="touch_point_title"
                  type="text"
                  class="h-10 border border-gray-300 rounded-md w-11/12"
                />
              </div>
              <div>
                <button type="submit" class="px-4 py-1 bg-primary rounded-lg">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <!-- Form Link -->
        <div class="w-full flex flex-col gap-2 relative">
          <div
            class="flex justify-between items-center p-1 rounded-lg shadow-lg border-b border-gray-100 bg-primary px-2"
          >
            <h4 class="m-0 text-lg text-white">Forms</h4>
            <button
              v-if="this.selected_popUp == ''"
              @click="this.selected_popUp = 'formLink'"
              class="px-4 py-1 bg-white text-red-800 rounded-lg"
            >
              Add
            </button>
          </div>
          <ol v-if="form_link_list.length > 0">
            <li
              v-for="el in this.form_link_list"
              :key="el"
              class="list-disc p-2 border rounded-lg"
            >
              <div class="flex justify-between">
                <div class="flex gap-4 items-center">
                  {{ el.title }}
                  <a :href="el.Form_links" target="_blank">
                    <button class="px-3 py-0.5 text-sm bg-primary rounded-md">Link</button>
                  </a>
                </div>
                <div>
                  <img src="../assets/edit.svg" alt="" @click="selected_popUp = 'formEdit',this.edit_file_id = el.id" class="h-6 cursor-pointer" />
                  <img
                    src="../assets/trash.svg"
                    @click="this.deleteForms(el.id)"
                    alt=""
                    class="h-6 cursor-pointer"
                  />
                </div>
              </div>
            </li>
            <div
                v-if="selected_popUp == 'formEdit'"
                class="p-4 absolute top-10 right-0 bg-white border border-gray-300 shadow-lg rounded-md z-50"
                style="width: 450px"
              >
                <form
                  @submit.prevent="this.updateFormLink($event,edit_file_id)"
                  class="flex flex-col gap-2 relative w-ful"
                >
                  <img
                    src="./../assets/closebutton.svg"
                    alt="close-btn"
                    @click="this.selected_popUp = ''"
                    class="h-6 absolute top-0 right-0 cursor-pointer z-50"
                  />
                  <div>
                    <label for="update_title">Updated Title</label>
                    <input
                      id="update_title"
                      type="text"
                      class="h-10 border border-gray-300 rounded-md w-11/12"
                    />
                  </div>
                  <div>
                    <label for="update_url">Updated Url</label>
                    <input
                      id="update_url"
                      type="text"
                      class="h-10 border border-gray-300 rounded-md w-11/12"
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      class="px-4 py-1 bg-primary rounded-lg"
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
          </ol>
          <ol v-else>
            <li>No data</li>
          </ol>
          <div
            v-if="selected_popUp == 'formLink'"
            class="p-4 absolute top-10 right-0 bg-white border border-gray-300 shadow-lg rounded-md z-50"
            style="width: 450px"
          >
            <form ref="form_ref"
              @submit.prevent="this.addForm"
              class="flex flex-col gap-2 relative w-full"
            >
              <img
                src="./../assets/closebutton.svg"
                alt="close-btn"
                @click="this.selected_popUp = ''"
                class="h-6 absolute top-0 right-0 cursor-pointer z-50"
              />
              <div>
                <label for="form_title">Form Title</label>
                <input
                  id="form_title"
                  type="text"
                  class="h-10 border border-gray-300 rounded-md w-11/12"
                />
              </div>
              <div>
                <label for="form_url">Form Url</label>
                <input
                  id="form_url"
                  type="text"
                  class="h-10 border border-gray-300 rounded-md w-11/12"
                />
              </div>
              <div>
                <button type="submit" class="px-4 py-1 bg-primary rounded-lg">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      key_object_list: [],
      touch_point_list: [],
      form_link_list: [],
      selected_popUp: "",
      edit_file_id : ""
    };
  },
  props: ["course_id", "user_token", "user_id"],

  created() {
    this.keyObjectCall();
    this.touchPointCall();
    this.formLinkCall();
  },
  methods: {
    deleteForms(id) {
      axios
        .delete(
          `${process.env.VUE_APP_API}/formlinks/update/deleted/${id}/?Key=${this.user_token}`
        )
        .then((resp) => {
          this.selected_popUp = "";
          this.formLinkCall();
        });
    },
    updateFormLink(el,id){
      
      let  inputJson = {
          title: el.target.elements.update_title.value,
          Form_links: el.target.elements.update_url.value,
        };
      axios
        .patch(
          `${process.env.VUE_APP_API}/formlinks/update/deleted/${id}/?Key=${this.user_token}`,
          inputJson
        )
        .then((resp) => {
          this.selected_popUp = "";
          this.edit_file_id = "";
          this.formLinkCall();
        });
    },
    addForm(el){
      let postJson = {
        user: this.user_id,
        course: this.course_id,
        title: el.target.elements.form_title.value,
        Form_links: el.target.elements.form_url.value,
        published: true,
      };
      axios
        .post(
          `${process.env.VUE_APP_API}/formlinks/post/?Key=${this.user_token}`,
          postJson
        )
        .then((resp) => {
          console.log(resp);
          this.$refs.form_ref.reset()
          this.selected_popUp = "";
          this.formLinkCall();
        });
    },
    async formLinkCall(){
      await axios
        .get(
          `${process.env.VUE_APP_API}/formlinks/list/${this.course_id}/?Key=${this.user_token}`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            this.form_link_list = resp.data.data;
          } else {
            this.form_link_list = [];
          }
        });
    },
    async keyObjectCall() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/Keyobjectives/list/${this.course_id}/?Key=${this.user_token}`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            this.key_object_list = resp.data.data;
          } else {
            this.key_object_list = [];
          }
        });
    },
    async touchPointCall() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/touchpoints/list/${this.course_id}/?Key=${this.user_token}`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            this.touch_point_list = resp.data.data;
          } else {
            this.touch_point_list = [];
          }
        });
    },
    addKeyObject(el) {
      let postJson = {
        user: this.user_id,
        course: this.course_id,
        title: el.target.elements.key_title.value,
        initiated: false,
        completed: false,
      };
      axios
        .post(
          `${process.env.VUE_APP_API}/Keyobjectives/post/?Key=${this.user_token}`,
          postJson
        )
        .then((resp) => {
          console.log(resp);
          this.selected_popUp = "";
          this.keyObjectCall();
        });
    },
    addTouchPoints(el) {
      console.log(el);
      let postJson = {
        user: this.user_id,
        course: this.course_id,
        title: el.target.elements.touch_point_title.value,
        initiated: false,
        completed: false,
      };
      axios
        .post(
          `${process.env.VUE_APP_API}/touchpoints/post/?Key=${this.user_token}`,
          postJson
        )
        .then((resp) => {
          this.selected_popUp = "";
          this.touchPointCall();
        });
    },
    deleteKeyObject(id) {
      axios
        .delete(
          `${process.env.VUE_APP_API}/Keyobjectives/update/deleted/${id}/?Key=${this.user_token}`
        )
        .then((resp) => {
          this.selected_popUp = "";
          this.keyObjectCall();
        });
    },
    deleteTouchPoints(id) {
      axios
        .delete(
          `${process.env.VUE_APP_API}/touchpoints/update/deleted/${id}/?Key=${this.user_token}`
        )
        .then((resp) => {
          this.selected_popUp = "";
          this.touchPointCall();
        });
    },
    updateTouchPointIni(el, editField, id) {
      var inputJson;
      if (editField == "title") {
        inputJson = {
          title: el.target.elements.touch_point_titleEdit.value,
        };
      } else if (editField == "initiated") {
        inputJson = {
          initiated: el.target.checked,
        };
      } else {
        inputJson = {
          completed: el.target.checked,
        };
      }
      axios
        .patch(
          `${process.env.VUE_APP_API}/touchpoints/update/deleted/${id}/?Key=${this.user_token}`,
          inputJson
        )
        .then((resp) => {
          this.selected_popUp = "";
          this.edit_file_id = "";
          this.touchPointCall();
        });
    },
    updateKeyObject(el,id) {
      console.log(el.target.elements.key_object_titleEdit.value)
      
      let  inputJson = {
          title: el.target.elements.key_object_titleEdit.value,
        };
      axios
        .patch(
          `${process.env.VUE_APP_API}/Keyobjectives/update/deleted/${id}/?Key=${this.user_token}`,
          inputJson
        )
        .then((resp) => {
          this.selected_popUp = "";
          this.edit_file_id = "";
          this.keyObjectCall();
        });
    },
  },
};
</script>

<style></style>
