<template>
  <div class="page-wrapper">
    <Navigation />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <div v-if="this.isAuthenticated" class="flex w-full 2xl:max-w-7xl mx-auto">
      <LeftNavigationView actived="home" />
      <div class="w-full">
        <div class="wf-section py-8">
          <div class="container-default-1209px w-container">
            <div class="flex items-start justify-between">
              <div class="">
                <h1 class="text-4xl">
                  Welcome {{ this.userInfo.user.display_name }} to
                  <span class="text-4xl"
                    >your Learning and Knowledge Resource Portal!</span
                  >
                </h1>
                <h3 class="">Program Name: X-Sell</h3>
                <!-- Objective -->
                <div class="px-4 max-w-6xl mx-auto text-justify">
                  <h3>Program Objectives</h3>

                  <ol>
                    <li class="list-disc text-gray-700">
                      Understand the importance of customer success and its
                      relationship to revenue generation.
                    </li>

                    <li class="list-disc text-gray-700">
                      Understand the importance of customer success and its
                      relationship to revenue generation.
                    </li>
                    <li class="list-disc text-gray-700">
                      Develop effective communication skills to build strong
                      customer relationships.
                    </li>
                    <li class="list-disc text-gray-700">
                      Identify customer segments with cross-sell and upsell
                      potential.
                    </li>
                    <li class="list-disc text-gray-700">
                      Deepen product knowledge to articulate the value of
                      cross-selling and upselling.
                    </li>
                    <li class="list-disc text-gray-700">
                      Recognize opportunities for cross-selling and upselling.
                    </li>
                    <li class="list-disc text-gray-700">
                      Proactively recommend relevant products and services.
                    </li>
                    <li class="list-disc text-gray-700">
                      Overcome objections and address customer concerns.
                    </li>
                    <li class="list-disc text-gray-700">
                      Cultivate a customer-centric mindset for solution-oriented
                      selling.
                    </li>
                    <li class="list-disc text-gray-700">
                      Enhance phone-based and written interaction skills for
                      cross-selling and upselling.
                    </li>
                    <li class="list-disc text-gray-700">
                      Identify & explore simple yet effective negotiation
                      techniques
                    </li>
                  </ol>
                </div>
              </div>
              <div v-if="this.isAuthenticated" class="spacer hero z-20">
                <img
                  src="../assets/logo-gupshup.svg"
                  width="429"
                  sizes="(max-width: 479px) 90vw, (max-width: 991px) 429px, (max-width: 1439px) 32vw, 429px"
                  srcset="
                    ../assets/logo-gupshup.svg                             500w,
                    ../assets/logo-gupshup.svg                             800w,
                    ../../catalyft-ui-templates/images/home_hero_image.jpg 858w
                  "
                  alt="Course Teachers - Academy Webflow Template"
                  class="image hero"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";

import FlashMessage from "@/components/flashMessage.vue";
import { mapGetters } from "vuex";
import LeftNavigationView from "@/components/LeftNav.vue";

export default {
  name: "Home",
  data() {
    return {
      messages: {
        text: "",
        type: "null",
      },
      title: "",
      activeCourse: "",
    };
  },
  computed: mapGetters(["isAuthenticated", "userInfo"]),
  components: {
    Navigation,
    Footer,
    FlashMessage,
    LeftNavigationView,
  },

  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.marquee_list {
  --marquee-width: 50vw;
  --marquee-height: 64;
  /* --marquee-elements: 12; */
  /* defined with JavaScript */
  --marquee-elements-displayed: 5;
  --marquee-element-width: calc(
    var(--marquee-width) / var(--marquee-elements-displayed)
  );
  --marquee-animation-duration: calc(var(--marquee-elements) * 3s);
}

.marquee {
  width: var(--marquee-width);
  height: var(--marquee-height);

  overflow: hidden;
  position: relative;
}

.marquee:before,
.marquee:after {
  position: absolute;
  top: 0;
  width: 10rem;
  height: 100%;
  content: "";
  z-index: 1;
}

.marquee:before {
  left: 0;
  background: linear-gradient(
    to right,
    rgb(255, 255, 255) 0%,
    transparent 100%
  );
}

.marquee:after {
  right: 0;
  background: linear-gradient(to left, rgb(255, 255, 255) 0%, transparent 100%);
}

.marquee-content {
  list-style: none;
  height: 100%;
  display: flex;
  gap: 6px;
  animation: scrolling var(--marquee-animation-duration) linear infinite;
}

/* .marquee-content:hover {
  animation-play-state: paused;
} */
@keyframes scrolling {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(
      calc(-1 * var(--marquee-element-width) * var(--marquee-elements))
    );
  }
}

.marquee-content img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: var(--marquee-element-width);
  max-height: 100%;
  white-space: nowrap;
}

.marquee-content img {
  width: 224px;
  /* height: 100%; */
}

@media (max-width: 850px) {
  .marquee_list {
    --marquee-width: 100vw;
    --marquee-height: 64px;
    --marquee-elements-displayed: 3;
  }

  .marquee:before,
  .marquee:after {
    width: 5rem;
  }
}
</style>
