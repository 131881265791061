<template>
<div id="main" class="page-wrapper " style="display:none;">
    <Navigation />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <div v-if="is_video==true" class="fixed top-0 bg-black bg-opacity-500 w-full h-full flex items-center" style="z-index:1000">
        <div class="w-full h-full relative-md mx-auto max-w-sm md:max-w-2xl max-h-56 md:max-h-96">
            <!-- <iframe class="w-full h-full mx-auto rounded" :src="this.courseDetails[0].video_url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen> </iframe> -->
            <template v-if="this.courseDetails[0].video_url">
            <video :src="this.courseDetails[0].video_url" controls width="1200"></video> 
            </template>
            <template v-else>
                <div class="max-w-5xl w-full">
                <p class="text-5xl text-white ">No Data</p>
                </div>
            </template>
            <p @click="is_video=false" class="absolute -top-14 -right-14 font-bold text-white text-2xl z-50 cursor-pointer hover:text-red-500">X</p>
        </div>
    </div>
    <div class="section course wf-section">
        <div v-if="this.courseDetails[0] && this.courseDetailInfo" class="container-default-1209px w-container">
            <div class="course-wrapper">
                <div class="about-course">
                    <div data-w-id="5c5cb2e0-9139-5c2c-442b-67253e009bb2" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="top-content course">
                        <p class="title course-page">{{this.courseDetails[0].title}}</p>
                        <p class="paragraph course-description">{{this.courseDetails[0].short_description}} </p>
                        <div class="w-layout-grid advantage-course-grid">
                            <div class="advantage-course-wrapper"><img src="../../catalyft-ui-templates/images/icon-advantage-course-01-academy-template.svg" alt="Laptop  Icon - Academy Webflow Template" class="advantage-course-icon">
                                <div class="advantage-course-text">Online Videos</div>
                            </div>
                            <div class="advantage-course-wrapper"><img src="../../catalyft-ui-templates/images/icon-advantage-course-02-academy-template.svg" alt="Files Icon - Academy Webflow Template" class="advantage-course-icon">
                                <div class="advantage-course-text">Multiple Resources</div>
                            </div>
                            <div class="advantage-course-wrapper"><img src="../../catalyft-ui-templates/images/icon-advantage-course-03-academy-template.svg" alt="Chat Icon - Academy Webflow Template" class="advantage-course-icon">
                                <div class="advantage-course-text">Active Community</div>
                            </div>
                            <div class="advantage-course-wrapper"><img src="../../catalyft-ui-templates/images/icon-advantage-course-04-academy-template.svg" alt="Mentor Icon - Academy Webflow Template" class="advantage-course-icon">
                                <div class="advantage-course-text">One-on-One Mentorship</div>
                            </div>
                        </div>
                        <a data-w-id="5c5cb2e0-9139-5c2c-442b-67253e009bc8" href="#" class="course-teacher-wrapper w-inline-block"><img :src="this.courseDetailInfo.profile" alt="" class="image course-teacher">
                            <div>
                                <div class="course-teacher-name capitalize">{{this.courseDetails[0].instructor}}</div>
                                <div class="teacher-work _2"></div>
                            </div>
                        </a>
                    </div>
                    <div class="spacer about-course"></div>
                    <div class="card course-mobile">
                        <div class="course-preview"><img :src="this.courseDetails[0].thumbnail" alt="" class="image course-page">
                            <div class="course-preview-wrapper">
                                <a href="#" class="course-preview-button w-inline-block w-lightbox">
                                    <div class="button-play-wrapper">
                                        <img @click="video_fwd()" src="../../catalyft-ui-templates/images/icon-play-academy-template.svg" alt="Play Icon - Academy Webflow Template" class="button-play course"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="about-course-content">
                        <div class="w-full h-full" style="width:620px">
                            <section class="py-2 flex flex-col md:flex-row gap-4 w-full px-4 rounded  bg-opacity-50 justify-between border-b">
                                <div @click="tap='Description'" class="py-2 px-3 text-base xl:text-lg cursor-pointer " :class="{'border-b-4 border-blue-400  ' : tap=='Description'}">Description</div>
                                <div @click="tap='Curriculum'" class="py-2 px-3 text-base xl:text-lg cursor-pointer" :class="{'border-b-4 border-blue-400' : tap=='Curriculum'}">Curriculum</div>
                                <div @click="tap='feedback'" class="py-2 px-3 text-base xl:text-lg cursor-pointer" :class="{'border-b-4 border-blue-400' : tap=='feedback'}">Reviews</div>
                            </section>
                            <div class="course-tabs-content">
                                <div v-if="tap=='Description'">
                                    <div>
                                        <p class="text-base md:text-lg lg:text-xl text-gray-600 xl:text-2xl py-3 ">Course Description</p>
                                        <!--  Markdown previwer -->
                                        <v-md-preview :text="courseDetails[0].description"></v-md-preview>
                                        <!-- <p class="text-sm lg:text-base">{{filterSlug[0].description}}</p> -->
                                    </div>
                                </div>
                                <div v-if="tap=='Curriculum'" class="h-full w-full overflow-y-auto ">
                                    <p class="font-semibold text-xl text-gray-700 mb-2">Curriculum</p>
                                    <div v-if="sectionInfo!= null" class="px-0.5 md:px-4">
                                        <div v-for="section in sectionInfo.data" :key="section" class="mb-8">
                                            <div class="flex items-center gap-2 py-1">
                                                <p class="text-sm md:text-xl text-gray-700 ">{{section.title}}</p>
                                            </div>
                                            <div v-if="Object.keys(this.lessonDetails) != 'error'">
                                                <div @click="redirectToVideo(lesson,index)" v-for="lesson , index in lessonDetails.results" :key="lesson" class="">
                                                    <p v-if="section.id == lesson.section_id" class="text-xs md:text-md lg:text-base bg-gray-100 bg-opacity-50 w-full  m-1 p-2 rounded truncate">
                                                        {{lesson.title}} <span class="hidden md:block border float-right text-xs primary-color px-3 py-0.5 rounded ">{{lesson.duration}}min</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="tap=='feedback'" class="w-full">
                                    <div v-if="this.avgrating.data" class="flex flex-col gap-4 w-full">
                                        <p class="text-3xl text-gray-600">Student Feedback</p>
                                        <div class="flex h-48 items-center border shadow-lg rounded-lg justify-center" style="max-width:750px">
                                            <div class="flex flex-col gap-2 h-full w-3/12 justify-center items-center">
                                                <p class="text-6xl font-semibold text-gray-600">{{this.avgrating.data.avgrating.toFixed(1)}}</p>
                                                <div class="">
                                                    <div class="Stars" :style="{'--rating': this.avgrating.data.avgrating}" aria-label="Rating of this product is 2.3 out of 5."> </div>
                                                </div>
                                                <p>Course Rating</p>
                                            </div>
                                            <div class="flex flex-col justify-center w-9/12 h-full items-center">
                                                <div class="flex items-center gap-2 w-full px-2">
                                                    <div class="shadow bg-white mt-1 ml-1 mr-1 border-black w-4/6">
                                                        <div class="border-4 text-xs leading-none font-medium text-center " :class="[this.avgrating.data.percentage5==0 ? 'border-white' : 'border-gray-400']" :style="{'width': this.avgrating.data.percentage5 +'%'}"></div>
                                                    </div>
                                                    <div class="flex gap-2">
                                                        <div class="">
                                                            <div class="Stars" style="--rating: 5" aria-label="Rating of this product is 2.3 out of 5."> </div>
                                                        </div>
                                                        <p class="line-bar font-semibold text-sm text-blue-400 flex gap-1 w-12"> <span class="w-2/3"> {{this.avgrating.data.percentage5 }}% </span> <span class="w-1/3">({{this.avgrating.data.rating5}})</span> </p>
                                                    </div>
                                                </div>

                                                <div class="flex items-center gap-2 w-full px-2">
                                                    <div class="shadow bg-white mt-1 ml-1 mr-1 border-black w-4/6">
                                                        <div class="border-4 text-xs leading-none font-medium text-center " :class="[this.avgrating.data.percentage4==0 ? 'border-white' : 'border-gray-400']" :style="{'width': this.avgrating.data.percentage4 + '%'}"></div>
                                                    </div>
                                                    <div class="flex gap-2">
                                                        <div class="">
                                                            <div class="Stars" style="--rating: 4" aria-label="Rating of this product is 2.3 out of 5."> </div>
                                                        </div>
                                                        <p class="line-bar font-semibold text-sm text-blue-400 flex gap-1 w-12"> <span class="w-2/3"> {{this.avgrating.data.percentage4 }}% </span> <span class="w-1/3">({{this.avgrating.data.rating4}})</span> </p>
                                                    </div>
                                                </div>
                                                <div class="flex items-center gap-2 w-full px-2">
                                                    <div class="shadow bg-white mt-1 ml-1 mr-1 border-black w-4/6">
                                                        <div class="border-4  text-xs leading-none font-medium text-center " :class="[this.avgrating.data.percentage3==0 ? 'border-white' : 'border-gray-400']" :style="{'width': this.avgrating.data.percentage3 + '%'}"></div>
                                                    </div>
                                                    <div class="flex gap-2">
                                                        <div class="">
                                                            <div class="Stars" style="--rating: 3" aria-label="Rating of this product is 2.3 out of 5."> </div>
                                                        </div>
                                                        <p class="line-bar font-semibold text-sm text-blue-400 flex gap-1 w-12"> <span class="w-2/3"> {{this.avgrating.data.percentage3 }}% </span> <span class="w-1/3">({{this.avgrating.data.rating3}})</span> </p>
                                                    </div>
                                                </div>
                                                <div class="flex items-center gap-2 w-full px-2">
                                                    <div class="shadow bg-white mt-1 ml-1 mr-1 border-black w-4/6">
                                                        <div class="border-4 text-xs leading-none font-medium text-center " :class="[this.avgrating.data.percentage2==0 ? 'border-white' : 'border-gray-400']" :style="{'width': this.avgrating.data.percentage2 + '%'}"></div>
                                                    </div>
                                                    <div class="flex gap-2">
                                                        <div class="">
                                                            <div class="Stars" style="--rating: 2" aria-label="Rating of this product is 2.3 out of 5."> </div>
                                                        </div>
                                                        <p class="line-bar font-semibold text-sm text-blue-400 flex gap-1 w-12"> <span class="w-2/3"> {{this.avgrating.data.percentage2 }}% </span> <span class="w-1/3">({{this.avgrating.data.rating2}})</span> </p>
                                                    </div>
                                                </div>
                                                <div class="flex items-center gap-2 w-full px-2">
                                                    <div class="shadow bg-white mt-1 ml-1 mr-1 border-black w-4/6">
                                                        <div class="border-4 text-xs leading-none font-medium text-center " :class="[this.avgrating.data.percentage1==0 ? 'border-white' : 'border-gray-400']" :style="{'width': this.avgrating.data.percentage1 + '%'}"></div>
                                                    </div>
                                                    <div class="flex gap-2">
                                                        <div class="">
                                                            <div class="Stars" style="--rating: 1" aria-label="Rating of this product is 2.3 out of 5."> </div>
                                                        </div>
                                                        <p class="line-bar font-semibold text-sm text-blue-400 flex gap-1 w-12"> <span class="w-2/3"> {{this.avgrating.data.percentage1 }}% </span> <span class="w-1/3">({{this.avgrating.data.rating1}})</span> </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="this.is_paid==true || this.courseDetails[0].course_free == true" class="w-full max-w-4xl flex flex-col gap-4">
                                            <p class="text-3xl text-gray-600">Reviews</p>
                                            <div class="w-full flex justify-between gap-4">
                                                <div class="w-9/12 relative">
                                                    <input v-model="searchvalue" type="text" class="border w-full border-black h-12 rounded-sm pr-12" placeholder="Search...">
                                                    <div class="bg-black absolute top-0 right-0 h-12 w-10 flex items-center justify-center">
                                                        <font-awesome-icon class=" text-xl text-white self-center" :icon="['fas','search']" />
                                                    </div>
                                                </div>
                                                <select v-model="ratingfilter" class="w-3/12 border border-black rounded-sm">
                                                    <option value="0">All star</option>
                                                    <option value="5">Five star</option>
                                                    <option value="4">Four star</option>
                                                    <option value="3">Three star</option>
                                                    <option value="2">Two star</option>
                                                    <option value="1">One star</option>
                                                </select>
                                            </div>
                                        </div>

                                        <!-- user Rating -->
                                        <template v-if="this.is_paid==true || this.courseDetails[0].course_free == true">
                                            <div v-for="i in filteredData" :key="i" class="flex gap-4 w-full rounded-md shadow-lg p-2">
                                                <!-- <font-awesome-icon class="text-5xl p-2 text-gray-500 self-center border rounded-full " :icon="['fas','user']" /> -->
                                                <img :src="i.user_id.profile" alt="" class="h-14 w-14 p-2 text-gray-500 self-center border rounded-full ">

                                                <div class="" style="  line-height: 0.6;">
                                                    <p class="text-gray-600 capitalize ">{{i.user_id.display_name}}</p>
                                                    <div class="flex gap-2  items-center">
                                                        <div id="starBody">
                                                            <div class="Stars " :style="{'--rating': i.rating}" aria-label="Rating of this product is 2.3 out of 5."> </div>
                                                        </div>
                                                        <div class="text-sm text-blue-400">{{i.date}}</div>
                                                    </div>
                                                    <p class="text-sm">{{i.feedback}}</p>
                                                </div>
                                            </div>
                                        </template>

                                        <!-- review to Course -->
                                        <div v-if="this.is_paid==true || this.courseDetails[0].course_free == true" class="w-full flex flex-col gap-2 border p-4 rounded">
                                            <p class="text-3xl text-gray-600">Review to Course</p>
                                            <form ref="feedbackfrom" @submit.prevent="FeedbackPost" class="flex flex-col gap-2">
                                                <div class="flex gap-2 flex-col">
                                                    <p class="text-xl text-gray-600 font-semibold">Rating</p>

                                                    <div id="ratingRange" class="flex">
                                                        <div v-for="i in 5 " :key="i" class="flex">
                                                            <img :id="`star`+i" @click="starselect(`star`+i)" src="https://img.icons8.com/color/48/000000/star--v1.png" class="h-8 w-8" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="flex flex-col gap-2">
                                                    <h5 class="text-xl text-gray-600 font-semibold">Comments</h5>
                                                    <textarea name="reviewComments" id="reviewComments" cols="60" rows="10" class="w-full rounded"></textarea>
                                                </div>
                                                <button type="submit" class="gradient-btn mt-3 w-32">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="spacer course"></div>
                <div data-w-id="5c5cb2e0-9139-5c2c-442b-67253e009c41" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="sidebar course">
                    <div class="card course">
                        <div class="course-preview"><img :src="this.courseDetails[0].thumbnail" alt="" class="image course-page">
                            <div class="course-preview-wrapper">
                                <a href="#" class="course-preview-button w-inline-block w-lightbox">
                                    <div class="button-play-wrapper">
                                        <img @click="video_fwd()" src="../../catalyft-ui-templates/images/icon-play-academy-template.svg" alt="Play Icon - Academy Webflow Template" class="button-play course"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-course"></div>
    </div>
    <div class="container-default-1209px w-container">
        <div data-w-id="9bd4c19c-51d0-fbd8-40a7-a7b25e97372c" style="-webkit-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="divider"></div>
    </div>
    <div data-w-id="7f9ee631-ebdc-f61e-3211-8ea9882c02f6" style="-webkit-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="section related-courses wf-section">
        <div class="container-default-1209px w-container">
            <div data-w-id="7f9ee631-ebdc-f61e-3211-8ea9882c02f8" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="top-content related-courses">
                <div class="split-content courses-section-left">
                    <h2 class="title related-courses">Related Courses</h2>
                </div>
                <router-link :to="{name:'Courses'}" class="button-secondary w-button">All Courses</router-link>
            </div>
            <div class="w-dyn-list">
                <div v-if="this.courselist" role="list" class="courses-section-grid w-dyn-items">
                    <div v-for="course in this.courselist" :key="course" data-w-id="7f9ee631-ebdc-f61e-3211-8ea9882c0302" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" role="listitem" class="course-card-wrapper w-dyn-item">
                        <div @click="forwardCouse(course)" class="card w-80 md:w-96" style="height:528px">
                            <a href="#" class="card-image-link w-inline-block"><img :src="course.thumbnail" style="overflow: hidden;height:208px" alt="" class="image course w-80 md:w-96"></a>
                            <div class="card-content">
                                <a href="#" class="card-title-link w-inline-block">
                                    <h3 class="text-lg 2xl:text-xl course">{{course.title.slice(0,80)}} <span v-if="course.title.length>80">....</span></h3>
                                </a>
                                <p class="text-base primary-text-color">{{course.short_description.slice(0,80)}} <span v-if="course.short_description.length>80">....</span></p>
                                <div class="divider course-card"></div>
                                <div class="course-card-details-wrapper">
                                    <div class="level-wrapper">
                                        <img v-if="course.level=='beginner'" src="../../catalyft-ui-templates/images/icon-level-01-academy-template.svg" alt="Beginners - Academy Webflow Template" class="level-icon">
                                        <img v-else-if="course.level=='Intermediate'" src="../../catalyft-ui-templates/images/icon-level-02-academy-template.svg" alt="Beginners - Academy Webflow Template" class="level-icon">
                                        <img v-else src="../../catalyft-ui-templates/images/icon-level-03-academy-template.svg" alt="Beginners - Academy Webflow Template" class="level-icon">
                                        <div class="w-dyn-list">
                                            <div role="list" class="levels-list w-dyn-items">
                                                <div role="listitem" class="level-text-wrapper w-dyn-item">
                                                    <a href="#" class="level-text">{{course.level}}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="course.course_free != true" class="course-card-price">&#8377;{{course.price}}</div>
                                    <div v-else class="font-semibold text-red-600 tracking-wide">&#8377; Free</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="card empty-state w-dyn-empty">
                    <div class="empty-state-text">There are no courses available yet.</div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-default-1209px w-container">
        <div data-w-id="d0a06249-e723-1509-686c-a89910190192" style="-webkit-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="divider"></div>
    </div>
    <Footer />
</div>
<div id="main2" class="w-full h-screen flex items-center justify-center ">
    <img src="../assets/LoadingBall.svg" alt="" class="w-32 h-32">
</div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import axios from "axios";
import Footer from "@/components/Footer.vue";
import FlashMessage from "@/components/flashMessage.vue";
import {
    mapGetters,
    mapActions
} from "vuex";
export default {
    data() {
        return {
            courseDetails: '',
            courseDetailInfo: '',
            lessonDetails: '',
            lessonCount: '',
            sectionDetails: '',
            sectionInfo: '',
            tap: 'Description',
            feedback: '',
            givenstar: 0,
            avgrating: '',
            courselist: '',
            searchvalue: '',
            ratingfilter: 0,
            is_video: false,
            is_paid: false,
            messages: {
                text: "",
                type: 'null',
            },
            is_loading: false
        }
    },
    props: ["slug"],
    components: {
        Navigation,
        Footer,
        FlashMessage
    },
    computed: {
        ...mapGetters(["userInfo", "subscriptionInfo", "isAuthenticated"]),
        filteredData() {
            let result_Filter = []
            if (this.feedback) {
                result_Filter = this.feedback.filter(data => {
                    return data.user_id.display_name.toLowerCase().includes(this.searchvalue.toLowerCase()) || data.feedback.toLowerCase().includes(this.searchvalue.toLowerCase())
                })
            }
            if (this.feedback && this.ratingfilter == 0) {
                return result_Filter
            } else if (this.feedback && this.ratingfilter != 0) {
                result_Filter = this.feedback.filter(data => {
                    return parseInt(data.rating) == parseInt(this.ratingfilter)
                })
            }
            return result_Filter
        }
    },

    async created() {
        await this.getSubinfo()

        await axios.get(`${process.env.VUE_APP_API}/coursesdata/${this.courseDetails[0].id}/`)
            .then(response => {

                this.courseDetailInfo = response.data

            })
        axios.get(`${process.env.VUE_APP_API}/lessons/?course_name=${this.courseDetails[0].id}`)
            .then(response => {

                this.lessonDetails = response.data
                if (Object.keys(this.lessonDetails) == "error") {
                    console.log("Null")
                    this.lessonCount = 0
                } else {
                    this.lessonCount = this.lessonDetails.count
                }
            })
        axios.get(`${process.env.VUE_APP_API}/section/?course_name=${this.courseDetails[0].id}`)
            .then(response => {
                this.sectionInfo = response.data
            })

        axios.get(`${process.env.VUE_APP_API}/courses/?is_published=true&format=json`)
            .then(response => {
                this.courselist = response.data.data
                // console.log(this.courselist)
                if (this.courselist.length >= 3) {

                    this.courselist = this.courselist.slice(0, 3)
                } else {
                    this.courselist
                }
            })

        this.courseFeedbackGet()
    },

    async mounted() {
        setTimeout(() => {
            document.querySelector("#main").style.display = "block"
            document.querySelector("#main2").style.display = "none"
        }, 3000)

    },

    methods: {
        ...mapActions(["subcription"]),

        async getSubinfo() {
            await axios.get(`${process.env.VUE_APP_API}/courses/?format=json&slug=${this.slug}`)
                .then(response => {
                    this.courseDetails = response.data.data
                    if (this.subscriptionInfo != "Nothing to show") {
                        var subscribe_list = []
                        this.subscriptionInfo.forEach(val => {
                            subscribe_list.push(val.course[0])
                        })
                        if (subscribe_list.includes(this.courseDetails[0].id)) {
                            this.is_paid = true
                        } else {
                            this.is_paid = false
                        }
                    } else {
                        this.is_paid = false
                    }

                })
        },

        startCourse(id) {
            this.$router.push({
                name: 'VideoRender',
                params: {
                    courseid: id,
                }
            })
        },

        video_fwd() {
            this.is_video = true
        },

        async forwardCouse(course) {
            await this.$router.push({
                name: 'CouseDetail',
                params: {
                    slug: course.slug,
                }
            })
            location.reload();
        },

        starselect(el) {

            let valOfstar = el.slice(4, 5)
            this.givenstar = parseInt(valOfstar)
            for (let i = 1; i <= 5; i++) {
                document.getElementById(`star` + i).src = "https://img.icons8.com/color/48/000000/star--v1.png"
            }
            for (let i = 1; i <= valOfstar; i++) {
                document.getElementById(`star` + i).src = "https://cdn.pixabay.com/photo/2021/10/11/00/58/star-6699069__340.png"
            }
        },
        async courseFeedbackGet() {
            // course Feedback
            await axios.get(`${process.env.VUE_APP_API}/course/feedback/rating/${this.courseDetails[0].id}`)
                .then(response => {
                    this.avgrating = response.data
                })
            await axios.get(`${process.env.VUE_APP_API}/course/feedback/select/?course_id=${this.courseDetails[0].id}`)
                .then(response => {
                    this.feedback = response.data
                    if (Object.keys(this.feedback) == "message") {
                        this.feedback = ''
                    } else {
                        this.feedback = this.feedback.data
                        this.feedback.forEach(element => {
                            element["date"] = new Date(element.date).toLocaleDateString('en-IN', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric'
                            })
                        })
                    }
                })
        },
        async FeedbackPost() {

            let feedbackheader = {
                'user_id': this.userInfo.user.id,
                'feedback': document.querySelector("#reviewComments").value,
                'rating': this.givenstar,
                'course_id': this.courseDetails[0].id,
                'published': true
            }

            // post method
            await axios.post(`${process.env.VUE_APP_API}/course/feedback/insert/?Key=${this.userInfo.token_key}`, feedbackheader)
                .then(response => {
                    console.log(response.data)

                })

            await this.courseFeedbackGet()
            this.starselect(`star` + 0)
            this.$refs.feedbackfrom.reset()
        },

        paymentPage() {

            this.$router.push({
                name: 'Payment',
                params: {
                    amount: this.courseDetails[0].price,
                    title: this.courseDetails[0].title,
                    slug: this.courseDetails[0].slug,
                    courseId: this.courseDetails[0].id,
                }
            })

        },
        async Enroll() {
            console.log('entroll')
            this.is_loading = true
            const permissiondetails = {
                user: this.userInfo.user.id,
                token_id: this.userInfo.token_key,
                permission_path: [`${this.courseDetails[0].title}`],
                course: [parseInt(this.courseDetails[0].id)],
            }

            // User details push to UserPermision table
            await axios.post(`${process.env.VUE_APP_API}/userpermission/?Key=${this.userInfo.token_key}`, permissiondetails)
                .then(async response => {
                    let subdata = this.userInfo;
                    if (response.data.message == "Course added successfully") {
                        await this.subcription(subdata)
                        this.messages = {
                            text: "Course added successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                    setTimeout(async () => {
                        this.messages = {
                            text: "",
                            type: 'null',
                        }
                        this.is_loading = false
                        await this.getSubinfo()
                    }, 1500)

                })
        }
    }

}
</script>

<style scoped>
.Stars {
    --percent: calc(var(--rating) / 5 * 100%);
    display: inline-block;
    font-size: 40px;
    font-family: Times;
    line-height: 0;

}

.Stars::before {
    content: "★★★★★";
    font-size: 20px;
    letter-spacing: 1px;
    background: linear-gradient(90deg, #fc0 var(--percent), rgb(218, 210, 210) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 0;

}

#starBody {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;

}

* {
    position: relative;
    box-sizing: border-box;
}

.detailsCourse {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 16 px;

}

.parent {
    display: hidden;
    grid-template-columns: repeat(2, 1fr) .75fr;
    grid-template-rows: 40rem minmax(max-content, 1fr);
    gap: .05rem;
}

table.table-fixed {
    font-size: 1rem;
    line-height: 1.75rem;
}

.imgHero {
    background-image: url("https://images.unsplash.com/photo-1509718443690-d8e2fb3474b7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80");
    background-size: cover;

}

@media screen and (max-width: 1024px) {
    .parent {
        display: grid;
        grid-template-rows: 35rem 35rem auto 4rem;
    }
}

.content {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    /* background-color: #f1f1f1; */
}
</style>
