<template>
  <div class="page-wrapper">
    <Navigation />
    <div class="max-w-7xl w-full mx-auto">
        <div class="mt-10">
            <form @submit.prevent="studentEnroll" class="flex flex-col max-w-lg mx-auto w-full gap-4">
                <h4 class="text-center">Student Enrollment</h4>
                <input type="text" id="user_name" class="h-10 border border-gray-300 rounded-md text-sm" placeholder="User Name" />
                <input type="email" id="user_mail" class="h-10 border border-gray-300 rounded-md text-sm" placeholder="Email address" />
                <select name="" id="user_course" class="h-10 border border-gray-300 rounded-md text-sm">
                    <option value="FLM">FLM</option>
                    <option value="SLM">SLM</option>
                    <option value="IC">IC</option>
                </select>
                <p v-if="messages.message != ''" class="text-center py-0.5 rounded-md" :class="[this.messages.status==200 ? 'bg-green-800 text-white' : 'bg-red-800 text-white']">{{ this.messages.message }} </p>
                <div class="text-center">
                    <button type="submit" class="bg-primary px-2 py-1 rounded-md">Submit</button>
                </div>
            </form>
        </div>

    </div>
</div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import axios from "axios";
import {
    mapGetters
} from "vuex";
export default {
    data(){
        return{
            messages:{
                message : '',
                status : 200
            }
        }
    },
    components: {
        Navigation,
    },
    computed: mapGetters(['userInfo']),
    methods:{
        studentEnroll(el){
            let dataJson = {
                "name":el.target.elements.user_name.value,
                "email": el.target.elements.user_mail.value,
                "type":el.target.elements.user_course.value
            }
            axios.post(`${process.env.VUE_APP_API}/user/onboard/?Key=${this.userInfo.token_key}`,dataJson)
            .then(resp=>{
                console.log(resp)
                if(resp.data.status==200){
                    this.messages.message = "Enrollment Done"
                    this.messages.status = 200
                }else{
                    this.messages.message = "Event Failed"
                    this.messages.status = 400
                }
            }).catch(error=>{
                this.messages.message = error
                this.messages.status = 400
            })
            setTimeout(()=>{
                this.messages.message = ''
            },3000)
        }
    }
}
</script>

<style>

</style>