<template>
<div class="sm:flex lg:hidden left-0 fixed top-19 z-50">
    <button @click="toggleClassMenu" class="shadow-xl rounded-r-md border border-white bg-blue-700 z-50" aria-label="Open Menu" style="z-index: 289;">
        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-8 h-8 text-white">
            <path d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
    </button>
</div>
<div class="layout text-base shadow-xl" id="clickfun">
    <!-- 2nd nav -->
    <div class="leftmenu absolute left-0 h-full flex flex-col lg:hidden justify-start items-start w-full text-gray-100 font-medium bg-black bg-opacity-75 " style="z-index: 289;width:100vw">
        <div class="w-44 md:w-56 h-full flex flex-col gap-2 md:gap-3 bg-white">
            <!-- <router-link v-if="userInfo.user.permission == 'super_admin' || userInfo.user.permission == 'admin' " :to="{name:'organizationCourse' ,params:{slug_id:userInfo.slug_id }}" class='cursor-pointer'>
                <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='Dashboard'}">
                    <img src="../../assets/dashboard.svg" class="w-5 h-5">
                    <div>Dashboard</div>
                </div>
            </router-link> -->
            <template v-if="userInfo.user.permission=='super_admin'">
                <router-link :to="{name:'organizationCourse',params:{slug_id:this.userInfo.slug_id}}" class='cursor-pointer'>
                    <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='course'}">
                        <img src="../../assets/studentslist.svg" class="w-5 h-5">
                        <div>Courses</div>
                    </div>
                </router-link>
            </template>
            <router-link :to="{name :'InstructorStudents'}" class='cursor-pointer'>
                <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='studentlist'}">
                    <img src="../../assets/studentslist.svg" class="w-5 h-5">
                    <div>Student List</div>
                </div>
            </router-link>

            <router-link :to='{name :"InstructorMessage"}' class='cursor-pointer '>
                <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='messages'}">
                    <img src="../../assets/message.svg" class="w-5 h-5">
                    <div>Messages</div>
                </div>
            </router-link>

            <router-link :to='{name :"InstructorAssignments"}' class='cursor-pointer '>
                <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='assignments'}">
                    <img src="../../assets/assignment.svg" class="w-5 h-5" alt="">
                    <div>Assignments</div>
                </div>
            </router-link>

            <!-- <router-link :to='{name :"InstructorStudents"}' :class="{'bg-sky-100' : actived=='Students'}">
        <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4">
          <div>Documents</div>
        </div>
      </router-link> -->
            <router-link :to="{name:'InstructorEvents'}">
                <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='events'}">
                    <img src="../../assets/events.svg" class="w-5 h-5" alt="">
                    <div>Events</div>
                </div>
            </router-link>
            <router-link :to='{name :"forum"}'>
                <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='forums'}">
                    <img src="../../assets/forums.svg" class="w-5 h-5" alt="">
                    <div>Group Chat</div>
                </div>
            </router-link>
            <router-link :to='{name :"CourseQuizcreate"}'>
                <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='quiz'}">
                    <img src="../../assets/ideas.png" class="h-6" />
                    <div>Quiz</div>
                </div>
            </router-link>
        </div>
        <img @click="lefnev=false" src="../../assets/closebutton.svg" class="menu-icon absolute w-8 h-8 right-2">
    </div>

    <!-- left nav for lg screen -->
    <div class="hidden w-48 2xl:w-56 largerLayout h-full lg:flex lg:flex-col justify-start items-start text-black font-medium relative gap-2 " >
        <!-- <img src="../../assets/leftArrow.svg" class="w-8 p-1 LeftArrow absolute -right-3.5 top-3.5 z-50 bg-teal-500 hover:bg-teal-600 rounded-lg" @click="slideFn" /> -->
        <div class="collapseDiv w-48 2xl:w-52 flex items-center bottom-0 cursor-pointer px-4 py-1 gap-2 border rounded-md border-gray-100 shadow-sm mx-auto" @click="slideFn">
            <img src="../../assets/leftArrow.svg" class="w-7 p-1 LeftArrow " />
            <p class="collapseText text-sm mb-0">Collapse sidebar</p>
        </div>
        <!-- <router-link v-if="userInfo.user.permission == 'super_admin' || userInfo.user.permission == 'admin'" :to="{name:'organizationCourse' ,params:{slug_id:userInfo.slug_id }}" class='cursor-pointer'>
            <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black  border-gray-200 flex gap-2 px-4 rounded-md border " :class="{'primary-border bg-gray-100 border-l-4' : actived=='Dashboard'}">
                <img src="../../assets/dashboard.svg" class="w-6 h-6">
                <div>Dashboard</div>
            </div>
        </router-link> -->
        <template v-if="userInfo.user.permission=='super_admin'">
            <router-link :to="{name:'organizationCourse',params:{slug_id:this.userInfo.slug_id}}" class='cursor-pointer'>
            <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black  border-gray-200 flex gap-2 px-4 rounded-md border " :class="{'primary-border bg-gray-100 border-l-4' : actived=='courses'}">
                <img src="../../assets/studentslist.svg" class="w-6 h-6">
                <div>Courses</div>
            </div>
        </router-link>
        </template>
        <router-link :to="{name :'InstructorStudents'}" class='cursor-pointer'>
            <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black  border-gray-200 flex gap-2 px-4 rounded-md border " :class="{'primary-border bg-gray-100 border-l-4' : actived=='studentlist'}">
                <img src="../../assets/studentslist.svg" class="w-6 h-6">
                <div>Student List</div>
            </div>
        </router-link>

        <router-link :to='{name :"InstructorMessage"}' class='cursor-pointer'>
            <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black  border-gray-200 flex gap-2 px-4 rounded-md border " :class="{'primary-border bg-gray-100 border-l-4' : actived=='messages'}">
                <img src="../../assets/message.svg" class="w-6 h-6">
                <div>Messages</div>
            </div>
        </router-link>

        <router-link :to='{name :"InstructorAssignments"}' class='cursor-pointer'>
            <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black  border-gray-200 flex gap-2 px-4 rounded-md border " :class="{'primary-border bg-gray-100 border-l-4' : actived=='assignments'}">
                <img src="../../assets/assignment.svg" class="w-6 h-6" alt="">
                <div>Assignments</div>
            </div>

        </router-link>
        <!-- <router-link :to='{name :"InstructorStudents"}' :class="{'primary-border bg-gray-100 border-l-4' : actived=='documents'}">
      <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black  border-gray-200 flex gap-2 px-4 rounded-md border ">
        <div>Documents</div>
      </div>
      </router-link> -->
        <router-link :to="{name:'InstructorEvents'}">
            <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black  border-gray-200 flex gap-2 px-4 rounded-md border " :class="{'primary-border bg-gray-100 border-l-4' : actived=='events'}">
                <img src="../../assets/events.svg" class="w-6 h-6" alt="">
                <div>Events</div>
            </div>
        </router-link>
        <router-link :to='{name :"forum"}'>
            <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black  border-gray-200 flex gap-2 px-4 rounded-md border " :class="{'primary-border bg-gray-100 border-l-4' : actived=='forums'}">
                <img src="../../assets/forums.svg" class="w-6 h-6" alt="">
                <div>Group Chat</div>
            </div>
        </router-link>
        <router-link :to='{name :"CourseQuizcreate"}'>
            <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black  border-gray-200 flex gap-2 px-4 rounded-md border " :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='quiz'}">
                <img src="../../assets/ideas.png" class="h-6" />
                <div>Quiz</div>
            </div>
        </router-link>
    </div>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
export default {
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters(['userInfo'])
    },
    props: {
        actived: String
    },
    mounted() {
        var oppMenu = document.querySelector(".menu-icon");
        oppMenu.addEventListener("click", this.toggleClassMenu, false);
    },
    methods: {

        slideFn() {
            console.log("Slide")
            document.querySelector(".LeftArrow").classList.toggle("rotateimg");
            document.querySelector(".collapseText").classList.toggle("hidden");
            document.querySelector(".collapseDiv").classList.toggle("border-none");

            var largelayout = document.querySelector(".largerLayout")
            if (!largelayout.classList.contains("app-menu-close")) {
                largelayout.classList.add("app-menu-close");
            } else {
                largelayout.classList.remove("app-menu-close");
            }
        },

        toggleClassMenu() {
            console.log("toggle");
            var layout = document.querySelector(".layout");
            if (!layout.classList.contains("app-menu-open")) {
                layout.classList.add("app-menu-open");
            } else {
                layout.classList.remove("app-menu-open");
            }
        }
    }
}
</script>

<style scoped>
.largerLayout {
    transition: width 1.5s, ;
}

.rotateimg {
    transform: rotate(180deg) translate(-5px);
}

.contentval {
    transition: width 1.5s, ;
}

.leftmenu {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: transform 150ms linear;
}

.app-menu-open .leftmenu {
    -webkit-transform: none;
    transform: none;
    transition: transform 150ms linear;
}

.app-menu-close {
    width: 60px;
    transition: width 1.5s, ;
    margin: auto;
}

.app-menu-close .contentval {
    width: 60px;
    padding-left: 0px;
    padding-right: 0px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app-menu-close .contentval div {
    display: none;
}
</style>
