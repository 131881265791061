<template lang="">
    <div>
      <Navigation /> 
    </div>
    <router-view />
</template>
<script>
import Navigation from '../../components/Navigation.vue'
export default {
    components: {
        Navigation,
      
    },
    props: ["course_id", "instructor_id", "course_name"],
    
}
</script>
<style lang="">
    
</style>