<template lang="">
<div>
    <Navigation />

    <div class="page-wrapper">
        <div class="section events wf-section">
            <div data-w-id="a64e4142-528b-f7eb-06ea-9629c3dd8759" style="" class="container-medium-727px text-center w-container">
                <h1 class="special-2">My Upcoming <span class="font-color-primary">Events</span>.</h1>
            </div>
            <div class="container-default-1209px w-container">
                <div class="w-dyn-list">
                    <div v-if="webinar_List != ''" role="list" class="events-grid w-dyn-items">
                        <div v-for="el in this.webinar_List" :key="el" data-w-id="55cd279d-65f1-1f8d-20fe-77100a429ba9" style="" role="listitem" class="w-dyn-item">
                            <router-link :to="{name : 'WebinarRegister', params: {webinar_id:el.webinar.id}}"  class="card events" >
                                <div class="w-full lg:w-3/6  flex items-center">
                                    <img :src="el.webinar.thumbnail" alt="" class="h-full w-full" ></div>
                                <div class="card-content events w-full lg:w-3/6 ">
                                    <div class="card-title-link w-inline-block">
                                        <h2 class="title card-event heading-h3-size">{{el.webinar.title}}</h2>
                                    </div>
                                    <p>{{el.webinar.short_description.slice(0,150)}} <span v-if="el.webinar.short_description.length>150">....</span></p>

                                    <div class="divider card-events"></div>
                                    <div class="card-event-details-wrapper">
                                        <div class="event-date-wrapper">
                                            <div class="event-date"><img src="../../catalyft-ui-templates/images/icon-event-01-academy-template.svg" alt="Calendar Icon - Academy Webflow Template" class="event-icon">
                                                <div class="event-details-text">{{ new Date(el.webinar.event_date).toLocaleDateString('en-IN', {day: 'numeric',month: 'long',year: 'numeric'}) }}</div>
                                            </div>
                                            <div class="event-time"><img src="../../catalyft-ui-templates/images/icon-event-02-academy-template.svg" alt="Clock Icon - Academy Webflow Template" class="event-icon">
                                                <div class="event-details-text">{{new Date(el.webinar.event_date).toLocaleTimeString('en-IN')}}</div>
                                            </div>
                                        </div>
                                        <div class="event-location-wrapper" >
                                            <button v-if="el.webinar.event_status" class="px-2 py-0.5 rounded-lg border border-red-400">Done</button>
                                            <button class="px-2 py-0.5 rounded-lg border bg-blue-700 text-white">Upcoming</button>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div v-else class="card empty-state w-dyn-empty">
                        <div class="empty-state-text">There are no events available yet.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Footer />
</div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import {
    mapGetters
} from "vuex"
export default {
    data() {
        return {
            webinar_List : ''
        }
    },
    components: {
        Navigation,
        Footer
    },
        computed: mapGetters(["userInfo"]),

    created(){
        axios.get(`${process.env.VUE_APP_API}/webinar-register-full-details/list/?email=${this.userInfo.user.email}`)
        .then(response =>{
            this.webinar_List=response.data.data
        })
    }

}
</script>

<style lang="">
    
</style>
