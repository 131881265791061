<template>
    <Navigation />
<div class='overflow-y-auto h-screen  flex justify-center ' style="scrollbar-color:gray white ">
    <internLaftNav actived='Profile' />
    <div v-if="is_selected=='profile'" class="absolute right-24 bottom-0 2xl:bottom-24 w-1/2 ">
        <img src="../../assets/organizationProfile.svg" alt="" class="w-full">
    </div>
    <div v-else class="absolute right-24 bottom-24 w-1/3 ">
        <img src="../../assets/organizationMail1.svg" alt="" class="w-full">
    </div>
    <div class='w-full p-0'>
        <div class="w-full border rounded-md shadow-lg" >
            <div class="bg-gray-200 flex gap-2 p-4 rounded-t-md">
                <button class="rounded-md">Mail to Student</button>
            </div>
            <div class="h-auto max-w-4xl">
                <div class="bg-white w-full  flex flex-col justify-center text-md rounded-b-md p-4 h-auto">
                <div class='min-h-screen w-full bg-white flex rounded p-8'>
                    <div class="relative w-full mx-auto bg-gray-50 h-full flex flex-col rounded-md border border-gray-300 p-8 gap-4">
                        <div class="font-semibold text-base md:text-lg 2xl:text-2xl text-gray-700">Mail </div>
                        <div class='w-full '>
                            <form class=' h-full flex flex-col gap-4' @submit.prevent="Sendmail">
                                <div class="mx-auto w-full">
                                    <div class=w-5/6> <label for="sectionTitle" class='text-xl'>
                                        Course
                                        </label>
                                        <select @change="selectTitle($event)" class="p-2 block  rounded-md border border-gray-300 w-4/6" id="sectionTitle">
                                            <option>All</option>
                                            <option v-for="section in info" :key="section" :value="section.id">
                                                {{section.title}}
                                            </option>
                                        </select>
                                        <span v-if="sendtoAllcourse " class="text-xs px-2 text-red-500">Are you Sure want to Send Mail to All Courses</span>
                                    </div>
                                </div>
                                <div id='mailpart' class="flex items-center justify-start">
                                    <div class='w-5/6'>
                                        <label for="sectionMailid" class='text-xl'>
                                            Student
                                        </label>

                                        <select class="p-2 block  rounded-md border border-gray-300 w-4/6" id="sectionMailid" @change="selectMailId">
                                            <option v-if="this.studentList.length > 0">All</option>
                                            <option v-else>None</option>
                                            <option v-for="section in studentList" :key="section" :value="section[0].id">
                                                {{section[0].email}}
                                            </option>
                                        </select>
                                        <span v-if="sendto && this.studentList.length > 0" class="text-xs px-2 text-red-500">Are you Sure want to Send Mail to {{sendto}}</span>
                                    </div>

                                </div>
                                <div class="flex flex-col rounded">
                                    <label for='mailSubject'>Subject</label>
                                    <input type='text' id='mailSubject' class='p-2 block  rounded-md border border-gray-300 w-4/6'>
                                </div>
                                <div class="flex flex-col rounded">
                                    <label for='content'>Content</label>
                                    <textarea id='content' class='p-2 block  rounded-md border border-gray-300 w-5/6 ' rows="10" cols="10"> </textarea>
                                </div>
                                <button id='mailSend' type='submit' class="gradient-btn w-44">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios"
import Navigation from "@/components/Navigation.vue"
import internLaftNav from "@/views/organization/leftNavIntern.vue"
import {
    mapGetters
} from "vuex"

export default {
    data() {
        return {
            info: null,
            sendtoAllcourse: "",
            studentList: "",
            sendto: "",

        }
    },
    computed: mapGetters(["userInfo"]),
    components: {
        Navigation,
        internLaftNav
    },
    created() {
        this.coursedetailsget()
    },
    methods: {
        async Sendmail(el) {
            let courseInfo = el.target.sectionTitle.value
            let mailInfo = el.target.sectionMailid.value
            let subjectInfo = el.target.mailSubject.value
            let contentInfo = el.target.content.value
            const HeaderVal = {
                "subject": subjectInfo,
                "time": "",
                "content_1": contentInfo,
                "content_2": "",
                "content_3": ""
            }
            if (courseInfo == 'All' && mailInfo == "None") {
                await axios.post(`${process.env.VUE_APP_API}/instructor_send_all_student/?Key=${this.userInfo.token_key}`, HeaderVal)
                    .then(response => {
                        if (response.data.message == 'successfully Email send') {
                            this.messages = {
                                text: " Email sent to All Course",
                                type: 'success',
                            }
                        } else {
                            this.messages = {
                                text: " Event Faild",
                                type: 'error',
                            }
                        }
                    })
                // instructor_send_all_student/
            } else if (courseInfo != 'All' && mailInfo == "All") {
                await axios.post(`${process.env.VUE_APP_API}/instructor_send_student/${courseInfo}/?Key=${this.userInfo.token_key}`, HeaderVal)
                    .then(response => {
                        if (response.data.message == 'successfully Email send') {
                            this.messages = {
                                text: "Email sent to All student in Course",
                                type: 'success',
                            }
                        } else {
                            this.messages = {
                                text: " Event Faild",
                                type: 'error',
                            }
                        }
                    })
                // instructor_send_student/<pk>/
            } else {
                await axios.post(`${process.env.VUE_APP_API}/instructor_send_personal/${mailInfo}/?Key=${this.userInfo.token_key}`, HeaderVal)
                    .then(response => {
                        if (response.data.message == 'successfully Email send') {
                            this.messages = {
                                text: "successfully Email to Student ",
                                type: 'success',
                            }
                        } else {
                            this.messages = {
                                text: " Event Faild",
                                type: 'error',
                            }
                        }
                    })
                // instructor_send_personal/<pk>/ 
            }
            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
                this.mailtemplate = false

            }, 1500)
        },
        async selectTitle(event) {
            // console.log(event.target.value);
            this.sendtoAllcourse = ""
            this.sendto = ""
            document.querySelector("#mailpart").classList.replace('hidden', "block")
            let course_id = event.target.value
            if (course_id != 'All') {
                await axios.get(`${process.env.VUE_APP_API}/all_list/${course_id}/?Key=${this.userInfo.token_key}`)
                    .then(response => {
                        this.studentList = response.data.data

                    })
            } else {
                this.sendtoAllcourse = "All Courses"
                this.studentList = ""
                document.querySelector("#mailpart").classList.add('hidden')
            }
        },
        async coursedetailsget() {
            await axios.get(`${process.env.VUE_APP_API}/courses/`)
                .then(response => {
                    if (Object.keys(response.data) == "error") {
                        console.log("Null")
                    } else {
                        this.info = response.data.data
                        this.info.forEach(element => {
                            element.displayprice = new Intl.NumberFormat('hi-IN', {
                                style: 'currency',
                                currency: 'INR',
                                maximumFractionDigits: 0,
                            }).format(element.price);
                        });
                    }
                    if (this.info) {
                        this.info.forEach(async el => {
                            var tempval
                            await axios.get(`${process.env.VUE_APP_API}/coursesdata/${el.id}/`)
                                .then(response => {
                                    tempval = response.data
                                    this.TotalstudentCount += tempval["total_student_count"]
                                })
                            el.duration = tempval["total_duration"]
                            el.studentCount = tempval["total_student_count"]
                            el.profile = tempval["profile"]

                        })
                    }
                })
        },
    }

}
</script>

<style scoped>

</style>
