<template>
<div class="page-wrapper">
    <Navigation />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <confirmPopup v-if='popupwindow==true' :title="titlepass" @cancelClick="popupwindow=false" @confirmClick="deletecourse(idpass)" />
    <div class="container-default-1209px w-container">
        <div data-w-id="4dfb3ae6-c85f-5256-cad4-afe060384278" style="" class="divider"></div>
    </div>
    <div id="Courses" style="" class="section latest-courses ">
        <div class="container-default-1209px w-container">
            <div class="latest-courses-wrapper">
                <h2 style="" class="title latest-courses">Created Courses

                    <router-link :to="{name:'AddCourse'}" class="button-secondary w-button float-right">Add Courses</router-link>
                </h2>
                <div class="w-dyn-list">
                    <div v-if="this.courselist" role="list" class="courses-grid ">
                        <div v-for="course in this.courselist" :key="course" style="height:528px" role="listitem" class="course-card-wrapper w-dyn-item w-80 md:w-96">
                            <div class="card w-80 md:w-96" style="height:528px">
                                <a href="#" class="card-image-link w-inline-block">
                                    <img :src="course.thumbnail" alt="" style="overflow:hidden;height:208px" class="image course w-80 md:w-96">
                                    <div class="absolute right-0 bottom-0 bg-white w-14 h-10 flex justify-end px-2  items-end" style="border-top-left-radius: 3rem;">
                                        <img id="EditImg" src="../assets/edit.svg" alt="" class="w-8 pl-1 cursor-pointer" title="Edit Thumbnail" @click="thumbnailUpdate=true;imgcourseId=course.id">
                                    </div>
                                </a>
                                <div class="card-content">
                                    <a href="#" class="card-title-link w-inline-block">
                                        <h3 class="text-lg 2xl:text-xl course hover:text-red-700">{{course.title.slice(0,80)}} <span v-if="course.title.length>80">....</span></h3>
                                    </a>
                                    <p class="text-base">{{course.short_description.slice(0,80)}} <span v-if="course.short_description.length>80">....</span></p>
                                    <div class="divider course-card"></div>
                                    <div class="course-card-details-wrapper">
                                        <div class="flex gap-1 items-center justify-between ">
                                            <router-link :to="{name:'UpdateLesson',params:{courseId:course.id}}" class="border px-2 rounded flex gap-1 border-green-400 text-sm items-center py-1 hover:bg-green-100 ">
                                                <img src="../assets/Addbutton.svg" alt="" class="h-6 w-6 cursor-pointer" title="Add Lesson">
                                                Add resource
                                            </router-link>
                                            <router-link :to="{name:'EditCourse',params:{course_id:course.id}}" class="border border-gray-200 px-2 rounded flex gap-1 text-sm items-center py-1 hover:bg-gray-100 ">
                                                <img src="../assets/edit.svg" alt="" class="h-6 w-6 cursor-pointer" title="Edit Course">
                                                Edit Course
                                            </router-link>
                                            <button class="border border-red-400 px-2 rounded flex gap-1 text-sm items-center py-1 hover:bg-red-100 " @click="titlepass=course.title;popupwindow=true;idpass=course.id">
                                                <!-- <img src="../../assets/trash.svg" alt="" class="h-6 w-6 cursor-pointer" title="Delete Course" >  -->
                                                <font-awesome-icon class="text-base text-red-400 self-center h-6 w-6" :icon="['fas','trash-alt']" />
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="card empty-state w-dyn-empty">
                        <div class="empty-state-text">There are no courses available yet.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-default-1209px w-container">
        <div data-w-id="4dfb3ae6-c85f-5256-cad4-afe0603842ac" style="" class="divider"></div>
    </div>

    <div class="container-default-1209px w-container">
        <div class="divider"></div>
    </div>
    <Footer />
</div>
<!-- Thumnail Update -->
<div v-if="thumbnailUpdate" class="fixed top-0 w-full min-h-screen bg-white flex items-center justify-center z-50">
    <div class="center h-full flex justify-center items-center ">
        <div class="form-input ">
            <div class="preview w-full h-full relative">
                <img id="imgPreview" class="h-72 w-96 hidden">
                <img v-if="submitting" src="../assets/closebutton.svg" alt="" class="w-7 h-7 absolute right-0 top-0 cursor-pointer" @click="imgreset()">
            </div>
            <form @submit.prevent="thumbnailUpdatefN" ref="imguploadref" class="w-full flex flex-col relative">
                <label v-if="submitting==false" for="imgFileupload" class="cursor-pointer border py-1 px-2 rounded hover:border-green-400">Upload Image</label>
                <input type="file" id="imgFileupload" accept=".jpg, .jpeg, .png, .webp" class="hidden" @change="showPreview();">
                <button v-if="submitting" type="submit" class="border text-center px-4 bg-teal-600 hover:bg-teal-800 text-white font-bold rounded-lg py-2">Submit</button>
                <img v-if="submitting==false" src="../assets/closebutton.svg" alt="" class="w-7 h-7 absolute -right-12 -top-12 cursor-pointer" @click="thumbnailUpdate=false">
            </form>
        </div>
    </div>

</div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import FlashMessage from "@/components/flashMessage.vue";
import confirmPopup from "@/components/confirmPopup.vue";
import axios from "axios";
import {
    mapGetters
} from "vuex"
export default {
    components: {
        Navigation,
        Footer,
        FlashMessage,
        confirmPopup,
    },
    data() {
        return {
            courselist: "",
            instructorInfo: '',
            messages: {
                text: "",
                type: 'null',
            },
            titlepass: "",
            popupwindow: false,
            idpass: "",
            thumbnailUpdate: false,
            imgcourseId: null,
            submitting: false,
        }
    },

    computed: mapGetters(['userInfo']),

    async created() {
        this.getdata()
    },
    methods: {
        async getdata() {
            await axios.get(`${process.env.VUE_APP_API}/instructor/?Key=${this.userInfo.token_key}`)
                .then(response => {

                    this.instructorInfo = response.data
                    console.log(this.instructorInfo, "info")
                })
            await axios.get(`${process.env.VUE_APP_API}/courses/?instructor=${this.instructorInfo.id}`)
                .then(response => {
                    if (response.data.message == "No courses details") {
                        console.log("Null")
                    } else {
                        this.courselist = response.data.data
                        this.courselist.forEach(element => {
                            element.displayprice = new Intl.NumberFormat('hi-IN', {
                                style: 'currency',
                                currency: 'INR',
                                maximumFractionDigits: 0,
                            }).format(element.price);
                        });
                    }
                })
        },

        showPreview() {
            if (event.target.files.length > 0) {
                var src = URL.createObjectURL(event.target.files[0]);
                var preview = document.getElementById("imgPreview");
                preview.src = src;
                preview.style.display = "block";
                this.submitting = true
            }
        },
        imgreset() {
            this.$refs.imguploadref.reset();
            this.thumbnailUpdate = false
            this.submitting = false
        },
        async thumbnailUpdatefN(el) {
            let fileVal = el.target.elements["imgFileupload"].files[0]
            let urls = `${process.env.VUE_APP_API}/course_image/${this.imgcourseId}/?Key=${this.userInfo.token_key}`;
            let formData = new FormData();
            formData.append("thumbnail", fileVal);

            await axios.put(urls, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((response) => {
                if (response.data.message == "Course Image Updated successfully") {
                    this.messages = {
                        text: "Image Uploaded successfully",
                        type: 'success',
                    }
                } else {
                    this.messages = {
                        text: " Event Faild",
                        type: 'error',
                    }
                }
            })
            this.$refs.imguploadref.reset();
            this.thumbnailUpdate = false
            this.submitting = false
            setTimeout(async () => {
                this.messages = {
                    text: "",
                    type: 'null',
                }

                await this.getdata()
            }, 1500)

        },
        async deletecourse(data) {
            await axios.delete(`${process.env.VUE_APP_API}/courseupdate/${data}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    this.res = response
                    if (this.res.statusText == 'No Content') {
                        this.messages = {
                            text: "Deleted successfully",
                            type: 'success',
                        }
                        console.log("successfull")
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })
            this.popupwindow = false

            setTimeout(() => {
                this.$router.go(0);
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 2000)
        },
    }
};
</script>
