<template lang="">
<div class="flex min-h-screen">
    <leftNavigationStudent actived='sessions' />

    <div class="p-1 lg:px-4 lg:py-4 w-full min-h-screen bg-white relative" id='demo'>

        <div v-if="this.documentsInfo" class="absolute flex flex-col justify-between md:flex-row right-0 top-0 w-full bg-white " style="height:100%">
            <div v-if="sectionInfo!= null && popoverLeft==true" class="hidden md:block top-0 bg-gray-100 bg-opacity-96 h-screen md:h-full overflow-y-auto w-full md:w-2/6 lg:w-3/12 2xl:w-1/6">
                <div class="flex w-full bg-gray-900 text-white py-1  items-center justify-between border">
                    <p class=" text-xl px-1 font-semibold"> Curriculum </p>
                    <div class="w-1/3 px-4 flex justify-end cursor-pointer">
                        <img @click="this.popoverLeft=false;" class='w-6 h-6' src="../../assets/closebutton.svg"> </div>
                </div>
                <button v-if="this.pre_quiz == true" @click="quizzget('pre')" class="w-full text-sm lg:text-sm border py-1 md:py-2  bg-white my-1 px-2 rounded cursor-pointer" :class="{'bg-gray-500 text-white'  :this.quizelecture == 'pre'}"> Pre-Quiz </button>
                <div v-for="section in sectionInfo.data" :key="section" class="mb-2 px-1">
                    <div class="flex items-center gap-1 py-1">
                        <p class="text-sm lg:text-base text-gray-700 font-bold">{{section.title}}</p>
                    </div>
                    
                    <div v-for="lesson in lessonInfo.results" :key="lesson" class="">
                        
                        <p @click="getLessonURL(lesson.id)" v-if="section.id == lesson.section_id" class="text-sm lg:text-sm border py-1 md:py-2  bg-white my-1 px-2 rounded cursor-pointer" :class="{'bg-gray-500 text-white' : lesson.id==documentsInfo.id && this.quizelecture == '',}">
                            {{lesson.title}}
                        </p>
                        
                    </div>
                </div>
                <button v-if="this.post_quiz == true" @click="quizzget('post')" class="w-full text-sm lg:text-sm border py-1 md:py-2  bg-white my-1 px-2 rounded cursor-pointer" :class="{'bg-gray-500 text-white'  :this.quizelecture == 'post'}">Post-Quiz</button>
            </div>

            <div v-if="this.quizelecture==''" class="h-screen md:h-full relative" :class="[popoverLeft==true ? 'p-0 w-full md:w-4/6 lg:w-9/12 2xl:w-5/6' : ' w-full' ]">
                <div class="flex flex-col items-center h-full">

                    <p class="w-full py-1 font-semibold px-2 text-gray-900 border-gray-300 text-xl 2xl:text-3xl">{{course_name}}</p>
                    <!-- Breadcrumb -->
                    <!-- <div class="w-full">
                        <ol class="flex gap-2 items-center w-full border-b p-2">
                            <li class="text-gray-500 text-sm">
                                <router-link :to="{name : 'Dashboard'}">
                                    Dashboard
                                </router-link> >
                            </li>
                            <li class="text-gray-500 text-sm">
                                <router-link :to='{name :"sessionStudent"}'>
                                    {{course_name}}
                                </router-link> >
                            </li>
                            <li class="text-gray-500 text-sm">

                                Session

                            </li>
                        </ol>
                    </div> -->
                    <div v-if="renderVideoLink" class="w-full" :class="[renderVideoLink != null ? 'h-2/5' : 'h-0' ]">
                        <iframe class="w-full h-full" :src="renderVideoLink" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                        </iframe>
                    </div>

                    <div class="w-full overflow-y-scroll" :class="[renderVideoLink != null ? 'h-3/5' : 'h-screen' ]">
                        <p class="w-full py-1 px-4 text-base lg:text-2xl font-bold bg-gray-100 truncate">Lesson: {{documentsInfo.title}}</p>
                        <div class="w-full h-full ">

                            <div v-if="documentsInfo.content.length>10" class="overflow-y-scroll border-t  border-gray-300" style="scrollbar-color:gray #f4f4f4; scrollbar-width: thin;">
                                <!-- <markdown-preview id="right" class="bg-white text-sm lg:text-base overflow-y-scroll lg:px-8 rounded" :source="documentsInfo.content" style="scrollbar-color:gray white;"></markdown-preview> -->
                                <v-md-preview class="bg-white text-sm lg:text-base overflow-y-scroll lg:px-8 rounded" :text="documentsInfo.content" style="scrollbar-color:gray white;"></v-md-preview>
                            </div>
                            <div v-else class="text-lg text-center py-4">
                                    No content available
                            </div>
                            <div v-if="documentsInfo.pdf_file != null">
                                <p class="text-2xl">Reference Document</p>
                                
                                <div v-if="documentsInfo.pdf_file.includes('.pdf')" class="h-auto overflow-y-scroll w-full lg:w-4/6  mx-auto">
                                    <vue-pdf-embed :source="documentsInfo.pdf_file" class="w-full lg:w-5/6 2xl:w-4/6 h-screen " />
                                </div>
                                <div v-else class="h-1/2 lg:h-auto overflow-y-auto lg:w-4/6 flex justify-center items-center w-full flex-col gap-2">
                                    <p class="">
                                        Given file is not a pdf file. so we can't show it. download the file to view it.
                                    </p>
                                    <!-- <a :href="documentsInfo.pdf_file" target="_blank" class="border px-2 py-1 rounded">Download</a> -->
                                </div>
                            </div>
                        </div>
                        <div v-if="popoverLeft==false" class='contentIcon flex flex-col gap-2 justify-center w-8 absolute cursor-pointer left-0 h-full  select-none border-l border-gray-400 top-0 p-0 font-bold hover:text-white hover:bg-tael-600 items-center' @click="popoverLeft=true" style="background-color:#222d32">
                            <!-- <div class="w-full flex flex-wrap justify-end text-white">
                            <img class='h-8 w-8 ' src="../../assets/menuicon.svg" /> 
                        </div> -->
                            <p class="text-white text-lg md:text-xl" style=" writing-mode: vertical-rl;font-family: 'Lucida Console', 'Courier New', monospace;">Curriculum</p>
                        </div>
                    </div>
                </div>
                <div v-if="sectionInfo!= null && popoverLeft==true" class="absolute block md:hidden top-0 bg-gray-100 bg-opacity-96 h-full w-full md:w-2/3 lg:w-1/4 z-20">
                    <div class="flex w-full bg-gray-900 text-white py-2 items-center">
                        <p class=" text-xl px-1 font-semibold w-2/3"> Curriculum </p>
                        <div class="w-1/3 px-4 flex justify-end cursor-pointer">
                            <img @click="this.popoverLeft=false;" class='w-6 h-6' src="../../assets/closebutton.svg"> </div>
                    </div>

                    <div v-for="section in sectionInfo.data" :key="section" class="mb-2 px-1">
                        <div class="flex items-center gap-1 py-1">
                            <p class="text-sm lg:text-base text-gray-700">{{section.title}}</p>
                        </div>
                        <div v-for="lesson in lessonInfo.results" :key="lesson" class="">
                            <p @click="getLessonURL(lesson.id)" v-if="section.id == lesson.section_id" class="text-sm lg:text-sm border py-1 md:py-2  bg-white my-1 px-2 rounded cursor-pointer" :class="{'bg-gray-500 text-white' : lesson.id==documentsInfo.id}">
                                {{lesson.title}}
                            </p>

                        </div>
                    </div>
                </div>

            </div>
            <!-- Quizz -->
            <main id="quizBody" v-if="this.quizList && this.quizelecture != ''" class="h-screen gap-2 py-4 relative w-full md:w-4/6 lg:w-9/12 2xl:w-3/6 mx-auto">
                <section class="sectionOne flex flex-col justify-center items-center border w-full mx-auto shadow-lg rounded " style="height:13%">
                    <div class=" w-5/6">
                        <p class=" text-2xl text-balck font-bold">{{this.quizList.quiz.name}}</p>
                        <p class=" text-base text-balck ">{{this.quizList.quiz.description}}</p>

                        <!-- <img :src="quizList.quiz.image" alt="" class="w-72 h-48 "> -->

                    </div>
                </section>
                <section class="flex flex-col  items-center border w-full mx-auto shadow-lg py-2 overflow-y-auto" style="scrollbar-color:gray white;height:87% ">
                    <form ref="quizForm" @submit.prevent="quizSubmit" class="flex flex-col justify-center items-center w-full ">
                        <div v-for="quiz in this.quizListquestion" :key="quiz" class="py-4 w-5/6 ">
                            <p>{{quiz.order}}. {{quiz.question_text}}</p>
                            <ul class="py-2">
                                <li v-for="(answer) in quiz.coursequizanswer_set" :key="answer" @change="checkquizz($event,answer)" class="py-1 flex gap-2 items-center">
                                    <input type="radio" :id="'id_'+answer.id" :name="answer.question" :value="answer.id">
                                    <label :for="'id_'+answer.id">{{answer.answer_text}}</label>
                                </li>
                            </ul>
                        </div>
                        <button id="quizsubmit" type="submit" class="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded ">
                            Submit </button>
                        <!-- <button id="quizDownload" class="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-2 rounded" @click="printDiv('quizBody')"> Download</button> -->
                    </form>
                </section>
                <main v-if="isSubmit && this.quizList" class="absolute w-full top-0  h-full bg-white  flex items-center ">
                    <div class=" w-full h-96 flex flex-col justify-center items-center border shadow-lg">
                        <div class="w-full bg-white  flex flex-col items-center justify-center gap-4 rounded-t-lg " style="height:75%">
                            <p class="w-4/6 text-2xl font-bold text-center">{{this.quizList.quiz.name}}</p>
                            <p class="w-4/6  text-xl font-semibold text-center">{{correctAnswercount}}/{{this.quizList.quiz.coursequizquestion_set.length}}</p>
                        </div>
                        <div class="w-full bg-white  flex gap-6 items-center justify-center rounded-b-lg" style="height:25%">
                            <button class="border w-40 h-12 rounded" @click="Retry()"> Retry</button>
                            <button class="border w-40 h-12 rounded" @click="Review()">Review</button>
                        </div>
                    </div>
                </main>
            </main>
        </div>
        <div v-else>
            <p class="w-full py-1 font-semibold px-2 text-gray-900 border-gray-300 text-xl 2xl:text-3xl">{{course_name}}</p>
            <!-- Breadcrumb -->
            <div class="w-full">
                <ol class="flex gap-2 items-center w-full border-b p-2">
                    <li class="text-gray-500 text-sm">
                        <router-link :to="{name : 'Dashboard'}">
                            Dashboard
                        </router-link> >
                    </li>
                    <li class="text-gray-500 text-sm">
                        <router-link :to='{name :"sessionStudent"}'>
                            {{course_name}}
                        </router-link> >
                    </li>
                    <li class="text-gray-500 text-sm">

                        Session

                    </li>
                </ol>
            </div>
            <div class="text-2xl p-2 text-gray-600">
                No data found
            </div>

        </div>
    </div>

</div>
</template>

<script>
import axios from "axios";
import leftNavigationStudent from "@/views/studentDashboard/leftnav.vue";
import '@uivjs/vue-markdown-preview/markdown.css';
import MarkdownPreview from '@uivjs/vue-markdown-preview';
import VuePdfEmbed from 'vue-pdf-embed';

import {
    mapGetters
} from 'vuex'
export default {
    data() {
        return {
            lessonInfo: null,
            sectionInfo: null,
            documentsInfo: null,
            renderVideoLink: "",
            popoverLeft: true,
            quizList: null,
            backgroundImg: null,
            correctAnswercount: 0,
            isSubmit: false,
            quizListquestion: null,
            quizelecture: '',
            pre_quiz: false,
            post_quiz: false,
            answer_set: [],
            quizTakerId: null,
        }
    },

    props: ["course_id", "instructor_id", "course_name"],

    computed: mapGetters(["userInfo"]),
    components: {
        leftNavigationStudent,
        MarkdownPreview,
        VuePdfEmbed,

    },

    async mounted() {
        this.lessoninfoGet()
        this.sectionInfoGet()
        await axios.get(`${process.env.VUE_APP_API}/course_quizzes_detail/${this.course_id}/?Key=${this.userInfo.token_key}`)
            .then(response => {

                for (let i = 0; i < response.data.data.length; i++) {
                    if (response.data.data[i].quiz.pre_quiz == true) {
                        this.pre_quiz = true

                    } else if (response.data.data[i].quiz.pre_quiz == false) {

                        this.post_quiz = true
                    }
                }
            })

    },

    methods: {

        async quizzget(selectedValue) {

            this.quizelecture = selectedValue
            // quizzz
            await axios.get(`${process.env.VUE_APP_API}/course_quizzes_detail/${this.course_id}/?Key=${this.userInfo.token_key}`)
                .then(response => {

                    for (let i = 0; i < response.data.data.length; i++) {
                        if (response.data.data[i].quiz.pre_quiz == true && this.quizelecture == 'pre') {

                            this.quizList = response.data.data[i]

                        } else if (response.data.data[i].quiz.pre_quiz == false && this.quizelecture == 'post') {
                            this.quizList = response.data.data[i]

                        }
                    }
                    this.quizTakerId = this.quizList.quiz.quiztakers_set.id

                    if (this.quizList.quiz.image != null) {

                        this.backgroundImg = this.quizList.quiz.image
                    }

                    this.quizListquestion = this.quizList.quiz.coursequizquestion_set.sort(function (el1, el2) {
                        return el1.order - el2.order
                    })

                })
            if (this.quizList.quiz.image != null) {
                document.querySelector(".sectionOne").style.backgroundImage = `url(${this.backgroundImg})`
                document.querySelector(".sectionOne").style.backgroundSize = "cover"
                document.querySelector(".sectionOne").style.backgroundPosition = "center"
                document.querySelector(".sectionOne").style.backgroundRepeat = "no-repeat"
                document.querySelector(".sectionOne").style.opacity = "0.8"
            }

        },

        async lessoninfoGet() {
            await axios.get(`${process.env.VUE_APP_API}/lessons/?course_name=${this.course_id}`)
                .then(response => {
                    let firstLessonId = response.data.results[0].id
                    this.getLessonURL(firstLessonId)
                    this.lessonInfo = response.data
                });
        },

        async sectionInfoGet() {
            await axios.get(`${process.env.VUE_APP_API}/section/?course_name=${this.course_id}`)
                .then(response => {
                    this.sectionInfo = response.data
                })
        },

        getLessonURL(data) {
            this.quizelecture = '',
                axios.get(`${process.env.VUE_APP_API}/lessons/?id=${data}`)
                .then(response => {
                    this.documentsInfo = response.data.results[0]

                    let videoLink = this.documentsInfo.video_url

                    if (videoLink != null) {
                        this.renderVideoLink = videoLink.indexOf("https://www.youtube") == 0 ?
                            videoLink : null
                    }
                    this.documentPopup = true
                })
        },

        // Quizz

        Retry() {
            this.$router.go(0)

            // this.isSubmit = false
            // this.$refs.quizForm.reset()
            // for(let i=0;i<this.$refs.quizForm.length-1;i++){
            //     console.log(this.$refs.quizForm[i].checked==false)
            // }

        },
        Review() {
            this.isSubmit = false
            document.getElementById("quizsubmit").style.display = "none"

        },
        quizSubmit(el) {

            axios.patch(`${process.env.VUE_APP_API}/course_save_multi_answer/?Key=${this.userInfo.token_key}`, this.answer_set)
                .then(response => {
                    console.log(response, "response..........................")
                })

            let allElement = el.target

            let outputList = []
            console.log(this.quizList.quiz.coursequizquestion_set.length, "length")
            for (let i = 0; i < this.quizList.quiz.coursequizquestion_set.length; i++) {

                let question = this.quizList.quiz.coursequizquestion_set[i].coursequizanswer_set.filter(answer => {
                    return answer.is_correct == true
                })

                outputList.push(question[0].id)
                console.log(outputList)
            }
            console.log(outputList, "outputList")

            for (let i = 0; i < allElement.length - 1; i++) {
                allElement[i].setAttribute("disabled", "disabled")
                if (outputList.includes(parseInt(allElement[i].value))) {
                    allElement[i].classList.add("bg-green-500")
                    allElement[i].labels[0].classList.add("text-green-500")
                }
                this.isSubmit = true

            }
        },
        checkquizz(event, answer) {

            console.log(answer)

            let answers = {
                "quiztaker": this.quizTakerId,
                "answer": answer.id,
                "question": answer.question
            }
            this.answer_set.push(answers)
            let childElement = event.target.parentNode.parentNode.children
            for (let i = 0; i < childElement.length; i++) {
                childElement[i].children[0].setAttribute("disabled", "disabled")
            }
            if (answer.is_correct == true) {
                this.correctAnswercount++

                event.target.labels[0].classList.add("text-green-500")
            } else {

                event.target.labels[0].classList.add("text-red-500")
            }

            console.log(this.answer_set, "answer_set")

        }

    }
}
</script>

<style scoped>

</style>
