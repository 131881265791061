<template lang="">
<div class="flex relative" style="height:calc(100vh - 113px);">
    <NavigationInstructor actived="studentlist" />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <div class="flex flex-col w-full h-full">
        <!-- Breadcrumb -->
        <!-- <div>
            <ol class="flex gap-2 items-center border-b p-2">
                <li v-if="userInfo.user.permission == 'admin' || userInfo.user.permission == 'super_admin'" class="text-gray-500 text-sm">
                    <router-link :to='{name :"organizationCourse",params:{slug_id:this.userInfo.slug_id}}'>
                        Dashboard
                    </router-link> >
                </li>
                <li v-else class="text-gray-500 text-sm">
                    <router-link :to="{name : 'Dashboard'}">
                        Dashboard
                    </router-link> >
                </li>
                <li class="text-gray-500 text-sm">
                    <router-link :to="{name :'InstructorStudents'}">
                        {{course_name}}
                    </router-link> >
                </li>
                <li class="text-gray-500 text-sm">

                    Students list

                </li>
            </ol>
        </div> -->
        <div class=" w-full lg:w-3/6 p-4 h-full">
        <div class="w-full flex justify-center lg:justify-start py-1 px-1 text-gray-900 bg-white border-gray-300 text-xl capitalize">
                <p class="mb-0"> {{course_name}} </p>
            </div>
            <p class="text-sm font-bold ">Student List</p>
            <main v-for="student in this.studentList.data" :keys="student" class="w-full md:w-1/2 lg:w-2/3 2xl:w-1/3">
                <p v-if="student[0].display_name" class="p-1 text-sm lg:text-base mt-1 border ml-2  capitalize rounded py-2">{{student[0].display_name}}
                    <span @click="popUp=true;this.mailid=student[0]" class='select-none float-right px-4 border rounded hover:bg-blue-700 hover:text-white'>
                        Mail
                    </span>
                </p>
            </main>
        </div>

        <div v-if="popUp" class='fixed z-50 bottom-0 right-0 w-full md:w-5/6 lg:w-3/6 bg-white flex items-center justify-center border' style="height:350px">
            <main class="flex flex-col w-full h-full">
                <form ref="mailref" @submit.prevent="sendmail" class="h-full flex flex-col overflow-y-auto">
                    <section class='bg-blue-800 text-white flex items-center h-10 justify-between pr-4'>
                        <p class='w-5/6 px-2 py-1 mb-0 text-base'>Mail to</p>
                            <div @click="this.popUp = false" class="border cursor-pointer flex rounded-lg">
                                <img class='h-6 w-6 ' src="../../assets/closebutton.svg" />
                            </div>
                    </section>
                    <section v-if="this.minimize" class=''>
                        <div class='mt-1 border-gray-200 px-2 py-2'>
                            <label for='to' class='w-1/6'></label>
                            <input input='email' required class='border-b w-full pl-1 text-sm' :value='this.mailid.display_name' placeholder='To' disabled />
                        </div>
                        <div class='mt-1 border-gray-200 px-2'>
                            <label for='subject' class=' w-1/6'></label>
                            <input input='text' id='subject' required class='border-b w-full pl-1 py-1 text-sm' placeholder='Subject' />
                        </div>
                        <div class='mt-2 px-2'>
                            <label for='content' class='w-1/6'></label>
                            <textarea rows="6" cols="6" id='content' class="text-sm border border-gray-300 h-full w-full pl-1 rounded" placeholder='Content'></textarea>
                        </div>
                        <div class='py-2 px-2'>
                            <button type='submit' class='gradient-btn'>Send</button>
                            <!-- <button class='px-4 py-1 ml-1 border bg-blue-500 text-white rounded hover:bg-white hover:text-gray-900' @click="sendmailallStudnt()">Send</button> -->

                            <!-- <label for="upload-photo" class=' ml-1 px-1 border'>Attach</label>
                    <input type="file" name="photo" id="upload-photo" style="opacity: 0;position: absolute; z-index: -1;" /> -->
                        </div>
                    </section>
                </form>
            </main>
        </div>

        <!-- <div class="absolute right-0 top-32 w-2/6 flex justify-center"><button @click="this.popUp= ! this.popUp" class='px-2 py-1 rounded border border-red-300 bg-white hover:bg-yellow-500 hover:text-white'>Mail</button></div> -->
    </div>
</div>
</template>

<script>
import NavigationInstructor from "@/views/instructordashboard/leftnavinstructor.vue";
import axios from "axios";
import FlashMessage from "@/components/flashMessage.vue";
import {
    mapGetters
} from "vuex";

export default {
    data() {
        return {

            popUp: false,
            minimize: true,
            maximize: false,
            studentList: "",
            mailid: null,
            messages: {
                text: "",
                type: 'null',
            },
        }
    },

    components: {
        NavigationInstructor,
        FlashMessage
    },

    created() {
        this.getstudentdetails()
    },

    computed: {
        ...mapGetters(["userInfo"]),
    },

    props: ["course_id", "course_name"],

    methods: {

        async getstudentdetails() {
            await axios.get(`${process.env.VUE_APP_API}/all_list/${this.course_id}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    this.studentList = response.data
                })
        },

        async sendmail(event) {
            const HeaderVal = {
                "subject": event.target.elements.subject.value,
                "time": "",
                "content_1": event.target.elements.content.value,
                "content_2": "",
                "content_3": ""
            }
            await axios.post(`${process.env.VUE_APP_API}/instructor_send_personal/${this.mailid.id}/?Key=${this.userInfo.token_key}`, HeaderVal)
                .then(response => {
                    if (response.data.message == 'successfully Email send') {
                        this.messages = {
                            text: "Mail Sent successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })
            this.$refs.mailref.reset()
            this.popUp = false
            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 1500)
        },

        // send Mail All student in a Course
        async sendmailallStudnt() {
            await axios.post(`${process.env.VUE_APP_API}/instructor_send_student/${this.course_id}/?Key=${this.userInfo.token_key}`)
                .then(Response => {
                    if (Response.message == 'successfully Email send') {
                        this.messages = {
                            text: "Mail Sent successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })
            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 1500)

        },
    },
}
</script>

<style lang="">
    
</style>
