<template>
<div class="min-h-screen relative">
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <div class='w-11/12 2xl:w-4/6 mx-auto relative bg-gray-50 m-4 p-8 rounded-md border shadow-lg'>
        <!-- <router-link :to="{name :'InstructorHome'}" class="absolute right-8 top-8">
            <div class='mb-1 cursor-pointer rounded-lg flex border justify-center w-24 gap-2 items-center py-1 bg-white'>
                <div class=" text-sm  text-gray-700 hover:text-teal-500 ">Back </div>
                <div class="flex items-center">
                <img src="https://img.icons8.com/external-simple-solid-edt.graphics/15/undefined/external-Back-arrows-simple-solid-edt.graphics-2.png"/>
                </div>
            </div>
        </router-link> -->

        <!-- <router-link v-else :to="{name :'organizationCourse',params:{slug_id:userInfo.slug_id}}" class="absolute right-8 top-8">
        <div class='mb-1 cursor-pointer rounded-lg flex'>
            <div class="px-1 md:px-2 py-1 text-sm md:text-base text-gray-700 hover:text-red-500">Close </div>
            <img class='h-5 w-5 md:h-8 md:w-8' src="../../assets/closebutton.svg" />
        </div>
    </router-link> -->
        <form ref="addCourseRef" @submit.prevent="createCourse" class="w-full lg:p-4 mx-auto lg:w-10/12">
            <h1 class="text-left  py-2 text-3xl font-semibold text-gray-600">
                Add Course
            </h1>
            <div class="parent">
                <div class="">
                    <label for="title">
                        Title<span class="text-red-400">*</span> <span class="text-xs text-gray-400 px-2">(max:350char)</span>
                    </label>
                    <input id="title" type="text" required class="border border-gray-300 rounded-md text-sm w-full p-2">
                </div>
                <!-- <div class="">
                    <label for="category" class="flex gap-2 items-center">
                        <div>
                            Category<span class="text-red-400">*</span>
                        </div>
                        <font-awesome-icon @click="is_CategoryPopup=true" class="text-base text-green-500 self-end h-6 w-6 cursor-pointer hover:text-green-800" :icon="['fas','plus']" title="Add Category" />
                    </label>
                    <select name="category" required class="p-2 block w-full rounded-md text-sm" id="category">
                        <option v-for="category in categories" :key="category" :value="category.id">
                            {{category.title}}
                        </option>
                    </select>
                </div> -->
                <div class="flex justify-start items-center gap-2 px-2">
                    <label for="freeCourse">
                        Free Course
                    </label>
                    <input id="freeCourse" type="checkbox" class="">
                </div>
                <div class="col-start-1 col-end-4">
                    <label for="short-desc">
                        Short Description
                    </label>

                    <textarea id="short-desc" class="border border-gray-300 rounded-md text-sm w-full p-2"></textarea>
                </div>
                <div class="col-start-1 col-end-4">
                    <label for="description">
                        Description<span class="text-red-400">*</span>
                    </label>
                    <MarkdownEditor class="rounded-md" />
                </div>
                <div class="col-start-1 col-end-4">
                    <label for="outcome">
                        Outcome
                    </label>
                    <textarea id="outcome" maxlength="200" class="border border-gray-300 rounded-md text-sm w-full p-2"></textarea>
                </div>
                <div class="col-start-1 col-end-4">
                    <label for="requirement">
                        Requirement
                    </label>
                    <textarea id="requirement" maxlength="200" class="border border-gray-300 rounded-md text-sm w-full p-2"></textarea>

                </div>
                <div class="">
                    <label for="language">
                        Language
                    </label>
                    <input id="language" type="text" class="border border-gray-300 rounded-md text-sm w-full p-2">
                </div>
                <div class="">
                    <label for="price">
                        Price
                    </label>
                    <input id="price" type="text" class="border border-gray-300 rounded-md text-sm w-full p-2">
                </div>
                <!-- <div class="">
                    <label for="level">
                        Level<span class="text-red-400">*</span>
                    </label>
                    <select name="level" required class="rounded-md p-2 block w-full text-sm" id="level">
                        <option v-for="level in ['beginner', 'intermediate', 'advanced']" :key="level" :value="level">
                            {{level}}
                        </option>
                    </select>
                </div> -->
                <div class="">
                    <label for="thumbnail">
                        Thumbnail
                    </label>
                    <input id="thumbnail" type="file" class="border border-gray-300 rounded-md text-sm w-full p-2">
                </div>
                <div class="">
                    <label for="video_url">
                        Video file
                    </label>
                    <input id="video_url" type="file" class="border border-gray-300 rounded-md text-sm w-full p-2" accept="video/*">
                </div>
                <div class="flex items-center gap-2">
                    <label for='checkbox'>Published</label>
                    <input type="checkbox" id="Published">
                </div>

            </div>
            <button v-if="is_ld" class="gradient-btn mt-4">
                Loading ...
            </button>
            <button v-else type="submit" class="gradient-btn mt-4">
                Add course
            </button>
            <div v-if="is_ld" class="py-4 my-4">
                <div class="h-2 w-full border bg-white border-purple-500 rounded-xl my-2">
                    <div class="h-full bg-gradient-to-r from-purple-500 to-pink-500" :style="{width:is_uploadingbar+`%`}">
                    </div>
                </div>
                <div class="text-sm lg:text-lg">
                    {{ this.is_uploadingbar}} <span class="text-sm"> %</span> <span v-if="this.is_uploadingbar<100" class="text-sm">Uploading</span> <span v-else>uploaded</span>
                    <span class="px-4 text-sm"> ( {{ this.lsz }} MB / {{ this.fsz }} MB )</span>
                </div>
            </div>
        </form>
    </div>

    <!-- Category Add in Course  -->
    <div v-if="is_CategoryPopup" class="fixed top-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center" style="z-index:5001">
        <div class="w-5/6 lg:w-2/3 h-1/3 2xl:w-1/3 2xl:h-1/5 bg-white card1 flex flex-col justify-center">
            <div @click="is_CategoryPopup=false" class="absolute right-8 top-8 cursor-pointer z-50">
                <div class='mb-1  rounded-lg flex border cursor-pointer'>
                    <div class="px-1 md:px-2 py-1 text-sm md:text-base text-gray-700 hover:text-red-500">Close </div>
                    <img class='h-5 w-5 md:h-8 md:w-8' src="../../assets/closebutton.svg" />
                </div>
            </div>
            <h4 class="text-gray-700 text-center">Add Category</h4>
            <form class="w-11/12 mx-auto" @submit.prevent="categoryAdd">
                <label for="title">
                    Title<span class="text-green-400">*</span>
                </label>
                <input id="title" type="text" required class="border border-gray-300 rounded-md text-sm w-full p-2">
                <button type="submit" class="gradient-btn mt-4">
                    Add course
                </button>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import CustomSelect from "@/components/buttons/CustomSelect.vue"
// import MarkdownEditor from "@/components/MarkdownEditor.vue"
import MarkdownEditor from "@/components/MarkdownEditor2.vue"
import {
    mapGetters
} from "vuex"
import FlashMessage from "@/components/flashMessage.vue";
export default {
    computed: mapGetters(["userInfo"]),
    components: {
        CustomSelect,
        MarkdownEditor,
        FlashMessage
    },
    data() {
        return {
            categories: null,
            info: null,
            msgverify: '',
            messages: {
                text: "",
                type: 'null',
            },
            is_CategoryPopup: false,
            is_ld: false,
            is_uploadingbar: 0,
            fsz: 0,
            lsz: 0

        }

    },

    methods: {
        // add the new Category in Course
        categoryAdd(el) {
            let bodyVal = {
                "title": el.target.elements.title.value,
            }

            axios.post(`${process.env.VUE_APP_API}/categorynew/?Key=${this.userInfo.token_key}`, bodyVal)
                .then(response => {
                    console.log(response.data.message)
                    if (response.data.message == "your Category added successfully") {
                        this.messages = {
                            text: "Category Added successfully",
                            type: 'success',
                        }
                        setTimeout(() => {
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                            this.is_CategoryPopup = false
                            this.categoryget()
                        }, 1500);
                    } else {
                        this.messages = {
                            text: response.data.message,
                            type: 'error',
                        }
                        setTimeout(() => {
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                        }, 2000)
                    }
                })
        },
        async createCourse(e) {
            this.is_ld = true

            let token = this.userInfo.token_key

            let urls = `${process.env.VUE_APP_API}/coursenew/?Key=${token}`;
            let HeaderVal = new FormData();
            if (e.target.elements.thumbnail.files.length > 0) {
                var thumbnailVal = e.target.elements.thumbnail.files[0]
                HeaderVal.append("thumbnail", thumbnailVal)
            }
            if (e.target.elements.video_url.files.length > 0) {
                var Video_file = e.target.elements.video_url.files[0]
                HeaderVal.append("video_url", Video_file)
            }
            HeaderVal.append("title", e.target.elements.title.value)
            // HeaderVal.append("category", parseInt(e.target.elements.category.value))
            HeaderVal.append("short_description", e.target.elements["short-desc"].value)
            HeaderVal.append("description", e.target.elements[3].value)
            HeaderVal.append("outcome", e.target.elements.outcome.value)
            HeaderVal.append("requirements", e.target.elements.requirement.value)
            HeaderVal.append("language", e.target.elements.language.value)
            HeaderVal.append("price", e.target.elements.price.value)
            HeaderVal.append("course_free", e.target.elements.freeCourse.checked)
            // HeaderVal.append("level", e.target.elements.level.value)
            HeaderVal.append("is_published", e.target.elements.Published.checked)
            HeaderVal.append("instructor", this.userInfo.Instructor_id);
            HeaderVal.append("organization_id", this.userInfo.organizes_id)
            var config = {
                onUploadProgress: (progressEvent) => {
                    this.fsz = Math.round(progressEvent.total / 1000000)
                    this.lsz = Math.round((progressEvent.loaded * 100) / 100000000)
                    this.is_uploadingbar = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                }
            };
            console.log(config)
            await axios.post(urls, HeaderVal, config, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }).then((response) => {
                    console.log(response);
                    if (response.data.message == "your Course added successfully") {
                        this.messages = {
                            text: "Courses Added successfully",
                            type: 'success',
                        }
                        setTimeout(() => {
                            this.is_ld = false
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                            this.$refs.addCourseRef.reset();
                            if (this.userInfo.user.is_superuser) {
                                this.$router.push({
                                    name: 'organizationCourse',
                                    params : {slug_id:this.userInfo.slug_id}
                                });
                            } else {
                                this.$router.push({
                                    name: 'InstructorCourse'
                                });
                            }
                        }, 3500)
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                        setTimeout(() => {
                            this.is_ld = false
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                        }, 3500)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.messages = {
                        text: error,
                        type: 'error',
                    }
                })

            setTimeout(() => {
                this.is_ld = false
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 3500)

        },
        categoryget() {
            axios.get(`${process.env.VUE_APP_API}/category/?format=json`).then(res => {
                this.categories = res.data.data
            })
        }
    },
    created() {
        this.categoryget()

    }

}
</script>

<style scoped>
select {
    line-height: 1.5em;
}

.parent {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
}
</style>
