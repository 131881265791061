<template>
 <div class="page-wrapper">
     <Navigation/>
    <div class="section courses wf-section">
      <div class="container-default-1209px w-container">
        <div class="courses-hero-wrapper">
          <div >
            <h1 class="special-2 courses">Courses</h1>
            <p class="paragraph courses pt-2">Take your time to explore our course catalog and find the courses that best fit your interests and goals. Our team of instructors is dedicated to providing high-quality content and support to help you succeed in your learning journey.</p>
            <a href="#Courses" class="button-primary large w-button">View courses</a>
          </div>
          <img src="../../catalyft-ui-templates/images/courses-hero-webflow-template.jpg" class="shadow-lg" style="min-width:460px;width:100px;border-radius:15px;" >
        </div>
      </div>
    </div>
    <div class="container-default-1209px w-container">
      <div data-w-id="4dfb3ae6-c85f-5256-cad4-afe060384278" style="-webkit-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="divider"></div>
    </div>
    <div id="Courses" data-w-id="4dfb3ae6-c85f-5256-cad4-afe060384279" style="-webkit-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="section latest-courses wf-section">
      <div class="container-default-1209px w-container">
        <div class="latest-courses-wrapper">
          <h2 data-w-id="4dfb3ae6-c85f-5256-cad4-afe06038427c" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="title latest-courses">Latest Courses</h2>
          <div class="w-dyn-list">
            <div v-if="this.courselist" role="list" class="courses-grid w-dyn-items">
              <div v-for="course in this.courselist" :key="course" data-w-id="96a61bf5-bd59-e173-033b-ad374d548848" style="height:528px" role="listitem" class="course-card-wrapper w-dyn-item ">
                <div class="card w-80 md:w-96" style="height:528px">
                  <router-link :to="{name: 'CouseDetail',params: {slug: course.slug,title: course.title}}" class="card w-80 md:w-96" style="height:528px">
                  <a href="#" class="card-image-link w-inline-block"><img :src="course.thumbnail" alt="" style="overflow: hidden;width:396px;height:208px" class="image course w-80 md:w-96"></a>
                  <div class="card-content">
                    <a href="#" class="card-title-link w-inline-block">
                      <h3 class="text-lg 2xl:text-xl course">{{course.title.slice(0,80)}} <span v-if="course.title.length>80">....</span></h3>
                    </a>
                    <p class="text-base primary-text-color">{{course.short_description.slice(0,80)}} <span v-if="course.short_description.length>80">....</span></p>
                    <div class="divider course-card"></div>
                    <div class="course-card-details-wrapper">
                      <div class="level-wrapper">
                      <img v-if="course.level=='beginner'" src="../../catalyft-ui-templates/images/icon-level-01-academy-template.svg" alt="Beginners - Academy Webflow Template" class="level-icon">
                      <img v-else-if="course.level=='Intermediate'" src="../../catalyft-ui-templates/images/icon-level-02-academy-template.svg" alt="Beginners - Academy Webflow Template" class="level-icon">
                      <img v-else src="../../catalyft-ui-templates/images/icon-level-03-academy-template.svg" alt="Beginners - Academy Webflow Template" class="level-icon">
                        <div class="w-dyn-list">
                          <div role="list" class="levels-list w-dyn-items">
                            <div role="listitem" class="level-text-wrapper w-dyn-item">
                              <a href="#" class="level-text">{{course.level}}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="course.course_free != true" class="course-card-price">&#8377;{{course.price}}</div>
                      <div v-else class="font-semibold text-red-600 tracking-wide">&#8377; Free</div>
                    </div>
                  </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div v-else class="card empty-state w-dyn-empty">
              <div class="empty-state-text">There are no courses available yet.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-default-1209px w-container">
      <div class="divider"></div>
    </div>
    <Footer/>
    
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
    data(){
      return{
        courselist :""
      }
    },
    components: {
        Navigation,
        Footer
    },
    
    created (){
      axios.get(`${process.env.VUE_APP_API}/courses/?is_published=true&format=json`)
      .then(response => {
        this.courselist=response.data.data
        })
    }
};
</script>

