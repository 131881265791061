<template>
<div>
    <Navigation />
<div class="flex h-screen">
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <internLaftNav actived='courses' />
    <confirmPopup v-if='popupwindow==true' :title="titlepass" @cancelClick="popupwindow=false" @confirmClick="deletecourse(idpass)" />
    <div class="h-full w-full flex flex-col overflow-y-auto">
        <div class="border p-2 flex gap-2 bg-gray-200">
            <button class="border border-gray-300 px-2 text-base rounded" :class="is_selected=='course' ? 'bg-white border border-teal-500' : 'bg-gray-100'" @click="is_selected='course'">Course list</button>
            <button class="border border-gray-300 px-2 text-base rounded" :class="is_selected=='Roles' ? 'bg-white border border-teal-500' : 'bg-gray-100'" @click="is_selected='Roles'">Roles Change</button>
        </div>
    <div v-if="is_selected=='course'" class="w-full h-full py-2 overflow-y-auto relative z-10">
        <div v-if="to_lessonEdit==false" class="w-full h-full">
            <div class=" px-4 text-xl ">All Courses</div>
            <div v-if="orgCourseDetails != null" class="flex flex-wrap gap-2">
                <div v-for="course in orgCourseDetails" :key="course" class="shadow-lg rounded-lg flex justify-center items-center border border-gray-300 mx-auto" style="height:16rem;width: 370px;">
                    <div class="flex flex-col gap-3">
                        <div v-if="course.thumbnail" class="cursor-pointer" @click="rediecttoInfo(course)">
                            <img :src="course.thumbnail" alt="" class="rounded-lg  object-cover w-80 h-36">
                        </div>
                        <div class="w-80 truncate">
                            <div class="text-lg truncate cursor-pointer" @click="rediecttoInfo(course)">{{course.title}}</div>
                            <!-- <p class="text-gray-700 text-sm"> Instructor : {{course.instructor}}</p>
                            <p class="text-gray-700 text-sm"> Created date : {{course.created_at}}</p>
                            <p class="text-gray-700 text-sm"> Last Updated date : {{course.updated_at}}</p> -->
                        </div>
                        <div class="flex gap-1 items-center justify-between ">
                            
                            <router-link :to="{name:'UpdateLesson',params:{courseId:course.id}}" class="border px-2 rounded flex gap-1 border-green-400 text-sm items-center py-1 hover:bg-green-100 ">
                             <img src="../../assets/Addbutton.svg" alt="" class="h-6 w-6 cursor-pointer" title="Add Lesson">
                                Add section 
                                </router-link>
                            
                            
                            <router-link :to="{name:'EditCourse',params:{course_id:course.id}}" class="border border-gray-200 px-2 rounded flex gap-1 text-sm items-center py-1 hover:bg-gray-100 ">
                            <img src="../../assets/edit.svg" alt="" class="h-6 w-6 cursor-pointer" title="Edit Course">
                                Edit Course
                                </router-link>
                            
                            <button class="border border-red-400 px-2 rounded flex gap-1 text-sm items-center py-1 hover:bg-red-100 " @click="titlepass=course.title;popupwindow=true;idpass=course.id">
                                <!-- <img src="../../assets/trash.svg" alt="" class="h-6 w-6 cursor-pointer" title="Delete Course" >  -->
                                <font-awesome-icon class="text-base text-red-400 self-center h-6 w-6" :icon="['fas','trash-alt']" />
                                Delete 
                                </button>
                            <!-- <img src="../../assets/Addbutton.svg" alt="" class="h-6 w-6 cursor-pointer" title="Add Lesson" @click="lessonEdit(course.id)">
                            <img src="../../assets/edit.svg" alt="" class="h-6 w-6 cursor-pointer" title="Edit Course" @click="getdetails(course)">
                            <img src="../../assets/trash.svg" alt="" class="h-6 w-6 cursor-pointer" title="Delete Course" @click="titlepass=course.title;popupwindow=true;idpass=course.id"> -->
                        </div>
                    </div>
                </div>
                <div class="flex justify-center items-center w-96" style="height:20rem">
                            <router-link :to="{name : 'AddCourse'}" class=" flex justify-center items-center">
                                <img src="../../assets/Addbutton.svg" alt="" class="h-16 w-16" title="Add Course">
                                <div class="">Add Course</div>
                            </router-link>
                        </div>
            </div>
        </div>
    </div>

    

    <!-- Instructor Replace -->
    <div v-if="eventDetails.popUp==false && this.is_selected=='Roles'" class='w-full h-1/2 lg:w-3/6 2xl:h-1/2 2xl:w-1/3 p-4'>
        <div class="p-6 h-auto border rounded shadow-lg flex flex-col gap-4">
            <div class="bg-gray-100 flex gap-4 p-3 border rounded shadow-md ">

                <button @click="instructor_event='Replace'" class="rounded p-2" :class="[ instructor_event=='Replace' ? 'bg-white border border-teal-500' : 'bg-gray-50']">Replace</button>

                <button @click="instructor_event='Remove'" class="rounded p-2" :class="[ instructor_event=='Remove' ? 'bg-white border border-teal-500' : 'bg-gray-50']">Remove</button>

            </div>
            <div v-if="instructor_event=='Replace'" class="h-full">
                <form @submit.prevent="removeUserPopUp" class="flex flex-col gap-3">
                    <div class="flex flex-col gap-2">
                        <label for='Course_id'>
                            Course Name
                        </label>
                        <select name="Course_id" class="rounded-md border border-gray-300 bg-gray-100 p-2 block w-full" id="Course_id">
                            <option v-for="course in orgCourseDetails" :key="course" :value="course.id">
                                {{course.title}}
                            </option>
                        </select>
                    </div>
                    <div class="flex flex-col gap-2">
                        <label for="instructor_email_replace">New Instructor Mail</label>
                        <select class="border border-gray-300 rounded" id="instructor_email_replace">
                            <option v-for="instructor in adminInfo.data" :key="instructor" :value="instructor.id">
                                {{instructor.user_name}} ({{instructor.user_email}})
                            </option>
                        </select>
                    </div>

                    <button type="submit" class="gradient-btn mt-4 w-60">
                        Update instructor
                    </button>
                </form>
            </div>

            <div v-else class="h-full">
                <form @submit.prevent="removeUserPopUp" class="flex flex-col gap-3">
                    <ul class="flex gap-8 justify-start  w-full">
                        <li class="flex items-center h-10">
                            <input type="radio" name="role" v-model="userDetail" value="student" id="student" class="mr-2">
                            <label for="paidUser" class="font-normal text-gray-800">Student</label>
                        </li>
                        <li class="flex items-center h-10">
                            <input type="radio" name="role" v-model="userDetail" value="instructor" id="instructor" class="mr-2">
                            <label for="unpaiduser" class="font-normal text-gray-800">Instructor</label>
                        </li>
                    </ul>
                    <!-- Instructor list -->
                    <div v-if="userDetail=='instructor'" class="flex flex-col gap-2">
                        <label for="instructor_email">Instructor Mail</label>
                        <select class="border border-gray-300 rounded" id="instructor_email">
                            <option v-for="instructor in adminInfo.data" :key="instructor" :value="instructor.Instructor_id">
                                {{instructor.user_name}} ({{instructor.user_email}})
                            </option>
                        </select>
                    </div>

                    <!-- Student list -->
                    <div v-else class="flex flex-col gap-2">
                        <label for="student_email">Student Mail</label>
                        <select class="border border-gray-300 rounded" id="student_email">
                            <option v-for="student in studentInfo" :key="student" :value="student.id">
                                {{student.students_name}} ({{student.students_email}})
                            </option>
                        </select>
                    </div>
                    <button type="submit" class="gradient-btn mt-4 w-60">
                        Remove
                    </button>
                </form>
            </div>
        </div>
    </div>

    <!-- Confirmation popUP -->
    <div v-else-if="eventDetails.popUp==true && this.is_selected=='Roles'" class="w-full h-full bg-black flex items-center">
       <div class="items-center flex w-full h-full">
        <div class="w-auto h-auto mx-auto bg-white p-8 flex flex-col rounded gap-4">
            <div class="h-2/3 my-auto flex flex-col gap-2">
                <div class="text-center text-xl lg:text-2xl">
                    Are you want to {{eventDetails.eventName}} </div>
                <div class="px-4 flex flex-col gap-2">
                    <p class="text-center">
                        Name : {{eventDetails.eventValues.name}}
                    </p>
                    <p class="text-center">
                        Email : {{eventDetails.eventValues.email}}
                    </p>
                </div>
            </div>
            <div class="flex gap-4 justify-around">
                <button class=" border px-6 py-1 rounded hover:bg-teal-400 hover:text-white" @click="eventActivate">Yes</button>
                <button class=" border px-6 py-1 rounded hover:bg-red-400 hover:text-white" @click="eventDetails.popUp=false">No</button>
            </div>
        </div>
    </div>
    </div> 
    </div>
</div>
</div>
</template>

<script>
import axios from "axios"
import {
    mapGetters
} from "vuex";
import Navigation from "@/components/Navigation.vue";
import internLaftNav from "@/views/organization/leftNavIntern.vue";
// import MarkdownEditor from "@/components/MarkdownEditor.vue";
import MarkdownEditor from "@/components/MarkdownEditor2.vue";
import confirmPopup from "@/components/confirmPopup.vue";
import FlashMessage from "@/components/flashMessage.vue";
export default {
    data() {
        return {
            info: null,
            orgCourseDetails: null,
            courseUpdate: false,
            popInfo: "",
            categories: null,
            popupwindow: false,
            titlepass: "",
            to_lessonEdit: false,
            course_id: null,
            adminInfo: null,
            studentInfo: null,
            instructor_event: "Replace",
            userDetail: "student",
            messages: {
                text: "",
                type: 'null',
            },
            eventDetails: {
                popUp: false,
                eventName: "",
                courseId: null,
                eventValues: {
                    "id": "",
                    "name": "",
                    "email": ""
                },
            },
            is_selected: 'course',

        }
    },
    props: ["text"],
    computed: mapGetters(["userInfo"]),
    components: {
        Navigation,
        internLaftNav,
        MarkdownEditor,
        confirmPopup,
        FlashMessage,
    },
    async created() {

        this.getallDetails();

        // instructor detils 
        await axios.get(`${process.env.VUE_APP_API}/instructordetaill/?Key=${this.userInfo.token_key}`)
            .then(response => this.adminInfo = response.data)

        await axios.get(`${process.env.VUE_APP_API}/studentsdetaill/?Key=${this.userInfo.token_key}`)
            .then(response => {
                this.studentInfo = response.data.data.filter(element => {
                    return element.students_Status == true
                });
            })

    },
    methods: {

        removeUserPopUp(el) {
            if (this.instructor_event == 'Remove') {

                if (this.userDetail == "student") {

                    this.eventDetails.popUp = true;
                    this.eventDetails.eventName = "Remove Student";
                    this.eventDetails.eventValues["id"] = this.studentInfo[el.target.student_email.selectedIndex].id
                    this.eventDetails.eventValues["name"] = this.studentInfo[el.target.student_email.selectedIndex].students_name,
                        this.eventDetails.eventValues["email"] = this.studentInfo[el.target.student_email.selectedIndex].students_email

                    // console.log(this.eventDetails)

                } else {

                    this.eventDetails.popUp = true;
                    this.eventDetails.eventName = "Remove instructor";
                    this.eventDetails.eventValues["id"] = this.adminInfo.data[el.target.instructor_email.selectedIndex].Instructor_id,
                        this.eventDetails.eventValues["name"] = this.adminInfo.data[el.target.instructor_email.selectedIndex].user_name,
                        this.eventDetails.eventValues["email"] = this.adminInfo.data[el.target.instructor_email.selectedIndex].user_email

                    // console.log(this.eventDetails)
                }

            } else {
                console.log("replace events")

                this.eventDetails.popUp = true;
                this.eventDetails.eventName = "Replace instructor";
                this.eventDetails.courseId = this.orgCourseDetails[el.target.Course_id.selectedIndex].id;
                this.eventDetails.eventValues["id"] = this.adminInfo.data[el.target.instructor_email_replace.selectedIndex].Instructor_id,
                    this.eventDetails.eventValues["name"] = this.adminInfo.data[el.target.instructor_email_replace.selectedIndex].user_name,
                    this.eventDetails.eventValues["email"] = this.adminInfo.data[el.target.instructor_email_replace.selectedIndex].user_email

                // console.log(this.eventDetails)
            }

        },

        async eventActivate() {

            // Replace or Remove Event Checking 
            if (this.instructor_event == 'Remove') {

                // remove event is_student or Is_instructor checking 
                if (this.userDetail == "student") {
                    // Student Remove Event
                    // console.log('student')

                    let headerVal = {
                        "student": parseInt(this.eventDetails.eventValues.id),
                    }
                    this.eventDetails.popUp = false;
                    axios.post(`${process.env.VUE_APP_API}/remove_student/?Key=${this.userInfo.token_key}`, headerVal)
                        .then(response => {
                            console.log(response)

                            if (response.data.message=='Remove student detail successfully') {

                                this.messages = {
                                    text: "student Removed successfully",
                                    type: 'success',
                                }
                                console.log("successful")
                            } else {
                                this.messages = {
                                    text: " Event Faild",
                                    type: 'error',
                                }
                                console.log("not successful")
                            }

                            setTimeout(() => {
                                
                                this.messages = {
                                    text: "",
                                    type: 'null',
                                }
                            }, 2500)

                        })
                        .catch(error => {
                            console.log(error)
                        })

                } else {
                    //Instructor Remove Event
                    // console.log('Instructor')

                    let headerVal = {
                        "instructor": parseInt(this.eventDetails.eventValues.id)
                    }
                    this.eventDetails.popUp = false;
                    axios.post(`${process.env.VUE_APP_API}/remove_instructor/?Key=${this.userInfo.token_key}`, headerVal)
                        .then(response => {
                            console.log(response)

                            if (response.data.message) {

                                this.messages = {
                                    text: "instructor removed successfully",
                                    type: 'success',
                                }
                                console.log("successful")
                            } else {
                                this.messages = {
                                    text: " Event Faild",
                                    type: 'error',
                                }
                                console.log("not successful")
                            }

                            setTimeout(() => {
                                this.messages = {
                                    text: "",
                                    type: 'null',
                                }
                                
                            }, 2500)

                        })
                        .catch(error => {
                            console.log(error)
                        })
                }

            } else {
                // instructor replace event
                // console.log('replace events')

                var oldInstructor
                // finding the old instructor id
                await axios.get(`${process.env.VUE_APP_API}/coursesdata/${this.eventDetails.courseId}/?Key=${this.userInfo.token_key}`)
                    .then(response => {
                        console.log(response.data.user_id)
                        oldInstructor = response.data.user_id;
                    })

                let headerVal = {
                    "old_instructor": oldInstructor,
                    "new_instructor": parseInt(this.eventDetails.eventValues.id)
                }
                this.eventDetails.popUp = false;

                axios.post(`${process.env.VUE_APP_API}/course_permission_change/${this.eventDetails.courseId}/?Key=${this.userInfo.token_key}`, headerVal)
                    .then(response => {
                        console.log(response, "replace events")

                        if (response.data.data == "success") {

                            this.messages = {
                                text: "Instructor Updated successfully",
                                type: 'success',
                            }
                            console.log("successful")
                        } else {
                            this.messages = {
                                text: " Event Faild",
                                type: 'error',
                            }
                            console.log("not successful")
                        }

                        setTimeout(() => {
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                        }, 2500)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        },

        async getallDetails() {

            await axios.get(`${process.env.VUE_APP_API}/organization_detail/?Key=${this.userInfo.token_key}`)
                .then(Response => {
                    this.info = Response.data.data
                })

            if (this.info != null) {
                await axios.get(`${process.env.VUE_APP_API}/courses/?organization_id=${this.info.id}`)
                    .then(Response => {
                        this.orgCourseDetails = Response.data.data
                    })
            }
            axios.get(`${process.env.VUE_APP_API}/category/?format=json`).then(res => {
                this.categories = res.data.data
            })
        },
        getdetails(data) {
            this.popInfo = data
            this.courseUpdate = true;
            console.log(data, )
        },
        rediecttoInfo(course) {
            this.$router.push({
                name: 'InstructorStudents',
                params: {
                    course_id: course.id,
                    instructor: course.instructor,
                    course_name: course.title
                }
            })
        },
        async deletecourse(data) {
            await axios.delete(`${process.env.VUE_APP_API}/courseupdate/${data}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    this.res = response
                    if (this.res.statusText == 'No Content') {
                        this.messages = {
                            text: "Deleted successfully",
                            type: 'success',
                        }
                        console.log("successfull")
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })
            this.popupwindow = false

            setTimeout(() => {
                this.$router.go(0);
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 2000)
        },
    }

}
</script>

<style scoped>
p {
    margin-bottom: 0px;
}
    
</style>
