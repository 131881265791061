<template>
  <div class="min-h-screen relative">
    <Navigation />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <div class="flex w-full 2xl:max-w-7xl mx-auto">
      <LeftNavigationView actived="profile" />
      <div class="w-full">
        <div class="min-h-screen w-full container-default-1209px w-container">
          <div class="w-full my-4 h-auto">
            <section class="w-full h-full flex items-center mx-auto">
              <div
                class="py-1 bg-gray-100 border border-gray-300 bg-opacity-70 flex items-center w-full gap-4 md:px-4 rounded-lg relative h-auto"
              >
                <button
                  class="px-4 rounded-lg py-1 hover:bg-white text-base"
                  :class="{
                    'bg-white border primary-text-color':
                      this.selectedcontent == 'journey',
                  }"
                  @click="this.selectedcontent = 'journey'"
                >
                  My Journey
                </button>
                <button
                  class="px-4 rounded-lg py-1 hover:bg-white text-base"
                  :class="{
                    'bg-white border primary-text-color':
                      this.selectedcontent == 'Courses',
                  }"
                  @click="this.selectedcontent = 'Courses'"
                >
                  My Courses
                </button>
                <button
                  class="px-4 rounded-lg py-1 hover:bg-white text-base"
                  :class="{
                    'bg-white border primary-text-color':
                      this.selectedcontent == 'resource',
                  }"
                  @click="this.resourceCall"
                >
                  My Resources
                </button>
                <button
                  class="px-4 rounded-lg py-1 hover:bg-white text-base"
                  :class="{
                    'bg-white border primary-text-color':
                      this.selectedcontent == 'profile',
                  }"
                  @click="this.selectedcontent = 'profile'"
                >
                  My Profile
                </button>
              </div>
            </section>
          </div>

          <!-- User Journey -->
          <!-- Course Details -->
          <div
            v-if="this.selectedcontent == 'journey'"
            class="w-full h-full overflow-y-auto p-2 flex flex-col gap-4"
          >
            <div class="w-full h-full shadow-lg">
              <!-- SLM -->

              <div
                v-for="el in this.courseDetails"
                :key="el"
                class="accordion-item border-t-0 border-x-0 border border-primary text-left"
              >
                <div
                  class="accordion-header mb-0 bg-white shadow-lg border border-gray-300"
                  @click="this.modulesAccordian"
                >
                  <button
                    class="accordion-button relative flex items-center justify-between w-full py-4 px-5 font-semibold text-sm md:text-base text-gray-800 text-left border-0 rounded-none transition focus:outline-none"
                    type="button"
                  >
                    {{ el.title }}
                    <img
                      src="../assets/down-arrow.svg"
                      alt="down-arrow"
                      class="down-arrow h-6 rotate_img"
                    />
                  </button>
                </div>
                <div class="flex items-center">
                  <div class="flex justify-center px-2">
                    <img
                      v-if="el.title.includes('Entre')"
                      src="../assets/ppd/Entrepreneurial_Skills.png"
                      class="h-20"
                      alt=""
                    />
                    <img
                      v-else-if="el.title.includes('Profess')"
                      src="../assets/ppd/Professional_Skills.png"
                      class="h-20"
                      alt=""
                    />
                    <img
                      v-else-if="el.title.includes('Social')"
                      src="../assets/ppd/Social_Skills.png"
                      class="h-20"
                      alt=""
                    />
                    <img
                      v-else-if="el.title.includes('Strate')"
                      src="../assets/ppd/Strategic_Skills.png"
                      class="h-20"
                      alt=""
                    />
                    <img
                      v-else
                      src="../assets/ppd/Leadership_Skills.png"
                      class="h-20"
                      alt=""
                    />
                  </div>
                  <Tracking
                    :course_id="el.id"
                    :user_token="this.userInfo.token_key"
                    :user_id="this.userInfo.user.id"
                  />
                </div>
              </div>
            </div>

            <div class="flex flex-col lg:flex-row gap-4">
              <!-- next Events -->
              <div
                class="events h-80 w-full lg:w-1/2 bg-white border border-gray-300 rounded-lg"
              >
                <h3 class="p-0 m-0 text-center text-gray-800 text-xl py-1">
                  Next Event
                </h3>
                <div
                  v-if="this.currentEvents.length > 0"
                  class="slider"
                  style="
                    height: 185px;
                    background-color: white;
                    border: solid 1px;
                    border-color: #d1d5db;
                  "
                  data-slick='{"slidesToShow": 1, "slidesToScroll": 1}'
                >
                  <div
                    v-for="event in this.currentEvents"
                    :key="event"
                    class="text-sm p-2"
                  >
                    <div class="flex flex-col gap-3 justify-center">
                      <div>
                        <p class="p-1 flex justify-end items-center gap-2 m-0">
                          Event @
                          <span
                            class="flex justify-end items-center m-0 p-0"
                            >{{
                          }}</span>
                          :
                          <span class="text-red-800">{{
                            new Date(event.date).toLocaleString("en-US", {
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            })
                          }}</span>
                        </p>
                      </div>
                      <h3
                        class="flex items-center justify-center text-base text-gray-800 my-2"
                      >
                        {{ event.title }}
                      </h3>
                      <a
                        :href="event.url"
                        class="flex justify-center items-center"
                      >
                        <button class="bg-primary px-2 py-1 text-sm rounded-lg">
                          Event Link
                        </button></a
                      >
                    </div>
                  </div>
                </div>
                <div v-else style="height: 185px">
                  <div class="">
                    <h3
                      class="flex items-center justify-center text-black"
                      style="color: #000"
                    >
                      No Events available
                    </h3>
                  </div>
                </div>
              </div>

              <!-- next Assessment -->
              <div
                class="h-80 w-full lg:w-1/2 bg-white border border-gray-300 rounded-lg"
              >
                <h3 class="p-0 m-0 text-center text-gray-800 text-xl py-1">
                  Assessment
                </h3>
                <div
                  v-if="this.form_link_list.length > 0"
                  class="p-4 shadow-xl rounded-md h-full"
                >
                  <div
                    v-for="event in this.form_link_list"
                    :key="event"
                    class="text-sm p-2"
                  >
                    <div class="flex gap-3 justify-between px-4">
                      <h3
                        class="flex items-center justify-start text-base text-gray-800 my-2 w-2/3 text-start"
                      >
                        {{ event.title }}
                      </h3>
                      <a
                        :href="event.Form_links"
                        target="_blank"
                        class="flex justify-center items-center w-1/3"
                      >
                        <button class="bg-primary px-2 py-1 text-sm rounded-lg">
                          Form Link
                        </button></a
                      >
                    </div>
                  </div>
                </div>
                <div v-else style="height: 185px">
                  <div class="">
                    <h3
                      class="flex items-center justify-center text-black"
                      style="color: #000"
                    >
                      No Events available
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- resource -->
          <div v-if="selectedcontent == 'resource'" class="w-full py-2">
            <div v-if="this.resourceData.length > 0" class="">
              <div
                v-for="resource in this.resourceData"
                :key="resource"
                class="flex flex-col gap-4 max-w-2xl w-full justify-center items-start bg-white p-4 rounded-lg border"
              >
                <h4 class="text-lg m-0">{{ resource.name }}</h4>
                <button>
                  <a
                    :href="`https` + resource.resource_file.slice(4)"
                    download
                    target="_blank"
                    class="bg-primary px-3 py-1 rounded-lg text-white hover:text-white text-base"
                    >Download</a
                  >
                </button>
              </div>
            </div>
            <div v-else class="text-center">No resource upload yet</div>
          </div>

          <!-- User Profile Details -->
          <section
            id="UserProfile"
            v-if="profileInfo && selectedcontent == 'profile'"
            class="w-full py-2"
          >
            <!-- firsthalf -->
            <div class="flex flex-col items-center">
              <div v-if="profileInfo.data.profile == ''">
                <p
                  class="border rounded-full w-48 h-48 self-center text-center flex items-center justify-center text-6xl text-black capitalize border-teal-700 bg-white font-semibold"
                >
                  {{ profileInfo.data.user.slice(0, 1) }}
                </p>
              </div>
              <div v-else>
                <img
                  :src="profileInfo.data.profile"
                  alt=""
                  class="w-48 h-48 rounded-full"
                />
              </div>
              <img
                v-if="profile == false"
                src="../assets/edit.svg"
                alt=""
                class="h-6 w-6"
                title="Edit Course"
                @click="profile = true"
              />
              <img
                v-else
                class="h-5 w-5 md:h-6 md:w-6"
                src="../assets/closebutton.svg"
                @click="profile = false"
              />
              <form @submit.prevent="imageHandles">
                <div v-if="profile" class="text-center flex flex-col">
                  <input
                    type="file"
                    id="image_uploads"
                    name="image_uploads"
                    accept=".jpg, .jpeg, .png, .webp"
                    class=""
                  />
                  <button
                    type="submit"
                    class="border rounded mt-1 primary-color"
                  >
                    Upload
                  </button>
                </div>
              </form>
              <p class="font-bold capitalize">{{ profileInfo.data.user }}</p>
              <!-- <p class="text-sm text-gray-700">({{profileInfo.data.designation}})</p> -->
            </div>
            <!-- secound half -->
            <div class="h-1/2 w-full flex justify-center">
              <div class="h-1/2 flex flex-col w-11/12 md:w-4/6">
                <div class="flex flex-col gap-2 justify-center">
                  <div class="text-base">
                    <p class="text-gray-800">Full Name</p>
                    <p class="text-base text-gray-500 capitalize">
                      {{ profileInfo.data.user }}
                    </p>
                  </div>
                  <hr />
                  <div class="text-base">
                    <p class="text-gray-800">Email</p>
                    <p class="text-gray-500 text-base">
                      {{ profileInfo.data.Email }}
                    </p>
                  </div>
                  <hr />
                  <div class="text-base">
                    <p class="text-gray-800">Mobile</p>
                    <p class="text-gray-500 text-base">
                      {{ profileInfo.data.mobile }}
                    </p>
                  </div>
                  <hr />
                  <div class="">
                    <router-link
                      :to="{
                        name: 'password',
                        params: { keyword: 'change', keyval: userInfo.slug_id },
                      }"
                      class="flex items-center gap-1 justify-start"
                    >
                      <img
                        src="../assets/passwordChange.svg"
                        alt=""
                        class="h-6 w-6"
                      />
                      <span class="px-2 text-gray-600 hover:text-teal-600"
                        >Change Password</span
                      >
                    </router-link>
                  </div>
                  <div @click="ProfileEdit" class="mt-2 relative">
                    <button class="primary-color px-4 rounded border">
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- Course Details -->
          <div
            v-if="this.selectedcontent == 'Courses'"
            class="flex flex-wrap justify-start w-full h-auto overflow-y-auto py-4 gap-4"
          >
            <div
              v-for="course in this.courseDetails"
              :key="course"
              class="h-48 w-80 shadow-lg card1 relative z-10"
            >
              <router-link
                :to="{ name: 'VideoRender', params: { courseid: course.id } }"
                class=""
              >
                <div
                  class="relative flex justify-center items-center"
                  style="height: 75%"
                >
                  <img
                    v-if="course.thumbnail"
                    class="object-cover object-center h-full w-full rounded-t-md"
                    :src="course.thumbnail"
                    alt=""
                  />
                  <img
                    v-else
                    class="object-cover object-center h-24 w-52 rounded"
                    src="../assets/coding.svg"
                    alt=""
                  />
                </div>

                <div
                  class="primary-color text-center flex flex-col justify-center shadow-lg rounded-b-md"
                  style="height: 25%"
                >
                  <p class="text-sm text-white truncate mb-0">
                    {{ course.title }}
                  </p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- popUp page -->
    <div
      v-if="popUp && updateDetails != null"
      class="fixed w-full h-full bg-black bg-opacity-75 top-0"
      style="z-index: 5000"
    >
      <div
        class="w-full md:w-5/6 lg:w-4/6 xl:w-3/6 2xl:w-2/6 h-full mx-auto flex flex-col justify-center"
      >
        <div
          class="bg-white h-auto flex flex-col gap-1 p-4 justify-center rounded-lg"
        >
          <div class="flex justify-between">
            <p class="text-gray-700 font-bold text-xl">Edit Details</p>
            <img
              src="../assets/closebutton.svg"
              @click="popUp = false"
              class="absolute h-8 -top-4 right-0 md:-top-14 md:-right-14 z-50 cursor-pointer"
            />
          </div>
          <div class="flex gap-2">
            <p class="w-1/3">Mobile</p>
            <input
              type="text"
              v-model="updateDetails.mobile"
              class="border-b-1 border-t-0 border-l-0 border-r-0 w-2/3"
            />
          </div>
          <hr />
          <div class="mt-2">
            <button
              @click="SubmitProfile()"
              type="submit"
              class="primary-color px-4 py-1 float-right border"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FlashMessage from "@/components/flashMessage.vue";
import { mapGetters } from "vuex";
import Navigation from "@/components/Navigation.vue";
import LeftNavigationView from "@/components/LeftNav.vue";
import Tracking from "@/components/studentTracking.vue";
export default {
  data() {
    return {
      profileInfo: null,
      popUp: false,
      updateDetails: null,
      selectedFile: null,
      tnxDetails: "",
      profile: false,
      messages: {
        text: "",
        type: "null",
      },
      selectedcontent: "journey",
      courseDetails: null,
      activeCourseId: "",
      resourceData: [],
      currentEvents: [],
      form_link_list: [],
      activeCourseList: [],
    };
  },
  components: {
    Navigation,
    FlashMessage,
    LeftNavigationView,
    Tracking,
  },
  props: ["selectValue"],
  computed: mapGetters(["userInfo", "isAuthenticated"]),

  async created() {
    this.selectedcontent = this.selectValue;
    await this.currentEventsCall();
    await this.currentCourseCall();
    await this.openProcess();
    await this.formLinkCall();
    axios
      .get(
        `${process.env.VUE_APP_API}/userpermissionlist/?Key=${this.userInfo.token_key}`
      )
      .then((response) => {
        this.courseDetails = response.data.data[0].course;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted() {},
  methods: {
    async formLinkCall() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/formlinks/list/${this.activeCourseId[0].id}/?Key=${this.userInfo.token_key}`
        )
        .then((resp) => {
          console.log(resp, "form info");
          if (resp.data.status == 200) {
            this.form_link_list = resp.data.data;
          } else {
            this.form_link_list = [];
          }
        });
    },
    slickCall() {
      console.log("slick");
      $(".slider").slick({
        infinite: true,
        dots: true,
        speed: 500,
      });

      $(".slider_form").slick({
        infinite: true,
        dots: true,
        speed: 500,
      });
    },
    async currentEventsCall() {
      // calendar/current/view/
      await axios
        .get(
          `${process.env.VUE_APP_API}/calendar/current/view/?Key=${this.userInfo.token_key}`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            this.currentEvents = resp.data.data;
          } else {
            this.currentEvents = [];
          }
        })
        .catch((error) => {
          console.log("error");
        });
      if (this.currentEvents.length > 0) {
        this.slickCall();
      }
    },
    async currentCourseCall() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/currentcoures/?Key=${this.userInfo.token_key}`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            console.log(resp.data);
            resp.data.course_data.forEach((el) => {
              this.activeCourseList.push(el.id);
            });
            this.activeCourseId = resp.data.course_data;
          } else {
            console.log(resp);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resourceCall() {
      this.selectedcontent = "resource";
      if (this.activeCourseId[0].id != "") {
        axios
          .get(
            `${process.env.VUE_APP_API}/coures-resource/list/?Course=${this.activeCourseId[0].id}`
          )
          .then((resp) => {
            if (resp.data.status == 200) {
              this.resourceData = resp.data.data;
            } else {
              this.resourceData = [];
            }
          });
      } else {
        this.resourceData = [];
      }
    },
    modulesAccordian(el) {
      let siblingVal = el.target.parentNode.nextSibling;
      siblingVal.classList.toggle("hidden");
    },
    async imageHandles() {
      this.profile = false;
      let headerprofile = new FormData();
      headerprofile.append("id", this.userInfo.user.id);
      headerprofile.append(
        "profile",
        document.querySelector("#image_uploads").files[0]
      );

      await axios
        .put(
          `${process.env.VUE_APP_API}/upload_photo/?Key=${this.userInfo.token_key}`,
          headerprofile
        )
        .then((response) => {
          if (response.data.message == "profile Data Updated successfully") {
            this.messages = {
              text: "Profile Uploaded successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        })
        .catch((error) => {
          this.messages = {
            text: error.response.data.error,
            type: "error",
          };
        });

      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
        this.openProcess();
      }, 3000);
    },

    onFileChanged(event) {
      this.selectedFile = event.target.files[0];
    },

    async onUpload() {
      // upload file
      await axios.post("my-domain.com/file-upload", this.selectedFile);
    },

    async openProcess() {
      let token = this.userInfo.token_key;
      let slug_id = this.userInfo.slug_id;

      await axios
        .get(
          `${process.env.VUE_APP_API}/profile_detail/${slug_id}?Key=${token}&format=json`
        )
        .then((response) => (this.profileInfo = response.data));
    },

    async ProfileEdit() {
      let details_id = this.profileInfo.data.id;
      let token = this.userInfo.token_key;
      this.popUp = true;

      await axios
        .get(
          `${process.env.VUE_APP_API}/profile_update/${details_id}?Key=${token}&format=json`
        )
        .then((response) => (this.updateDetails = response.data));
    },

    async SubmitProfile() {
      let details_id = this.profileInfo.data.id;
      let token = this.userInfo.token_key;
      let update = this.updateDetails;
      this.popUp = false;
      // console.log("updateDetails",updateDetails)

      await axios
        .put(
          `${process.env.VUE_APP_API}/profile_update/${details_id}/?Key=${token}&format=json`,
          update
        )
        .then((response) => {
          if (response.data.message == "Profile updated successfully") {
            this.messages = {
              text: "Profile Uploaded successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        });

      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
        this.openProcess();
      }, 3000);
    },
  },
};
</script>

<style scoped>
#image_uploads {
  background-color: white;
  border-radius: 5px;
  box-shadow: 2px 2px 4px black;
  width: 350px;
  outline: none;
}
.rotate_img {
  transform: rotate(180deg);
}
</style>
