<template>
    <div class="w-full min-h-screen max-w-7xl mx-auto">
        <Navigation />
        <div class="w-full h-full shadow-lg flex flex-col gap-6 p-8">
            <div v-for="course in course_list" :key="course" class="min-h-24 rounded-lg" @click="this.select_course = course.id">
                <div class="text-left h-full">
                    <div class="mb-0 bg-white shadow-lg border border-gray-300 rounded-lg h-full" @click="this.modulesAccordian">
                        <button
                            class="accordion-button relative flex items-center justify-between w-full py-4 px-5 font-semibold text-sm md:text-base text-gray-800 text-left border-0 rounded-none transition focus:outline-none"
                            type="button">
                            {{ course.title }}
                            <img src="../assets/down-arrow.svg" alt="down-arrow" class="down-arrow h-6  rotate_img"/>
                        </button>
                        <div class="h-full w-full" v-if="this.select_course == course.id">
                            <KeyTouchpointAdd :course_id="course.id" :user_token="this.userInfo.token_key" :user_id="this.userInfo.user.id" />
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Navigation from "@/components/Navigation.vue";
import KeyTouchpointAdd from "@/components/keyTouchpointView.vue";
import { mapGetters } from 'vuex';
export default {
    data(){
        return{
            course_list : [],
            select_course : ''
        }
    },
    computed: mapGetters(["userInfo"]),
    components:{
        KeyTouchpointAdd,
        Navigation,
    },
    created(){
        this.courseListCall()
    },
    methods:{
        courseListCall(){
            axios.get(`${process.env.VUE_APP_API}/courses/?format=json`)
            .then(response => {
                this.course_list = response.data.data
                if(this.course_list.length>0){
                    this.select_course = this.course_list[0].id
                }
            })
        },

    }
}
</script>

<style>

</style>