<template>
<div class="page-wrapper">
    <Navigation />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <confirmPopup v-if='popupwindow==true' :title="titlepass" @cancelClick="popupwindow=false" @confirmClick="deletecourse(idpass)" />
    <div class="container-default-1209px w-container">
        <div data-w-id="0b097d78-b776-9e09-a4b6-8ab657637199" style="" class="divider"></div>
    </div>
    <div data-w-id="0b097d78-b776-9e09-a4b6-8ab65763719a" style="" class="py-10">
        <div class="container-default-1209px w-container">
            <div data-w-id="0b097d78-b776-9e09-a4b6-8ab65763719c" style="" class="top-content">
                <div class="split-content courses-section-left">
                    <h2>Your Courses</h2>
                    <p class="paragraph mg-bottom-0px"></p>
                </div>
                <router-link :to="{name:'AddCourse'}" class="button-secondary w-button">Add Courses</router-link>
            </div>
            <div class="w-dyn-list">
                <div role="list" class="courses-section-grid w-dyn-items">
                    <div v-for="course in this.courselist" :key="course" data-w-id="0b097d78-b776-9e09-a4b6-8ab6576371be" role="listitem" class="course-card-wrapper w-dyn-item w-80 md:w-96 my-2" style="height:450px">
                        <div class="card w-80 md:w-96" style="height:450px">
                            <div class="w-80 md:w-96" style="height:450px">
                                <div class="card-image-link w-inline-block relative">
                                    <img :src="course.thumbnail" style="overflow:hidden;height:208px" alt="" class="image course w-80 md:w-96">
                                    <button @click="triggerEmail(course.id)" class="absolute bottom-0 right-2 bg-primary px-4 py-1 rounded-md">Trigger</button>
                                </div>
                                <router-link :to="{name: 'InstructorStudents',params: {course_id: course.id,instructor: course.instructor,course_name: course.title}}" class="card-content">
                                    <a href="#" class="card-title-link w-inline-block">
                                        <h3 class="text-lg 2xl:text-xl course hover:text-red-800">{{course.title.slice(0,80)}} <span v-if="course.short_description.length>80">....</span></h3>
                                    </a>
                                    <p class="text-base primary-text-color px-4">{{course.short_description.slice(0,80)}} <span v-if="course.short_description.length>80">....</span></p>
                                </router-link>
                                <div class="flex justify-evenly gap-4 items-center">
                                    <div class="flex gap-1 items-center justify-between">
                                        <router-link :to="{name:'UpdateLesson',params:{courseId:course.id}}" class="border px-2 rounded flex gap-1 border-green-400 text-sm items-center py-1 hover:bg-green-100 ">
                                            <img src="../assets/Addbutton.svg" alt="" class="h-6 w-6 cursor-pointer" title="Add Lesson">
                                            Add resource
                                        </router-link>
                                        <router-link :to="{name:'EditCourse',params:{course_id:course.id}}" class="border border-gray-200 px-2 rounded flex gap-1 text-sm items-center py-1 hover:bg-gray-100 ">
                                            <img src="../assets/edit.svg" alt="" class="h-6 w-6 cursor-pointer" title="Edit Course">
                                            Edit Course
                                        </router-link>
                                        <button class="border border-red-400 px-2 rounded flex gap-1 text-sm items-center py-1 hover:bg-red-100 " @click="titlepass=course.title;popupwindow=true;idpass=course.id">
                                            <!-- <img src="../../assets/trash.svg" alt="" class="h-6 w-6 cursor-pointer" title="Delete Course" >  -->
                                            <font-awesome-icon class="text-base text-red-400 self-center h-6 w-6" :icon="['fas','trash-alt']" />
                                            Delete
                                        </button>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div  v-if="this.messages.message != ''" :class="[this.messages.status == 200 ? 'bg-green-800' : 'bg-red-800']" class="fixed top-4 text-sm text-white right-2 w-64 h-16 py-2 border rounded-md font-semibold flex items-center justify-center" style="z-index : 6000">
        {{ this.messages.message }}
    </div>

    <div class="container-default-1209px w-container">
        <div data-w-id="0b097d78-b776-9e09-a4b6-8ab657637274" style="" class="divider"></div>
    </div>
    <Footer />
</div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import confirmPopup from "@/components/confirmPopup.vue";
import FlashMessage from "@/components/flashMessage.vue";
import {
    mapGetters
} from 'vuex';
export default {
    name: "Home",
    data() {
        return {
            courselist: "",
            profileInfo: "",
            instructorInfo: "",
            webinar_List: '',
            blog_list :'',
            messages : {
                message : '',
                status : '200'
            },
            popupwindow: false,
            idpass: "",
            titlepass: "",
        }
    },
    components: {
        Navigation,
        Footer,
        confirmPopup,
        FlashMessage,
    },
    computed: mapGetters(["isAuthenticated", "userInfo"]),

    async created() {
        await axios.get(`${process.env.VUE_APP_API}/instructor/?Key=${this.userInfo.token_key}`)
            .then(response => {
                this.instructorInfo = response.data
            })
        await axios.get(`${process.env.VUE_APP_API}/courses/?instructor=${this.instructorInfo.id}`)
            .then(response => {
                if (response.data.message == "No courses details") {
                    console.log("Null")
                } else {
                    this.courselist = response.data.data
                    this.courselist.forEach(element => {
                        element.displayprice = new Intl.NumberFormat('hi-IN', {
                            style: 'currency',
                            currency: 'INR',
                            maximumFractionDigits: 0,
                        }).format(element.price);
                    });
                }
            })
    
        if (this.userInfo && this.isAuthenticated) {

            axios.get(`${process.env.VUE_APP_API}/profile_detail/${this.userInfo.slug_id}?Key=${this.userInfo.token_key}&format=json`)
                .then(response => {
                    this.profileInfo = response.data
                })
        }

    },
    methods:{
        async deletecourse(data) {
            await axios.delete(`${process.env.VUE_APP_API}/courseupdate/${data}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    this.res = response
                    if (this.res.statusText == 'No Content') {
                        this.messages = {
                            text: "Deleted successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Failed",
                            type: 'error',
                        }
                    }
                })
            this.popupwindow = false

            setTimeout(() => {
                this.$router.go(0);
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 2000)
        },
        triggerEmail(id){
            axios.post(`${process.env.VUE_APP_API}/reminder/post/${id}/?Key=${this.userInfo.token_key}`)
            .then(response => {
                console.log(response)
                if(response.data.status==200){
                    this.messages.message = 'Email Trigged Successfully'
                    this.message.type = '200'
                }else{
                    this.messages.message = 'Trigger Failed'
                    this.message.type = '400'
                }
            })
            setTimeout(()=>{
                this.messages.message = ''
                    this.message.type = '200'
            },3000)
        }
    }

    
};
</script>
