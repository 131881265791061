<template>
  <div v-if="this.courselist" class="page-wrapper">
    <Navigation />
    <div class="flex w-full 2xl:max-w-7xl mx-auto">
      <LeftNavigationView actived="course" />
      <div class="w-full">
        <div
          id="videoFile"
          v-if="is_video == true"
          class="fixed top-0 bg-black bg-opacity-90 w-full h-full flex items-center"
          style="z-index: 1000"
        >
          <div
            v-if="this.videoLink"
            class="w-full h-full relative-md mx-auto max-w-sm md:max-w-2xl max-h-56 md:max-h-96"
          >
            <template v-if="this.videoLink">
              <video :src="this.videoLink" controls width="1200"></video>
            </template>
            <template v-else>
              <div class="max-w-5xl w-full">
                <p class="text-5xl text-white">No Data</p>
              </div>
            </template>
            <img
              src="../assets/closebutton.svg"
              @click="is_video = false"
              class="absolute top-2 right-2 md:- h-8top-14 md:-right-14 z-50 cursor-pointer"
            />
          </div>
          <div
            v-else
            class="w-full h-full relative mx-auto max-w-sm md:max-w-2xl max-h-56 md:max-h-96"
          >
            <h1 class="text-center text-xl card py-4">No Video found</h1>
            <img
              src="../assets/closebutton.svg"
              @click="is_video = false"
              class="absolute h-8 -top-8 right-4 md:-top-14 md:-right-14 z-50 cursor-pointer"
            />
          </div>
        </div>
        <div
          v-if="is_content == true"
          class="fixed top-0 bg-black bg-opacity-90 w-full h-full flex items-center"
          style="z-index: 1000"
        >
          <div
            v-if="this.contant"
            class="popupdisplay w-full h-full relative mx-auto max-w-sm md:max-w-3xl bg-white overflow-y-auto"
            style="height: 94%; scrollbar-color: gray white"
          >
            <img
              src="../assets/closebutton.svg"
              @click="is_content = false"
              class="sticky top-0 right-0 float-right h-8 cursor-pointer"
              style="z-index: 1000"
            />
            <v-md-preview :text="this.contant"></v-md-preview>
          </div>
          <div
            v-else
            class="w-full h-full relative mx-auto max-w-sm md:max-w-2xl max-h-56 md:max-h-96"
          >
            <h1>No Data found</h1>
            <img
              src="../assets/closebutton.svg"
              @click="is_video = false"
              class="absolute h-8 -top-8 right-4 md:top-6 md:right-10 z-50 cursor-pointer"
            />
          </div>
        </div>
        <div class="w-full">
          <div class="w-full">
            <div class="flex justify-between p-6 items-center">
              <div
                data-w-id="fee87d54-5c1d-9d5c-67e3-157fa7bb8ab3"
                style=""
                class="split-content video-hero-left"
              >
                <h1 class="text-2xl m-0">
                  {{ this.courselist[0].title }}
                </h1>
                <p class="text-sm">
                  {{ this.courselist[0].short_description }}
                </p>
                <!-- <p class="button-primary w-button" >Info</p> -->
                <!-- <router-link :to="{name:'studentEvents',params: {slug: this.userInfo.slug_id,course_id: this.courselist[0].id,instructor_id: this.courseDetailInfo.instructor_id,course_name: this.courselist[0].title,}}" class="button-primary w-button">Course Dashboard</router-link> -->
              </div>
              <!-- <div class="rounded-lg" style="width: 300px;"><img :src="this.courselist[0].thumbnail" alt="" class="object-contain rounded-lg">
                </div> -->
            </div>
          </div>
        </div>
        <div class="section videos wf-section">
          <div class="container-fw video-tabs w-container">
            <div
              data-duration-in="300"
              data-duration-out="100"
              data-current="Tab 1"
              data-easing="ease"
              class="videos-tabs w-tabs"
            >
              <div
                id="Course"
                class="py-1.5 bg-gray-100 border border-gray-300 bg-opacity-70 flex items-center gap-4 md:px-4 rounded-lg relative h-auto mx-4"
              >
                <div
                  @click="tap = 'about'"
                  style=""
                  :class="{ 'bg-primary': tap == 'about' }"
                  class="px-4 rounded-lg text-base py-1 cursor-pointer"
                >
                  <div>About</div>
                </div>
                <template v-if="this.sectionInfo != ''">
                  <div
                    v-if="this.sectionInfo.data.length > 0"
                    @click="tap = 'video'"
                    style=""
                    :class="{ 'bg-primary': tap == 'video' }"
                    class="px-4 rounded-lg text-base py-1 cursor-pointer"
                  >
                    <div>Videos</div>
                  </div>
                </template>
                <div
                  @click="tap = 'resources'"
                  style=""
                  :class="{ 'bg-primary': tap == 'resources' }"
                  class="px-4 rounded-lg text-base py-1 cursor-pointer"
                >
                  <div>Resources</div>
                </div>
                <div
                  @click="this.quizgetinfo"
                  data-w-id="fee87d54-5c1d-9d5c-67e3-157fa7bb8ae0"
                  style=""
                  :class="{ 'bg-primary': tap == 'quiz' }"
                  class="px-4 rounded-lg text-base py-1 cursor-pointer"
                >
                  <div>Quiz</div>
                </div>
              </div>
              <div
                data-w-id="fee87d54-5c1d-9d5c-67e3-157fa7bb8ae3"
                style=""
                class="videos-tabs-content w-tab-content"
              >
                <div v-if="tap == 'about'" class="">
                  <div class="rich-text w-richtext">
                    <v-md-preview
                      :text="this.courselist[0].description"
                    ></v-md-preview>
                  </div>
                </div>
                <div v-if="tap == 'video' && sectionInfo != null" class="">
                  <div v-for="section in sectionInfo.data" :key="section">
                    <div class="videos">
                      <h3 class="title videos">{{ section.title }}</h3>
                    </div>
                    <div class="spacer course-videos"></div>
                    <div
                      v-if="Object.keys(this.lessonInfo) != 'error'"
                      class="videos-wrapper"
                    >
                      <div v-for="lesson in lessonInfo.results" :key="lesson">
                        <div v-if="section.id == lesson.section_id">
                          <a
                            href="#"
                            class="border border-gray-400 flex justify-between rounded p-4"
                          >
                            <div
                              @click="
                                videoLink = lesson.video_url;
                                is_video = true;
                              "
                              class="flex items-center gap-2 justify-center"
                            >
                              <img
                                src="../../catalyft-ui-templates/images/icon-course-video-academy-template.svg"
                                alt="Play Icon"
                                class="course-video-icon mb-0"
                              />
                              <div class="h-full flex items-center">
                                <h3
                                  class="text-base md:text-xl course-video h-full hover:text-blue-800 m-0 flex items-center"
                                >
                                  {{ lesson.title }}
                                </h3>
                              </div>
                            </div>
                            <div
                              class="course-video-duration-text flex flex-col-reverse gap-4"
                            >
                              <button
                                v-if="lesson.content"
                                class="gradient-btn"
                                @click="
                                  contant = lesson.content;
                                  is_content = true;
                                "
                              >
                                view Notes
                              </button>
                              <button>{{ lesson.duration }} min</button>
                            </div>
                          </a>
                          <div class="spacer course-videos"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="tap == 'resources'" class="flex flex-col gap-2">
                  <div class="mt-4">
                    <h3 class="title files m-0">
                      {{ documentList.length }} Files &amp; Resources
                    </h3>
                    <!-- <p class="paragraph mg-bottom-0px">Browse all the links and downloads mentioned in the course. If you would like us to include any other resource, please let us know in the Community group.</p> -->
                  </div>
                  <div v-if="documentList.length > 0">
                    <div
                      class="files-wrapper"
                      v-for="file in documentList"
                      :key="file"
                    >
                      <div class="card course-files w-inline-block">
                        <div class="split-content course-files-left">
                          <div class="icon-files-wrapper">
                            <img
                              src="../../catalyft-ui-templates/images/icons-files-04-academy-template.svg"
                              alt="File - Academy Webflow Template"
                            />
                          </div>
                          <div class="text-lg text-gray-800">
                            {{ file.name }}
                          </div>
                        </div>
                        <div v-if="file.download" class="download-files-text">
                          <a
                            :href="`https` + file.resource_file.slice(4)"
                            target="_blank"
                            download
                          >
                            Download   <span class="icon-file"></span></a
                          >
                        </div>
                      </div>
                      <div class="spacer files"></div>
                    </div>
                  </div>
                  <div v-else class="card empty-state w-dyn-empty">
                    <div class="empty-state-text">
                      There are no Resources available yet.
                    </div>
                  </div>
                </div>
                <div v-if="tap == 'quiz'">
                  <div class="videos-social-media-wrapper">
                    <div class="flex flex-col items-center h-full w-full">
                      <div
                        v-for="quiz in this.generalquiz"
                        :key="quiz"
                        class="flex flex-col bg-white w-5/6 h-36 m-1 cursor-pointer relative border border-gray-300 shadow-md rounded-md"
                      >
                        <router-link
                          :to="{
                            name: 'Quizzes',
                            params: { quizId: quiz.quiz.id },
                          }"
                        >
                          <div
                            class="flex flex-col md:flex-row gap-2 items-center p-1 h-40 w-full"
                          >
                            <img
                              v-if="quiz.quiz.image"
                              class="object-cover object-center h-24 w-52"
                              :src="quiz.quiz.image"
                              alt=""
                            />
                            <img
                              v-else
                              class="object-cover object-center bg-teal-400 h-24 w-52"
                              src="../assets/coding.svg"
                              alt=""
                            />
                            <div
                              class="flex justify-between items-center w-5/6"
                            >
                              <div class="w-full h-24 overflow-hidden">
                                <h1
                                  class="text-gray-700 text-lg font-bold hover:text-teal-600"
                                >
                                  {{ quiz.quiz.name }}
                                </h1>
                                <h3
                                  class="text-left text-sm px-1 py-1 text-red-500 font-medium"
                                >
                                  {{ quiz.quiz.coursequizquestion_set.length }}
                                  Questions
                                </h3>
                                <p class="text-sm px-1">
                                  {{ quiz.quiz.description }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  <!-- <div id="main2" class="w-full h-screen flex items-center justify-center ">
    <img src="../assets/LoadingBall.svg" alt="" class="w-32 h-32">
</div> -->
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import "@uivjs/vue-markdown-preview/markdown.css";
import MarkdownPreview from "@uivjs/vue-markdown-preview";
import LeftNavigationView from "@/components/LeftNav.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Navigation,
    MarkdownPreview,
    Footer,
    LeftNavigationView,
  },
  data: function () {
    return {
      lessonInfo: "",
      sectionInfo: "",
      courselist: "",
      courseDetailInfo: "",
      lessonCount: "",
      tap: "about",
      videoLink: "",
      is_video: false,
      is_content: false,
      content: "",
      courselimited: "",
      documentList: "",
      courselistsuggest: "",
      is_topbutton: false,
      generalquiz: [],
    };
  },
  props: ["courseid"],
  computed: mapGetters(["userInfo", "subscriptionInfo", "isAuthenticated"]),

  async created() {
    await axios
      .get(
        `${process.env.VUE_APP_API}/courses/?id=${this.courseid}&format=json`
      )
      .then((response) => {
        this.courselist = response.data.data;
      });
    this.detailsgets();
    this.documentInfo();
  },

  mounted() {
    // setTimeout(() => {
    //     document.querySelector("#main").style.display = "block"
    //     document.querySelector("#main2").style.display = "none"
    // }, 3000)

    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 27) {
        this.is_video = false;
        this.is_content = false;
      }
    });
  },

  methods: {
    async detailsgets() {
      await axios
        .get(`${process.env.VUE_APP_API}/lessons/?course_name=${this.courseid}`)
        .then((response) => {
          this.lessonInfo = response.data;
          if (Object.keys(this.lessonInfo) == "error") {
            this.lessonCount = 0;
          } else {
            this.lessonCount = this.lessonInfo.count;
          }
        });
      await axios
        .get(`${process.env.VUE_APP_API}/section/?course_name=${this.courseid}`)
        .then((response) => {
          this.sectionInfo = response.data;
        });
      // await axios
      //   .get(
      //     `${process.env.VUE_APP_API}/courses/?id=${parseInt(this.courseid)}`
      //   )
      //   .then((response) => {
      //     this.courseDetailInfo = response.data.data;
      //     console.log(this.courseDetailInfo);
      //   });
      await axios
        .get(
          `${process.env.VUE_APP_API}/courses/?is_published=true&format=json`
        )
        .then((response) => {
          this.courselistsuggest = response.data.data;
          if (this.courselistsuggest.length >= 3) {
            this.courselimited = this.courselistsuggest.slice(0, 3);
          } else {
            this.courselimited = response.data.data;
          }
        });
    },

    async documentInfo() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/coures-resource/list/?Course=${this.courseid}`
        )
        .then((response) => {
          if (response.data.message == "Have a Documents")
            this.documentList = response.data.data;
          else {
            this.documentList = "";
          }
        });
    },
    forwardCouse(course) {
      this.$router.push({
        name: "CouseDetail",
        params: {
          slug: course.slug,
          title: course.title,
        },
      });
    },
    async quizgetinfo() {
      this.tap = "quiz";
      await axios
        .get(
          `${process.env.VUE_APP_API}/course_quizzes_detail/${this.courseid}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          this.generalquiz = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped></style>
