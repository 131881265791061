<template>
  <div class="sm:flex lg:hidden left-0 fixed top-19 z-50">
    <button
      @click="toggleClassMenu"
      class="shadow-xl rounded-r-md border border-white bg-blue-700 z-50"
      aria-label="Open Menu"
      style="z-index: 289"
    >
      <svg
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        viewBox="0 0 24 24"
        class="w-8 h-8 text-white"
      >
        <path d="M4 6h16M4 12h16M4 18h16"></path>
      </svg>
    </button>
  </div>

  <div
    v-if="this.activeCourse != ''"
    class="layout text-base px-4 border border-gray-200"
    id="clickfun"
  >
    <!-- 2nd nav -->
    <div
      class="leftmenu absolute left-0 hidden lg:hidden h-full"
      style="width: 100vw"
    >
      <div
        class="h-full flex flex-col w-full justify-start items-start text-gray-100 font-medium bg-black bg-opacity-75"
        style="z-index: 289"
      >
        <div class="w-44 md:w-56 h-full flex flex-col gap-2 md:gap-3 bg-white">
          <router-link
            :to="{ name: 'Home' }"
            class="cursor-pointer mx-auto mt-2"
          >
            <div
              class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4"
              :class="{
                'border rounded primary-border bg-gray-100 border-l-4':
                  actived == 'home',
              }"
            >
              <img src="../assets/home.png" class="w-5 h-5" />
              <div>Home</div>
            </div>
          </router-link>
          <router-link
            :to="{ name: 'UserProfile', params: { selectValue: 'journey' } }"
            class="cursor-pointer mx-auto mt-2"
          >
            <div
              class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4"
              :class="{
                'border rounded primary-border bg-gray-100 border-l-4':
                  actived == 'profile',
              }"
            >
              <img src="../assets/profile.svg" class="w-5 h-5" />
              <div>Profile</div>
            </div>
          </router-link>
          <router-link
            :to="{
              name: 'VideoRender',
              params: { courseid: this.activeCourse.id },
            }"
            class="cursor-pointer mx-auto mt-2"
          >
            <div
              class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4"
              :class="{
                'border rounded primary-border bg-gray-100 border-l-4':
                  actived == 'course',
              }"
            >
              <img src="../assets/content.svg" class="w-5 h-5" />
              <div>Course</div>
            </div>
          </router-link>
          <router-link
            :to="{
              name: 'messageStudent',
              params: {
                course_id: this.activeCourse.id,
                instructor_id: this.activeCourse.instructor_id,
                course_name: this.activeCourse.title,
              },
            }"
            class="cursor-pointer mx-auto mt-2"
          >
            <div
              class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4"
              :class="{
                'border rounded primary-border bg-gray-100 border-l-4':
                  actived == 'messages',
              }"
            >
              <img src="../assets/message.svg" class="w-5 h-5" />
              <div>Messages</div>
            </div>
          </router-link>
          <router-link
            :to="{
              name: 'assignmentStudent',
              params: {
                course_id: this.activeCourse.id,
                instructor_id: this.activeCourse.instructor_id,
                course_name: this.activeCourse.title,
              },
            }"
            class="cursor-pointer mx-auto"
          >
            <div
              class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4"
              :class="{
                'border rounded primary-border bg-gray-100 border-l-4':
                  actived == 'assignment',
              }"
            >
              <img src="../assets/assignment.svg" class="w-5 h-5" alt="" />
              <div>Resources</div>
            </div>
          </router-link>
          <router-link
            :to="{
              name: 'studentEvents',
              params: {
                course_id: this.activeCourse.id,
                instructor_id: this.activeCourse.instructor_id,
                course_name: this.activeCourse.title,
              },
            }"
            class="mx-auto"
          >
            <div
              class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4"
              :class="{
                'border rounded primary-border bg-gray-100 border-l-4':
                  actived == 'events',
              }"
            >
              <img src="../assets/events.svg" class="w-5 h-5" alt="" />
              <div>Events</div>
            </div>
          </router-link>
          <router-link
            :to="{
              name: 'chatStudent',
              params: {
                course_id: this.activeCourse.id,
                instructor_id: this.activeCourse.instructor_id,
                course_name: this.activeCourse.title,
              },
            }"
            class="mx-auto"
          >
            <div
              class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4"
              :class="{
                'border rounded primary-border bg-gray-100 border-l-4':
                  actived == 'forums',
              }"
            >
              <img src="../assets/forums.svg" class="w-5 h-5" alt="" />
              <div>Group Chat</div>
            </div>
          </router-link>
          <router-link
            :to="{
              name: 'StudentQuizList',
              params: {
                course_id: this.activeCourse.id,
                instructor_id: this.activeCourse.instructor_id,
                course_name: this.activeCourse.title,
              },
            }"
            :class="{ 'bg-sky-100': actived == 'quiz' }"
          >
            <div
              class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4"
            >
              <img src="../assets/ideas.png" class="h-6" />
              <p>Quiz</p>
            </div>
          </router-link>
        </div>
        <img
          @click="toggleClassMenu()"
          src="../assets/closebutton.svg"
          class="absolute menu-icon w-8 h-8 right-2 cursor-pointer"
        />
      </div>
    </div>

    <!-- left nav for lg screen -->
    <div
      class="hidden w-48 2xl:w-56 largerLayout h-full lg:flex lg:flex-col justify-start items-start text-black font-medium relative gap-2 py-2"
    >
      <div
        class="collapseDiv flex items-center bottom-0 cursor-pointer px-4 py-2 gap-2 border rounded-md border-gray-100 shadow-sm mx-auto"
        @click="slideFn"
      >
        <img src="../assets/leftArrow.svg" class="w-7 p-1 LeftArrow" />
        <p class="collapseText text-sm mb-0">Collapse sidebar</p>
      </div>
      <router-link :to="{ name: 'Home' }" class="cursor-pointer mx-auto">
        <div
          class="contentval w-48 2xl:w-52 textthis.activeCourse-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex gap-2 px-4 rounded-md border"
          :class="{
            'primary-border bg-gray-100 border-l-4': actived == 'home',
          }"
        >
          <img src="../assets/home.png" class="w-6 h-6" />
          <p class="mb-0">Home</p>
        </div>
      </router-link>
      <router-link
        :to="{ name: 'UserProfile', params: { selectValue: 'journey' } }"
        class="cursor-pointer mx-auto"
      >
        <div
          class="contentval w-48 2xl:w-52 textthis.activeCourse-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex gap-2 px-4 rounded-md border"
          :class="{
            'primary-border bg-gray-100 border-l-4': actived == 'profile',
          }"
        >
          <img src="../assets/profile.svg" class="w-6 h-6" />
          <p class="mb-0">Profile</p>
        </div>
      </router-link>
      <router-link
        :to="{
          name: 'VideoRender',
          params: { courseid: this.activeCourse.id },
        }"
        class="cursor-pointer mx-auto"
      >
        <div
          class="contentval w-48 2xl:w-52 textthis.activeCourse-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex gap-2 px-4 rounded-md border"
          :class="{
            'primary-border bg-gray-100 border-l-4': actived == 'course',
          }"
        >
          <img src="../assets/content.svg" class="w-6 h-6" />
          <p class="mb-0">Course</p>
        </div>
      </router-link>
      <router-link
        :to="{
          name: 'messageStudent',
          params: {
            course_id: this.activeCourse.id,
            instructor_id: this.activeCourse.instructor_id,
            course_name: this.activeCourse.title,
          },
        }"
        class="cursor-pointer mx-auto"
      >
        <div
          class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex gap-2 px-4 rounded-md border"
          :class="{
            'primary-border bg-gray-100 border-l-4': actived == 'messages',
          }"
        >
          <img src="../assets/message.svg" class="w-6 h-6" />
          <p class="mb-0">Messages</p>
        </div>
      </router-link>
      <router-link
        :to="{
          name: 'assignmentStudent',
          params: {
            course_id: this.activeCourse.id,
            instructor_id: this.activeCourse.instructor_id,
            course_name: this.activeCourse.title,
          },
        }"
        class="cursor-pointer mx-auto"
      >
        <div
          class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex gap-2 px-4 rounded-md border"
          :class="{
            'primary-border bg-gray-100 border-l-4': actived == 'assignment',
          }"
        >
          <img src="../assets/assignment.svg" class="w-6 h-6" alt="" />
          <p class="mb-0">Resources</p>
        </div>
      </router-link>

      <router-link
        :to="{
          name: 'studentEvents',
          params: {
            course_id: this.activeCourse.id,
            instructor_id: this.activeCourse.instructor_id,
            course_name: this.activeCourse.title,
          },
        }"
        class="mx-auto"
      >
        <div
          class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex gap-2 px-4 rounded-md border"
          :class="{
            'primary-border bg-gray-100 border-l-4': actived == 'events',
          }"
        >
          <img src="../assets/events.svg" class="w-6 h-6" alt="" />
          <p class="mb-0">Events</p>
        </div>
      </router-link>

      <router-link
        :to="{
          name: 'chatStudent',
          params: {
            course_id: this.activeCourse.id,
            instructor_id: this.activeCourse.instructor_id,
            course_name: this.activeCourse.title,
          },
        }"
        class="mx-auto"
      >
        <div
          class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex gap-2 px-4 rounded-md border"
          :class="{
            'primary-border bg-gray-100 border-l-4': actived == 'forums',
          }"
        >
          <img src="../assets/forums.svg" class="w-6 h-6" alt="" />
          <p class="mb-0">Group Chat</p>
        </div>
      </router-link>
      <router-link
        :to="{
          name: 'StudentQuizList',
          params: {
            course_id: this.activeCourse.id,
            instructor_id: this.activeCourse.instructor_id,
            course_name: this.activeCourse.title,
          },
        }"
        class="mx-auto"
      >
        <div
          class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex gap-2 px-4 rounded-md border"
          :class="{
            'primary-border bg-gray-100 border-l-4': actived == 'quiz',
          }"
        >
          <img src="../assets/ideas.png" class="h-6" />
          <p class="mb-0">Quiz</p>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      activeCourse: "",
    };
  },
  computed: mapGetters(["isAuthenticated", "userInfo"]),
  props: {
    actived: String,
  },
  async created() {
    if (this.isAuthenticated == true) {
      await axios
        .get(
          `${process.env.VUE_APP_API}/currentcoures/?Key=${this.userInfo.token_key}`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            this.activeCourse = resp.data.course_data[0];
            console.log(this.activeCourse, "active course");
          } else {
            console.log(resp);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  mounted() {
    if (this.activeCourse != "") {
      var oppMenu = document.querySelector(".menu-icon");
      oppMenu.addEventListener("click", this.toggleClassMenu, false);
    }
  },
  methods: {
    slideFn() {
      console.log("Slide");

      document.querySelector(".LeftArrow").classList.toggle("rotateing");
      document.querySelector(".collapseText").classList.toggle("hidden");
      document.querySelector(".collapseDiv").classList.toggle("border-none");

      var largelayout = document.querySelector(".largerLayout");
      if (!largelayout.classList.contains("app-menu-close")) {
        largelayout.classList.add("app-menu-close");
      } else {
        largelayout.classList.remove("app-menu-close");
      }
    },
    toggleClassMenu() {
      var layout = document
        .querySelector(".leftmenu")
        .classList.toggle("hidden");
    },
  },
};
</script>

<style scoped>
.largerLayout {
  transition: width 1.5s;
}

.rotateing {
  transform: rotate(180deg) translate(-5px);
}

.contentval {
  transition: width 1.5s;
}
.app-menu-close {
  width: 60px;
  transition: width 1.5s;
  margin: auto;
}

.app-menu-close .contentval {
  width: 60px;
  padding-left: 0px;
  padding-right: 0px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-menu-close .contentval p {
  display: none;
}
</style>
