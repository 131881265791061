<template lang="">
  <div class="flex" style="height: calc(100vh - 113px)">
    <leftNavigationStudent actived="assignment" />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <confirmPopup
      v-if="popupwindow == true"
      :title="titlepass"
      @cancelClick="popupwindow = false"
      @confirmClick="deleteAssignment(idpass)"
    />
    <div class="w-full h-full relative">
      <!-- Breadcrumb -->
      <!-- <div>
            <ol class="flex gap-2 items-center border-b p-2">
                <li class="text-gray-500 text-sm">
                    <router-link :to="{name : 'Dashboard'}">
                        Dashboard
                    </router-link> >
                </li>
                <li class="text-gray-500 text-sm">
                    <router-link :to='{name :"sessionStudent"}'>
                        {{course_name}}
                    </router-link> >
                </li>
                <li class="text-gray-500 text-sm">

                    Assignments

                </li>
            </ol>

        </div> -->
      <div
        v-if="assignmentInfo && assignmentPopup == false"
        class="flex flex-col h-full w-full px-1 lg:px-10 py-1 lg:mt-0 overflow-y-auto relative"
      >
        <div
          class="w-full flex justify-center lg:justify-start py-1 px-2 text-gray-900 bg-white border-gray-300 text-xl capitalize"
        >
          <p class="mb-0">{{ course_name }}</p>
        </div>
        <div
          class="bg-gray-50 shadow-lg p-2 rounded flex gap-4 justify-center lg:justify-start"
        >
          <div
            @click="this.submitted = 'assignment'"
            class="text-base px-2 text-gray-900 border py-1 select-none rounded"
            :class="{
              'primary-border bg-primary': this.submitted == 'assignment',
            }"
          >
            Resources
          </div>
          <div
            @click="this.submitted = 'submitted'"
            class="text-base px-2 text-gray-900 border py-1 select-none rounded hidden"
            :class="{
              'primary-border bg-primary': this.submitted == 'submitted',
            }"
          >
            Submitted
          </div>
        </div>
        <div v-if="this.submitted == 'assignment'" class="px-2 mt-4 h-screen">
          <!-- <p class='text-sky-700 text-2xl font-bold'> Assignments List </p> -->
          <main
            class="flex flex-wrap items-start sm:justify-center lg:justify-start gap-4 w-full mx-auto"
            style="height: 97%"
          >
            <div
              v-if="assignmentsNull == true"
              class="h-auto flex gap-4 flex-wrap justify-center py-2"
            >
              <div
                v-for="assignment in assignmentInfo.data"
                :keys="assignment"
                class="maincontent border rounded w-80 p-1 px-2 md:p-4 flex flex-col gap-1 bg-white rounded-lg shadow-lg relative"
              >
                <div class="" style="height: 32%">
                  <p class="text-xs text-red-400 px-1" style="height: 12%">
                    Due Date
                  </p>
                  <div style="height: 88%">
                    <div
                      class="text-xl font-bold lg:text-2xl 2xl:text-4xl text-gray-500"
                    >
                      {{ assignment.display_date.substring(0, 2) }}
                    </div>
                    <div class="text-base md:text-xl text-gray-500">
                      {{ assignment.display_date.substring(2) }}
                    </div>
                  </div>
                </div>
                <div
                  @click="detailsPopup(assignment)"
                  class="overflow-hidden cursor-pointer"
                  style="height: 88%"
                >
                  <div
                    class="text-sm xl:text-base text-gray-900 text-justify hover:text-indigo-900"
                  >
                    {{ assignment.title.slice(0, 125) }}
                  </div>
                  <div class="text-sm text-gray-500 py-0.5 overflow-y-hidden">
                    {{ assignment.description.slice(0, 300) }}
                  </div>
                </div>
                <div
                  class="flex gap-4 justify-center items-center mt-2"
                  style="height: 10%"
                >
                  <a
                    v-if="assignment.file"
                    :href="assignment.file"
                    class="gradient-btn"
                    target="_blank"
                    download
                    >Download</a
                  >
                  <button
                    @click="
                      this.assignmentPopup = !this.assignmentPopup;
                      this.assignmentId = assignment.id;
                    "
                    class="gradient-btn hidden"
                  >
                    Submit
                  </button>
                  <!-- <p>Description :<span>{{assignment.description}}</span> </p> -->
                </div>
              </div>
            </div>
            <div v-else>No Assignments Yet !</div>
          </main>
          <!-- <div class="absolute -top-11  right-0 flex items-center">
                <button @click="this.submitted=true" class="px-2 rounded border py-1 bg-white hover:bg-teal-500 hover:text-white select-none" :class="{'bg-teal-500 text-white':this.submitted==true}">
                    Submitted Assignments</button>
                <img class='h-6 w-6 md:h-8 md:w-8 ' src="../../assets/submit.svg">
            </div> -->
        </div>
        <div v-if="submitted == 'submitted'" class="px-2 mt-4 w-full md:w-5/6">
          <div
            v-for="assignment in submitassignmentInfo.data"
            :keys="assignment"
            class="my-2 submitedCard"
          >
            <div class="px-4 py-4 mt-2 border-b rounded shadow-lg bg-white">
              <div class="flex items-center justify-between">
                <div>
                  <p class="capitalize">{{ assignment.title }}</p>
                  <!-- <p>Description :<span>{{assignment.student_description}}</span> </p> -->
                  <p class="text-sm">
                    Due Date :<span>{{ assignment.submit_date }}</span>
                  </p>
                  <button
                    v-if="assignment.verify_status == 'submitted'"
                    class="text-sm py-1 border px-2 rounded bg-primary"
                  >
                    Submitted
                  </button>
                  <button
                    v-else-if="assignment.verify_status == 'accepted'"
                    class="text-sm py-1 border px-2 rounded bg-teal-700 text-white"
                  >
                    Accepted
                  </button>
                  <button
                    v-else
                    class="text-sm py-1 border px-2 rounded bg-pink-600 text-white"
                  >
                    Rejected
                  </button>
                </div>
                <a
                  :href="assignment.file"
                  target="_blank"
                  download
                  class="gradient-btn"
                  >Download</a
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Details Popup -->

        <div
          v-if="is_detailsPopup"
          class="fixed bg-black bg-opacity-75 w-full h-full top-0 right-0 flex flex-col justify-center"
          style="z-index: 1000"
        >
          <div
            class="w-full p-6 max-w-7xl mx-auto flex flex-col justify-center"
            style="height: 95%"
          >
            <div
              class="h-auto bg-white p-6 overflow-y-auto rounded-md previewDiv"
              style="scrollbar-color: gray white"
            >
              <div
                class="sticky top-0 float-right flex items-center justify-center border rounded-lg px-1 w-20 bg-white hover:bg-gray-100 z-50 cursor-pointer"
                @click="(is_detailsPopup = false), (is_preview = false)"
              >
                <div class="text-sm md:text-base">Close</div>
                <img
                  class="h-5 w-5 md:h-7 md:w-7"
                  src="../../assets/closebutton.svg"
                />
              </div>
              <!-- <p> {{assignmentDetails}}</p> -->
              <p class="text-lg md:text-xl font-semibold">Assignment</p>
              <div class="py-2 flex flex-col gap-1">
                <p class="text-base md:text-lg font-semibold capitalize">
                  {{ assignmentDetails.title }}
                </p>
                <p class="text-sm text-justify px-2">
                  {{ assignmentDetails.description }}
                </p>
                <div class="flex gap-2">
                  <a :href="assignmentDetails.file" class="" target="_blank">
                    <button class="gradient-btn">Download</button></a
                  >
                  <button
                    v-if="assignmentDetails.file.includes('.pdf')"
                    @click="is_preview = !is_preview"
                    class="gradient-btn"
                    target="_blank"
                  >
                    Preview
                  </button>
                </div>
                <!-- <p>{{assignmentDetails.file}}</p> -->
              </div>
              <div
                v-if="assignmentDetails.file.includes('.pdf') && is_preview"
                class="h-full mx-auto w-full"
              >
                <vue-pdf-embed
                  :source="assignmentDetails.file"
                  class="w-full"
                />
              </div>
            </div>
            <!-- PDf reader -->
          </div>
        </div>
      </div>
      <!-- Confirm PopuP -->
      <div
        v-if="this.assignmentPopup"
        class="bg-white absolute w-full px-2 md:px-4 lg:px-10 py-4 rounded h-full top-0 left-0"
      >
        <div
          class="w-full md:w-11/12 lg:w-5/6 2xl:w-4/6 flex flex-col justify-center items-center mx-auto p-6 relative rounded border shadow-xl"
        >
          <p class="text-sm md:text-base lg:text-lg text-gray-900">
            Submit Assignment
          </p>
          <div class="w-full">
            <form
              @submit.prevent="scheduleAssignment"
              class="flex flex-col gap-4 text-xs md:text-sm lg:text-base h-full"
            >
              <div
                class="flex flex-col md:flex-row gap-2 w-full text-xs md:text-sm lg:text-base"
              >
                <div class="w-full md:w-4/6 flex flex-col">
                  <label for="title">Title</label>
                  <input
                    id="title"
                    type="text"
                    class="border border-gray-400 rounded text-xs md:text-sm lg:text-base"
                  />
                </div>
                <div class="w-full md:w-2/6 flex flex-col">
                  <label for="duedate">Submit date</label>
                  <input
                    id="duedate"
                    type="date"
                    class="border border-gray-400 rounded text-xs md:text-sm lg:text-base"
                  />
                </div>
              </div>
              <div class="flex flex-col w-full">
                <label for="description">Description</label>
                <textarea
                  id="description"
                  rows="12"
                  cols="10"
                  class="border border-gray-400 h-full rounded text-xs md:text-sm lg:text-base"
                />
              </div>
              <div class="flex flex-col w-full lg:w-3/6">
                <label for="attachments">Attachments *</label>
                <input
                  id="files"
                  type="file"
                  class="border bg-white border-gray-400 rounded text-xs md:text-sm lg:text-base"
                  @change="inputchange"
                  required
                />
              </div>
              <div>
                <button type="submit" class="gradient-btn">Submit</button>
              </div>
            </form>
          </div>
          <div
            id="addbutton"
            class="flex absolute right-10 select-none rounded-lg border bg-white top-3 md:top-2 hover:text-red-400 items-center cursor-pointer"
          >
            <div
              class="px-1 md:px-2 py-1 text-sm md:text-base"
              @click="this.assignmentPopup = false"
            >
              Close
            </div>
            <img
              class="h-5 w-5 md:h-8 md:w-8"
              src="../../assets/closebutton.svg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import FlashMessage from "@/components/flashMessage.vue";
import confirmPopup from "@/components/confirmPopup.vue";
import leftNavigationStudent from "@/views/studentDashboard/leftnav.vue";
import VuePdfEmbed from "vue-pdf-embed";
export default {
  data() {
    return {
      assignmentInfo: null,
      messages: {
        text: "",
        type: "null",
      },
      popupwindow: false,
      titlepass: null,
      idpass: null,
      assignmentPopup: false,
      assignmentEditPopup: false,
      editvalues: null,
      assignmentId: null,
      submitassignmentInfo: null,
      submitted: "assignment",
      assignmentsNull: false,
      is_detailsPopup: false,
      assignmentDetails: null,
      is_preview: false,
    };
  },

  props: ["course_id", "instructor_id", "course_name"],

  components: {
    FlashMessage,
    confirmPopup,
    leftNavigationStudent,
    VuePdfEmbed,
  },

  computed: {
    ...mapGetters(["userInfo"]),
  },

  mounted() {
    this.getdetails();
  },

  methods: {
    detailsPopup(value) {
      this.is_detailsPopup = true;
      this.assignmentDetails = value;
    },

    async getdetails() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/Inst_Assignment/view/?instructor_id=${this.instructor_id}&course_id=${this.course_id}&Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          this.assignmentInfo = response.data;
          if (Object.keys(this.assignmentInfo) == "error") {
            console.log("Null");
            this.assignmentsNull = false;
          } else {
            this.assignmentsNull = true;
            this.assignmentInfo.data.forEach((element) => {
              element["display_date"] = new Date(
                element.duedate
              ).toLocaleDateString("en-IN", {
                day: "numeric",
                month: "long",
                year: "numeric",
              });
            });
          }
        });

      await axios
        .get(
          `${process.env.VUE_APP_API}/student_assignment/view/?user=${this.userInfo.user.id}&Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          this.submitassignmentInfo = response.data;
          if (Object.keys(this.submitassignmentInfo) == "error") {
            console.log("Null");
          } else {
            this.submitassignmentInfo.data.forEach((element) => {
              element.submit_date = new Date(
                element.submit_date
              ).toLocaleDateString("en-IN", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
            });
          }
        });
    },

    // student Submit Functions
    async scheduleAssignment(el) {
      let fileVal = el.target.elements.files.files[0];
      let urls = `${process.env.VUE_APP_API}/student_assignment/new/?Key=${this.userInfo.token_key}`;
      this.assignmentPopup = false;
      let formData = new FormData();
      formData.append("file", fileVal);
      formData.append("title", el.target.elements.title.value);
      formData.append(
        "student_description",
        el.target.elements.description.value
      );
      formData.append("submit_date", el.target.elements.duedate.value);
      formData.append("verify_status", "submitted");
      formData.append("user", this.userInfo.user.id);
      formData.append("assignment_id", this.assignmentId);
      axios
        .post(urls, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.message == "your Assignment added successfully") {
            this.messages = {
              text: "Assignment Submitted successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        });
      await this.getdetails();

      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
      }, 1500);
    },

    async deleteAssignment(data) {
      this.popupwindow = false;
      await axios
        .delete(
          `${process.env.VUE_APP_API}/assignment_deleted/${data}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.message == "Assignment deleted successfully") {
            this.messages = {
              text: "Assignment Deleted successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        });

      await this.getdetails();
      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
      }, 1500);
    },
  },
};
</script>

<style scoped>
.maincontent {
  height: 25rem;
}

.submitedCard:hover {
  transform: scale(1.02);
  transition: all 0.4s;
}

.previewDiv::-webkit-scrollbar {
  width: 8px;
}

.previewDiv::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 15px;
}

.previewDiv::-webkit-scrollbar-track {
  background: #fff;
}
</style>
