<template>
<Navigation />
<div class='relative overflow-y-auto flex' style="scrollbar-color:gray white;height:cal(100vh-113px)">
    <internLaftNav actived='invite' />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <div class="absolute right-24 bottom-24 w-1/2 ">
        <img src="../../assets/invite-bg.svg" alt="" class="w-full">
    </div>
    <div v-if="! is_popUp" class="z-50 w-full flex justify-center 2xl:justify-start lg:w-3/6 2xl:w-3/6 p-4 md:p-8 2xl:px-24">
        <div class="w-full flex flex-col items-center">
            <div class="w-full h-full flex flex-col items-center">
                <div class="inline-block py-2 rounded-t w-full bg-gray-100">
                    <div class="flex gap-4 px-4">
                        <button @click="invite_to='student'" class="px-4 py-2 text-gray-700 font-bold text-xl rounded" :class="[invite_to=='student' ? 'bg-white border border-indigo-800' : 'bg-gray-50']">
                            Student
                        </button>
                        <button @click="invite_to='instructor'" class="px-4 py-2 text-gray-700 font-bold text-xl bg-white rounded" :class="[invite_to=='instructor' ? 'bg-white border border-indigo-800' : 'bg-gray-50']">
                            Instructor
                        </button>
                    </div>
                </div>
                <div v-if="invite_to=='student'" class="w-full flex border border-gray-100 rounded-b shadow-xl bg-white">
                    <div class="w-full p-6 ">
                        <form ref="inviteForm" @submit.prevent="popupFn" class="flex flex-col items-center justify-center gap-2 ">
                            <div class="w-full py-2 flex flex-col item-cener gap-2">
                                <label for="selectCourse " class="font-normal text-gray-800">Select Course</label>
                                <select name="course" id="course" v-model="inviteCourse" required class="py-2 text-white rounded" style="background-color : #001c54">
                                    <option v-for="course in info" :key="course" :value="course">{{course.title}}</option>
                                </select>
                            </div>
                            <div class="w-full py-2 flex flex-col gap-2">
                                <label for="email" class="font-normal text-gray-800">Email</label>
                                <input id="email" type="email" required v-model="userMail" class="border rounded border-indigo-800 w-full p-2" placeholder="Enter user email">
                            </div>
                            <ul class="flex gap-8 justify-start  w-full">
                                <li class="flex items-center h-10">
                                    <input type="radio" name="role" v-model="userDetail" value="paiduser" id="paidUser" class="mr-2">
                                    <label for="paidUser" class="font-normal text-gray-800">Paid</label>
                                </li>
                                <li class="flex items-center h-10">
                                    <input type="radio" name="role" v-model="userDetail" value="unpaiduser" id="unpaiduser" class="mr-2">
                                    <label for="unpaiduser" class="font-normal text-gray-800">Unpaid</label>
                                </li>
                            </ul>
                            <div class="w-full">
                                <button type="submit" class="button-primary header-button w-button">Invite User</button>
                            </div>
                        </form>
                    </div>
                </div>

                <!-- instructor invite -->
                <div v-if="invite_to=='instructor'" class="w-full flex border border-gray-100 rounded-b p-6 shadow-xl bg-white">
                    <form @submit.prevent="instructorInvite" class="w-full flex flex-col items-center justify-center gap-2">
                        <div class="w-full flex flex-col gap-2 py-2">
                            <label for="instructor_mail">Email</label>
                            <input type="email" id="instructor_mail" class="border rounded border-indigo-800 w-full p-2" placeholder="Enter email" required>
                        </div>
                        <div class="w-full ">
                            <button v-if="!is_loading" type="submit" class="button-primary header-button w-button">Invite Instructor</button>
                            <button v-else class="button-primary header-button w-button"><img class="mx-auto w-12 h-5" src="../../assets/Pulse.gif" alt=""></button>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div v-if="is_popUp" class="fixed w-full h-full top-0 flex justify-center items-center bg-black bg-opacity-80" style="z-index:6000">
        <div class="w-11/12 lg:w-3/6 2xl:w-2/6 h-2/6 bg-white flex flex-col justify-center items-center shadow-xl gap-2 rounded">
            <div class=" flex flex-col justify-center items-center">
                <div class="flex flex-col md:flex-row gap-2 ">
                    <span class="text-xl font-normal self-center">Are you sure want to invite </span>
                    <span class="text-xl font-bold text-indigo-900"> {{userMail}}</span>
                </div>
                <div class="flex flex-col justify-center items-center gap-2">
                    <p>for course</p>
                    <span class="font-bold text-indigo-900 text-sm text-center"> {{inviteCourse.title}} </span></div>
            </div>
            <p v-if="userDetail=='paiduser'" class="text-2xl font-bold text-gray-700 md:py-4">Payment status : <span class="capitalize font-bold text-indigo-900"> Paid</span> </p>
            <p v-else class="text-2xl">Payment status : <span class="capitalize font-bold text-red-500">Unpaid</span> </p>
            <div class="flex justify-between w-2/6 items-center gap-4 mt-4">
                <button class="border px-2 h-10 w-20 rounded bg-teal-600 text-white hover:bg-teal-700 hover:text-white w-1/2" @click="invateMail">Yes</button>
                <button class="border px-2 h-10 w-20 rounded bg-red-400 text-white hover:bg-red-500 hover:text-white w-1/2" @click="is_popUp=false">No</button>
            </div>
            <div v-if="is_loding_userInvite" class="absolute w-full h-full">
                <div class="flex justify-center items-center h-full w-full bg-white bg-opacity-90">
                        Loading...
                </div>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios"
import {
    mapGetters
} from "vuex";
import Navigation from "@/components/Navigation.vue"
import internLaftNav from "@/views/organization/leftNavIntern.vue"
import FlashMessage from "@/components/flashMessage.vue";

export default {
    data() {
        return {
            info: null,
            orginfo: null,
            userDetail: 'paiduser',
            messages: {
                text: "",
                type: 'null',
            },
            is_popUp: false,
            inviteCourse: null,
            userMail: null,
            invite_to: 'student',
            is_loading: false,
            is_loding_userInvite : false

            // isStudent: true,
            // isStaff : false,

        }
    },
    components: {
        Navigation,
        internLaftNav,
        FlashMessage,
    },
    computed: mapGetters(["userInfo", "instructorInfo"]),
    async created() {

        await axios.get(`${process.env.VUE_APP_API}/organization_detail/?Key=${this.userInfo.token_key}`)
            .then(Response => {
                this.orginfo = Response.data.data
            })

        if (this.orginfo != null) {
            await axios.get(`${process.env.VUE_APP_API}/courses/?organization_id=${this.orginfo.id}`)
                .then(Response => {
                    this.info = Response.data.data
                    this.inviteCourse = this.info[0]
                })
        }

    },

    methods: {
        instructorInvite(el) {
            let mailId = {
                email: el.target.elements.instructor_mail.value
            }
            axios.post(`${process.env.VUE_APP_API}/email_verification/`, mailId)
                .then(response => {
                    if (response.data.message == "Email already verified") {
                        console.log("Email already exist")
                        this.messages = {
                            text: "Mail already exist",
                            type: 'error',
                        }
                    } else {
                        console.log("Email not exist")
                        axios.post(`${process.env.VUE_APP_API}/instructor_invite/?Key=${this.userInfo.token_key}`, mailId)
                            .then(response => {

                                if (response.data.message == "successfully Email send") {
                                    console.log("Email send")
                                    this.messages = {
                                        text: "Invite successfully send",
                                        type: 'success',
                                    }
                                } else {
                                    console.log("Email not send")
                                    // console.log(response)
                                    this.messages = {
                                        text: "Already Invited",
                                        type: 'success',
                                    }
                                }
                                setTimeout(() => {
                                    this.messages = {
                                        text: "",
                                        type: 'null',
                                    }
                                    this.is_loading = true
                                }, 1500)
                            })
                    }

                    setTimeout(() => {
                        this.messages = {
                            text: "",
                            type: 'null',
                        }
                    this.is_loading= false
                    }, 1500)
                })
                .catch(error => {
                    console.log(error)
                })

        },
        popupFn() {
            this.is_popUp = true
        },
        invateMail() {
            this.is_loding_userInvite = true

            if (this.userDetail == "paiduser") {

                let mailId = {
                    email: this.userMail
                }
                axios.post(`${process.env.VUE_APP_API}/email_verification/`, mailId)
                    .then(response => {

                        if (response.data.message == "Email already verified") {

                            axios.post(`${process.env.VUE_APP_API}/paid_invite/${this.userInfo.token_key}/${this.inviteCourse.id}/?Key=${this.userInfo.token_key}`, mailId)
                                .then(Response => {

                                    if (Response.data.message == "Invited successfully") {

                                        this.messages = {
                                            text: "Invited Successfully",
                                            type: 'success',
                                        }
                                    } else if (Response.data.message == "Already Invited") {

                                        this.messages = {
                                            text: "Already Invited to this course",
                                            type: 'error',
                                        }
                                    }

                                    this.is_popUp = false
                                    this.is_loding_userInvite = false
                                    setTimeout(() => {
                                        this.messages = {
                                            text: "",
                                            type: 'null',
                                        }
                                        this.userMail = null
                                    }, 1500)
                                })

                        } else {

                            let mainInfo = {
                                "userid": this.userInfo.user.id,
                                "email": this.userMail,
                                "status": false,
                                "coures": `${process.env.VUE_APP_SITE}/${this.userInfo.token_key}/${this.inviteCourse.id}/signup`
                            }

                            axios.post(`${process.env.VUE_APP_API}/invitation/`, mainInfo)
                                .then(response => {

                                    if (response.data.message == "successfully invited for the course.") {
                                        this.messages = {
                                            text: "Invited Successfully",
                                            type: 'success',
                                        }
                                    } else if (response.data.user_data == "User already invited for this course.") {
                                        this.messages = {
                                            text: "Already Invited for this course.",
                                            type: 'error',
                                        }
                                    }

                                    this.is_popUp = false
                                    this.is_loding_userInvite = false
                                    setTimeout(() => {
                                        this.messages = {
                                            text: "",
                                            type: 'null',
                                        }
                                        this.userMail = null
                                    }, 1500)
                                })

                        }

                    })

            } else {

                let mainInfo = {
                    "userid": this.userInfo.user.id,
                    "email": this.userMail,
                    "status": false,
                    "coures": `${process.env.VUE_APP_SITE}/${this.inviteCourse.slug}`

                }

                axios.post(`${process.env.VUE_APP_API}/invitation/`, mainInfo)
                    .then(response => {
                        console.log(response.data, "respoonse ")
                        if (response.data.message == "successfully invited for the course.") {
                            this.messages = {
                                text: "Invite Sent Successfully",
                                type: 'success',
                            }
                        } else if (response.data.user_data == "User already invited for this course.") {
                            this.messages = {
                                text: "Already Invited for this course.",
                                type: 'error',
                            }
                        }

                        this.is_popUp = false
                        setTimeout(() => {
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                            this.userMail = null
                        }, 1500)
                    })

            }

        }
    },

}
</script>

<style scoped>
th,
tr,
td,
table {
    padding: 10px;
}
</style>
